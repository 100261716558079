#page-title{
    position: relative;
    overflow: hidden;
    &.active{
        .bg{
            transform: scale(1);
        }
        h1{
            opacity: 1;
            transform: translateY(0);
            @media screen and (max-width:$responsive){
                transform: translateY(-8px);
            }
        }
    }
    #particles{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        pointer-events: none;
    }
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        z-index: 1;
        transform: scale(1.04);
        @include transition(1600ms, $ease:linear);
    }
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($normal, .48);
        box-shadow: inset 0 88px 88px rgba($normal, .72);
        z-index: 2;
        @media screen and (max-width:$responsive){
            opacity: .88;   
        }
    }
    .display{
        z-index: 3;
        height: 400px;
        @include flex();
        align-items: center;
        justify-content: center;
        padding: $pc-head/1.66 0 0;
        @media screen and (max-width:$tab){
            height: 240px;   
        }
        @media screen and (max-width:$responsive){
            height: 220px;
        }
    }
    h1{
        color: #FFF;
        font-size: 20px;
        font-weight: 800;
        line-height: 1;
        letter-spacing: .08em;
        text-shadow: 0 0 32px rgba(#000,.24);
        transform: translateY(20px);
        opacity: 0;
        text-align: center;
        @include transition(800ms, $delay:200ms);
        @media screen and (max-width:$tab){
            font-size: 16px;
        }
        @media screen and (max-width:$responsive){
            font-size: 13px;
        }
        &:before{
            font-family: 'Open Sans', sans-serif;
            content: attr(data-eng);
            letter-spacing: .06em;
            font-size: 56px;
            display: block;
            margin: 0 0 16px;
            @media screen and (max-width:$tab){
                font-size: 40px;   
            }
            @media screen and (max-width:$responsive){
                font-size: 24px;
                margin: 0 0 10px;
            }
        }
    }
}

#breadcrumb{
    background: darken($glay, 2%);
    @media screen and (max-width:$responsive){
        width: 100%;
        overflow: hidden;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            background: -moz-linear-gradient(left,  rgba($glay,0) 0%, rgba($glay,1) 100%);
            background: -webkit-linear-gradient(left,  rgba($glay,0) 0%, rgba($glay,1) 100%);
            background: linear-gradient(to right,  rgba($glay,0) 0%, rgba($glay,1) 100%);
            top: 0;
            right: 0;
            width: 40px;
            height: 100%;
            z-index: 100;
        }
    }
    a{
        color: $normal;
    }
    ol{
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 13px;
        @include flex();
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width:$tab){
            padding: 20px;   
        }
        @media screen and (max-width:$responsive){
            padding: 15px;
            font-size: 12px;
            width: 200%;
        }
        li{
            &:first-child{
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
                        color: $red;
                    }
                }
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
            &:after{
                content: "\f054";
                font-family: "Font Awesome 5 Free";
                font-size: 8px;
                font-weight: 900;
                opacity: .4;
                display: inline-block;
                margin: 0 16px;
                -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
            }
        }
    }
}