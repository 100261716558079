.site-contact{
    .contactbox{
        background: $glay;
        padding: 15px 160px;
        border-radius: 4px;
        margin: 0 0 72px;
        @include flex();
        @media screen and (max-width:$tab){
            padding: 15px;   
        }
        @media screen and (max-width:$responsive){
            margin: 0 0 32px;
            display: block;
        }
        li{
            width: calc(50% - 12px);
            background: #FFF;
            border-radius: 3px;
            box-shadow: 0 0 15px rgba(#000,.06);
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 0 0 15px;
                &:last-child{
                    margin: 0;
                }
            }
            dl{
                text-align: center;
                line-height: 1;
                font-weight: 700;
                padding: 20px;
                dt{
                    font-size: 17px;
                    margin: 0 0 12px;
                    i{
                        color: $red;
                        display: inline-block;
                        margin: 0 8px 0 0;
                    }
                }
                dd{
                    font-family: 'Open Sans',sans-serif;
                    font-size: 40px;
                    color: $red;
                    @media screen and (max-width:$tab){
                        font-size: 24px;   
                    }
                    .up{
                        display: inline-block;
                        transform: translateY(-4px);
                    }
                }
            }
        }
    }
    .site-module{
        .table-style{
            tbody{
                tr{
                    &:first-child {
                        border-top: none;
                        th,td{
                            padding-top: 24px;
                        }
                    }
                    .sub{
                        margin: 8px 0 0;
                    }
                }
            }
        }
    }
	.harf{
		@include flex();
		&__item{
			width: calc(50% - 15px);
			@include flex();
			align-items: center;
			&__title{
				width: 40px;
			}
			&__gadget{
				width: calc(100% - 40px);
			}
		}
	}
    .send{
        text-align: center;
        margin: 40px 0 0;
        @media screen and (max-width:$responsive){
            margin: 24px 0 0;   
        }
        input{
            width: 280px;
            @include font();
            color: #FFF;
            font-size: 18px;
            font-weight: 700;
            background: $red;
            border: $red 2px solid;
            padding: 15px;
            line-height: 1;
            cursor: pointer;
            @media screen and (max-width:$responsive){
                width: 100%;
                display: block;
                font-size: 16px;
            }
            &:hover{
                background: #FFF;
                color: $red;
            }
        }
    }
}