.site-index{
    .firstview{
        position: relative;
        top: 0;
        left: 0;
        height: 100vh;
        border-top: none;
        background: #000;
        @include flex();
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            opacity: .24;
            z-index: 2;
        }
        &:after{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            box-shadow: inset 0 0 88px rgba(0,0,0,.88);
        }
        &.active{
            .catch{
                opacity: .96;
                margin-top: 0;
            }
            .topics{
                transform: translateX(0);
                opacity: 1;
            }
        }
        #video{
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: bottom center;
            width: 100vw;
            height: 100vh;
            z-index: 1;
        }
        #particles{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            pointer-events: none;
        }
        .catch{
            position: relative;
            z-index: 3;
            font-weight: 700;
            color: #FFF;
            line-height: 1;
            margin: 0 0 0 10%;
            margin-top: 40px;
            opacity: 0;
            @include transition(600ms, $delay:400ms);
            dt{
                @include fontsize(12px, 5);
                font-weight: 500;
                margin: 18px 0 0;
                text-indent: 2px;
                @media screen and (max-width:$responsive){
                    margin: 16px 0 0;
                    font-size: 16px;
                    width: 72%;
                    line-height: 1.66;
                }
            }
            dd{
                @include fontsize(70px, 32);
                @include osw();
                line-height: 1.12;
                text-transform: uppercase;
                letter-spacing: .0em;
                @media screen and (max-width:$responsive){
                    font-size: 70px;
                    white-space: nowrap;
                    br{
                        display: none;
                    }
                }
            }
            strong{
                color: $red;
                @media screen and (max-width:$responsive){
                    display: block;   
                }
            }
        }
        .topics{
            color: rgba(#FFF,.88);
            min-width: 300px;
            max-width: 500px;
            @include flex();
            align-items: center;
            position: absolute;
            left: 0;
            bottom: 1px;
            z-index: 3;
            padding: 20px 15px;
            line-height: 1;
            transform: translateX(-20%);
            opacity: 0;
            transition: transform 600ms 400ms, opacity 600ms 400ms;
            @media screen and (max-width:$responsive){
                min-width: 1px;
                padding: 15px;
                transform: translateX(0);
                bottom: 0;
                background: $normal;
            }
            &:hover{
                i{
                    color: $red;
                }
            }
            &:before{
                content: '';
                z-index: -1;
                width: 120%;
                height: 100%;
                background: $normal;
                transform: skewX(24deg);
                position: absolute;
                top: 0;
                left: -10%;
                @media screen and (max-width:$responsive){
                    display: none;
                }
            }
            i{
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                @media screen and (max-width:$responsive){
                    right: 15px;   
                }
            }
            time{
                width: 110px;
                font-size: 11px;
                padding: 4px 0;
                text-align: center;
                border-right: rgba(#FFF,.12) 1px solid;
                @media screen and (max-width:$responsive){
                    width: 100px;
                }
            }
            &__mes{
                width: calc(100% - 110px);
                padding: 0 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 13px;
                @media screen and (max-width:$responsive){
                   width: calc(100% - 100px);
                }
            }
        }
        .scroll{
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 3;
            @media screen and (max-width:$responsive){
                display: none;
            }
            &__line{
                position: absolute;
                right: 29px;
                bottom: 24px;
                z-index: 3;
                height: 96px;
                &__circle{
                    content: '';
                    width: 32px;
                    height: 32px;
                    border: $red 1px solid;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    animation: move 2s infinite;
                    &:before{
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        width: 30%;
                        height: 30%;
                        border-radius: 50%;
                        background: $red;
                    }
                    @keyframes move{
                        0%{
                            transform: scale(0);
                            opacity: 1;
                        }
                        70%{
                            transform: scale(1);
                            opacity: 1;
                            filter: blur(0);
                        }
                        100%{
                            transform: scale(1.2);
                            opacity: 0;
                            filter: blur(5px);
                        }
                    }
                }
                &:after{
                    font-size: 11px;
                    -webkit-writing-mode: vertical-rl;
                    -moz-writing-mode: vertical-rl;
                    -ms-writing-mode: tb-rl;
                    writing-mode: vertical-rl;
                    white-space: nowrap;
                    letter-spacing: .12em;
                    content: 'SCROLL';
                    position: absolute;
                    top: 0;
                    right: 8px;
                    color: #FFF;
                }
            }
        }
        .copy{
            -webkit-writing-mode: vertical-rl;
            -moz-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            white-space: nowrap;
            letter-spacing: .08em;
            position: absolute;
            top: 0;
            right: 40px;
            height: 100%;
            color: rgba(255,255,255,.88);
            font-size: 9px;
            text-align: center;
            letter-spacing: .24em;
            z-index: 3;
            color: #FFF;
            text-transform: uppercase;
            @media screen and (max-width:$responsive){
                right: 18px;   
            }
        }
        .bg{
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: 100vh;
            background: #FFF;
            z-index: 100;
            @media screen and (max-width:$responsive){
                display: none;
            }
        }
    }
}