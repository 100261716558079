.site-product{
    .firstbox{
        @include flex();
        @media screen and (max-width:$responsive){
            padding: 0;
            border: none;
            display: block;
        }
        &__img{
            width: 400px;
			order: 2;
            @media screen and (max-width:$responsive){
				order: 1;
                width: 240px;
                margin: 0 auto;
            }
            img{
                width: 100%;
            }
        }
        &__data{
            width: calc(100% - 400px);
            padding: 0 48px 0 0;
            @media screen and (max-width:$responsive){
                width: auto;
                padding: 20px 0 0;
            }
            &__dl{
                dt{
                    font-size: 24px;
                    font-weight: 700;
                    margin: 0 0 12px;
                    padding: 0 0 12px;
                    border-bottom: $border 1px solid;
                    @media screen and (max-width:$responsive){
                        font-size: 18px;
                        text-align: center;
                    }
                    &:after{
                        content: attr(data-eng);
                        display: inline-block;
                        margin: 0 0 0 16px;
                        font-size: 10px;
                        color: $red;
                        @media screen and (max-width:$responsive){
                            display: block;
                            margin: 4px 0 0;
                        }
                    }
                }
                dd{
                    text-align: justify;
                }
                &--mini{
                    margin: 32px 0 0;
                    background: $glay;
                    padding: 15px 24px;
                    border-radius: 4px;
                    @media screen and (max-width:$responsive){
                        margin: 24px 0 0;
                        padding: 15px;
                    }
                    dt{
                        font-size: 18px;
                        @media screen and (max-width:$responsive){
                            text-align: left;
                            margin: 0 0 10px;
                            padding: 0 0 6px;
                        }
                        &:before{
                            content: '■';
                            color: $red;
                            font-size: 12px;
                            display: inline-block;
                            margin: 0 6px 0 0;
                            transform: translateY(-2px);
                        }
                    }
                    dd{
                        
                    }
                }
            }
        }
    }
    
    .listitem{
        margin: 32px 0 0;
        padding: 25px;
        background: $glay;
        @media screen and (max-width:$responsive){
            padding: 15px;
            margin: 24px 0 0;
        }
        &--mt{
            margin-top: 0;
        }
        &__items{
            @include flex();
            align-items: flex-start;
            justify-content: flex-start;
            @media screen and (max-width:$responsive){
                display: block;   
            }
            li{
                width: 32%;
                margin: 2% 2% 0 0;
                &:nth-child(-n+3){
                    margin-top: 0;
                }
                &:nth-child(3n+3){
                    margin-right: 0;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 15px;
                    &:last-child{
                        margin: 0;
                    }
                }
                img{
                    width: 100%;
                }
                figcaption{
                    text-align: center;
                    margin: 8px 15px 0;
                    font-size: 15px;
                }
            }
        }
        &__txt{
            margin: 24px;
            text-align: center;
            font-size: 15px;
        }
        .btnarea{
            margin: 0;
            padding: 0 0 8px;
            @media screen and (max-width:$responsive){
                padding: 0;   
            }
        }
    }
    
    .itembox{
        margin: 72px 0 0;
        @media screen and (max-width:$responsive){
            margin: 32px 0 0;   
        }
        .mb{
            margin-bottom: 32px;
            @media screen and (max-width:$responsive){
                margin-bottom: 24px;   
            }
            &:last-of-type{
                margin-bottom: 0;
            }
        }
		> h4{
			margin-top: 56px;
			@media screen and (max-width:$responsive){
				margin-top: 32px;	
			}
			&:first-of-type{
				margin-top: 0;
			}
		}
    }
    
    .usebox{
        @include flex();
        align-items: center;
        @media screen and (max-width:$responsive){
            display: block;   
        }
        &__video{
            width: 380px;
			order: 2;
            iframe{
                width: 100%;
                vertical-align: bottom;
            }
            @media screen and (max-width:$responsive){
                width: auto;   
            }
        }
        &__list{
            width: calc(100% - 380px);
            padding: 15px 32px 0 0;
            @include flex();
            @media screen and (max-width:$responsive){
                margin: 15px 0 0;
                padding: 0;
                width: auto;
                display: block;
            }
            li{
                width: calc(50% - 12px);
                font-size: 15px;
                margin: 0 0 15px;
                padding: 0 0 15px;
                border-bottom: $border 1px solid;
                @media screen and (max-width:$responsive){
                    width: auto;
                    &:last-child{
                        margin: 0;
                        padding: 0;
                        border-bottom: none;
                        font-size: 13px;
                    }
                }
                &:before{
                    content: "\f058";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    display: inline-block;
                    margin: 0 6px 0 0;
                    color: $red;
                }
            }
        }
    }
    
    .featurebox{
        border: $glay 8px solid;
        background: $glay;
        &__list{
            @include flex();
            justify-content: flex-start;
            background: $glay;
            @media screen and (max-width:$responsive){
                display: block;   
            }
            &__item{
                width: calc(33.3%);
                border-right: $glay 8px solid;
                border-top: $glay 8px solid;
                background: #FFF;
                &:nth-child(3n+3){
                    border-right: none;
                }
                &:nth-child(-n+3){
                    border-top: none;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    border: none;
                    margin: 0 0 8px;
                    &:last-child{
                        margin: 0;
                    }
                }
                &__bg{
                    display: block;
                    height: 300px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    @media screen and (max-width:$responsive){
                        height: 200px;   
                    }
                }
                &__txt{
                    text-align: center;
                    font-size: 17px;
                    padding: 15px;
                    border-top: $glay 1px solid;
                    @media screen and (max-width:$responsive){
                        font-size: 14px;
                        padding: 10px 15px;
                    }
                }
            }
        }
        &__txt{
            margin: 24px;
            text-align: center;
            font-size: 15px;
            @media screen and (max-width:$responsive){
                margin: 20px 8px 0;   
            }
        }
        .btnarea{
            margin: 0;
            padding: 0 0 24px;
            @media screen and (max-width:$responsive){
                padding: 0;
                margin: 20px 8px 8px;
            }
        }
    }
    
    
    .figurebox{
        text-align: center;
        border: $border 1px solid;
        background: #FFF;
        padding: 40px;
        @media screen and (max-width:$responsive){
            padding: 15px;   
        }
        img{
            max-width: 80%;
            width: auto;
            height: auto;
            @media screen and (max-width:$responsive){
                max-width: 88%;   
            }
        }
    }
    h4{
		width: 100%;
        font-weight: 700;
        font-size: 18px;
        margin: 0 0 8px;
        padding: 0 0 0 16px;
        position: relative;
        @media screen and (max-width:$responsive){
            font-size: 16px;
        }
        &:before{
            content: '';
            width: 9px;
            height: 9px;
            background: $red;
            border-radius: 50%;
            position: absolute;
            top: calc(50% + 1px);
            left: 0;
            transform: translateY(-50%);
        }
    }

    .tablebox{
        .txtbox{
            margin: 0 0 16px;
            @media screen and (max-width:$responsive){
                margin: 0 0 12px;
            }
        }
        &__responsive{
            @media screen and (max-width:$responsive){
                width: 100%;
                overflow: auto;
                position: relative;
            }
            .hand{
                display: none;
                @media screen and (max-width:$responsive){
                    display: block;
                    margin: 0 0 12px;
                    position: relative;
                    padding: 0 0 0 36px;
                    i{
                        position: absolute;
                        top: 5px;
                        left: 0;
                        color: $red;
                        animation: swipe 1s infinite alternate;
                        @keyframes swipe{
                            0%{
                                left: 0;
                            }
                            100%{
                                left: 12px;
                            }
                        }
                    }
                }
            }
        }
        table{
            border-collapse: collapse;
            border: $border 1px solid;
            width: 100%;
            thead,
            tbody{
                tr{
                    th,td{
                        padding: 5px 8px;
                        border: $border 1px solid;
                        font-size: 14px;
                        vertical-align: middle;
                        @media screen and (max-width:$responsive){
                            padding: 8 15px;
                            font-size: 13px;
                            min-width: 140px;
                        }
                        img{
                            max-width: 100%;
                        }
                    }
                }
            }
            thead{
                background: $glay;
                font-weight: 700;
            }
        }
    }
    p.sup{
        margin: 16px 0 0;
        font-size: 12px;
    }
    
    .otherbox{
        @include flex();
        align-items: flex-start;
        @media screen and (max-width:$responsive){
            display: block;   
        }
    }
    .otherlist{
        @include flex();
        justify-content: flex-start;
        width: calc(100% - 200px);
        padding: 0 24px 0 0;
        @media screen and (max-width:$responsive){
            width: auto;
            display: block;
            padding: 0;
            margin: 0 0 20px;
        }
        li{
            @media screen and (min-width:$tab + 1px){
                width: calc(32%);
                margin: 2% 2% 0 0;
                background: #FFF;
                box-shadow: 0 0 16px rgba(#000,.04);
                &:nth-child(3n+3){
                    margin-right: 0;
                }
                &:nth-child(-n+3){
                    margin-top: 0;
                }
            }
            @media screen and (max-width:$tab){
                background: #FFF;
                box-shadow: 0 0 16px rgba(#000,.04);
                margin: 0 0 20px;
                &:last-child{
                    margin: 0;
                }
            }
            a{
                padding: 20px;
                color: $normal;
                @include flex();
                align-items: center;
                figure{
                    width: 72px;
                    img{
                        width: 100%;
                    }
                }
                dl{
                    width: calc(100% - 72px);
                    padding: 0 0 0 20px;
                    dt{
                        font-size: 13.5px;
                        font-weight: 700;
                    }
                    dd{
                        font-size: 10px;
                        color: $red;
                        text-align: justify;
                    }
                }
                .tt{
                    font-size: 12px;
                    text-align: justify;
                    margin: 8px 0 0;
                    padding: 8px 0 0;
                    border-top: $border 1px solid;
                    width: 100%;
                }
            }
        }
    }

    .productmore{
        width: 200px;
        @media screen and (max-width:$responsive){
            width: auto;   
        }
        a{
            background: #FFF;
            border: $red 2px solid;
            color: $red;
            text-align: center;
            font-size: 15px;
            font-weight: 700;
            display: block;
            padding: 8px 0;
            margin: 0 0 10px;
            &:last-of-type{
                margin: 0;
            }
            &:hover{
                background: $red;
                color: #FFF;
            }
            i{
                display: inline-block;
                margin: 0 8px 0 0;
            }
        }
    }
	
	.itemflex{
		@media screen and (min-width:$tab + 1px){
			.figurebox{
				border: $border 1px solid;
				@include flex();
				align-items: center;
				justify-content: center;
				padding: 32px;
				img{
					max-width: 100%;
					width: auto;
				}
			}
			.tablebox{
				overflow: auto;
			}
		}
	}
}