.site-company{
    .box{
        margin: 0 0 80px;
        @media screen and (max-width:$tab){
            margin: 0 0 56px;   
        }
        @media screen and (max-width:$responsive){
            margin: 0 0 32px;   
        }
        &__flex{
            @include flex();
            align-items: center;
            figure{
                width: 640px;
                @media screen and (max-width:$tab){
                    width: 100%;
                    margin: 0 0 32px;
                }
                @media screen and (max-width:$responsive){
                    margin: 0 0 20px;   
                }
                img{
                    width: 100%;
                }
            }
            .greeting{
                width: calc(100% - 640px);
                padding: 0 0 0 48px;
                @media screen and (max-width:$tab){
                    width: auto;
                    padding: 0;
                    margin: 32px 0 0;
                }
                @media screen and (max-width:$responsive){
                    margin: 0;   
                }
                dd{
                    font-size: 15px;
                    text-align: justify;
                    line-height: 2;
                    @media screen and (max-width:$responsive){
                        font-size: 13px;   
                    }
                }
                dt{
                    text-align: right;
                    margin: 40px 0 0;
                    @include min();
                    font-weight: 700;
                    font-size: 14px;
                    strong{
                        font-size: 32px;
                        line-height: 1;
                        display: inline-block;
                        margin: 0 0 0 8px;
                        @media screen and (max-width:$responsive){
                            font-size: 24px;   
                        }
                    }
                }
            }
        }
    }
    .solution{
        position: relative;
        &__list{
            padding: 12px 0 48px;
            li{
                background: #FFF;
                dl{
                    text-align: center;
                    line-height: 1;
                    dt{
                        font-family: 'Open Sans', sans-serif;
                        font-weight: 800;
                        font-size: 72px;
                        letter-spacing: 0em;
                        margin: 0 0 20px;
                        text-transform: uppercase;
                        @media screen and (max-width:$responsive){
                            font-size: 28px;
                            margin: 0 0 8px;
                        }
                    }
                    dd{
                        @include min();
                        font-size: 20px;
                        font-weight: 700;
                        @media screen and (max-width:$responsive){
                            font-size: 15px;
                            line-height: 1.56;
                            width: 88%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        &__ctr{
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 10;
            @include flex();
            justify-content: flex-start;
            &__btn{
                $size: 48px;
                width: $size;
                height: $size;
                background: $normal;
                color: #FFF;
                font-size: 12px;
                margin: 0 2px 0 0;
                cursor: pointer;
                @include flex();
                align-items: center;
                justify-content: center;
                @media screen and (max-width:$responsive){
                    $size: 40px;
                    width: $size;
                    height: $size;
                }
                &:hover{
                    background: $red;
                }
            }
        }
    }
    .map{
        margin: 48px 0 0;
        border: $border 1px solid;
        background: #FFF;
        padding: 8px;
        @media screen and (max-width:$responsive){
            margin: 32px 0 0;   
        }
        iframe{
            width: 100%;
            height: 300px;
            filter: grayscale(1);
            vertical-align: bottom;
            @media screen and (max-width:$responsive){
                height: 200px;   
            }
        }
    }
    .client{
        dl{
            border: $border 1px solid;
            border-bottom: none;
            @include flex();
            position: relative;
            @media screen and (max-width:$responsive){
                display: block;   
            }
            &:first-of-type{
                margin: 10px 0 0;
            }
            &:last-of-type{
                border-bottom: $border 1px solid;
            }
            &:before{
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 160px;
                background: $border;
                @media screen and (max-width:$responsive){
                    display: none;
                }
            }
            dt{
                width: 160px;
                padding: 10px 0;
                padding-left: 20px;
                background: $glay;
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 10px 15px;
                    background: none;
                    border-bottom: $border 1px solid;
                    background: $glay;
                    font-weight: 700;
                }
            }
            dd{
                width: calc(100% - 160px);
                padding: 10px 20px;
                font-size: 14px;
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 10px 15px;
                    font-size: 13px;
                }
            }
        }
    }
}