@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');


/* BASE COLOR
------------------------------------------------------------*/
$normal:#121212;
$link:#D02;
$border:#e8e8e8;

$blue: #023894;
$red: #f43300;
$glay: #f2f2f2;


/* SITE SETTING
------------------------------------------------------------*/
$width:1024px;
$tab: $width - 1px;
$responsive: 768px;

$pc-head:88px;
$sp-head:70px;


@import "module/reset";
@import "module/module";

@mixin fontsize($w: 32px, $m: 22){
    font-size: calc(#{$w} + #{$m} * (100vw - 450px)/ 900);
}

@media screen and (min-width:$width){
	@include default(
		$width, //サイト幅
		15px, //フォントサイズ
		$normal, //フォントカラー
		$red, //リンクカラー
		#FFF, //ボディ背景
		20px //左右余白
	);
}

@media screen and (max-width:$tab){
	@include default(
		auto, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$red, //リンクカラー
		#FFF, //ボディ背景
		20px //左右余白
	);
}

@media screen and (max-width:$responsive){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$red, //リンクカラー
		#FFF, //ボディ背景
		15px //左右余白
	);
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/index";
@import "page/firstview";
@import "page/company";
@import "page/catalog";
@import "page/product";
@import "page/category";
@import "page/contact";
@import "page/privacy";
@import "page/news";
@import "page/sitemap";
@import "page/mouvers";

#site-wrapper,#site-footer{
    position: relative;
    @media screen and (max-width:768px){

    }
}

.site-clum{
    @include flex();
    margin: 0 auto;
    @media screen and (max-width:$tab){
        width: auto;
        display: block;
    }
    &__sidebar{
        width: 280px;
		.btnarea{
			a{
				display: block !important;
				min-width: 1px !important;
			}
		}
        @media screen and (max-width:$tab){
            width: auto;
        }
        @media screen and (max-width:$responsive){
            margin: 32px 0 0;   
        }
    }
    &__container{
        width: calc(100% - 280px);
        padding: 0 48px 0 0;
        @media screen and (max-width:$tab){
            width: auto;
            padding: 0;
        }
        .pager{
            margin: 24px 0 0;
            text-align: center;
            a,span{
                $size: 28px;
                width: $size;
                height: $size;
                line-height: $size - 2px;
                text-align: center;
                display: inline-block;
                margin: 0 3px;
                border: $border 1px solid;
                background: $border;
                color: $normal;
                font-size: 13px;
            }
            span{
                background: $red;
                border-color: $red;
                color: #FFF;
            }
            a{
                &:hover{
                    color: $red;
                    background: #FFF;
                    border-color: $red;
                }
            }
        }
    }
}


#app{
    opacity: 0;
    &.active{
        opacity: 1;
    }
}

.display{
    &.side-margin{
        max-width: 1600px;
        padding: 0 4%;
    }
}

.site-module{
    &.wrap{
        padding: 120px 0;
        overflow: hidden;
        @media screen and (max-width:$tab){
            padding: 72px 0;   
        }
        @media screen and (max-width:$responsive){
            padding: 32px 0;   
        }
        &.glay{
            background: $glay;
        }
        &.mid{
            padding: 80px 0;
            @media screen and (max-width:$tab){
                padding: 56px 0;   
            }
            @media screen and (max-width:$responsive){
                padding: 32px 0;   
            }
        }
        &.no-top{
            padding-top: 0;
        }
        &.no-bottom{
            padding-bottom: 0;
        }
    }
    
    h2.large{
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        margin: 0 0 32px;
        letter-spacing: .08em;
        text-align: center;
        background: url("../../images/common/h_bg.png") no-repeat center top;
        background-size: 60px;
        padding: 30px 0 0;
        @media screen and (max-width:$responsive){
            font-size: 15px;
            margin: 0 0 24px;
            background: url("../../images/common/h_bg.png") no-repeat center top;
            background-size: 40px;
            padding: 24px 0 0;
        }
        &:before{
            content: attr(data-eng);
            font-family: 'Open Sans', sans-serif;
            font-size: 42px;
            display: block;
            margin: 0 0 10px;
            @media screen and (max-width:$responsive){
                font-size: 28px;   
            }
        }
    }
    h2.borders,
    h3.borders,
    h4.borders{
        border-bottom: $border 1px solid;
		position: relative;
        padding: 12px;
		padding-left: 20px;
        font-size: 22px;
		line-height: 1;
        font-weight: 700;
		margin: 0 0 16px;
		@media screen and (max-width:$responsive){
			font-size: 18px;
			margin: 0 0 20px;
			padding-left: 18px;
		}
		small{
			display: inline-block;
			margin: 0 0 0 20px;
			font-size: 13px;
			@media screen and (max-width:$responsive){
				margin: 0 0 0 15px;
				font-size: 11px;
			}
		}
		&:after{
			content: '';
			width: 5px;
			height: 70%;
			background: $red;
			border-radius: 4px;
			display: block;
			position: absolute;
			top: 15%;
			left: 0;
		}
    }
    h3.centers,
    h4.centers,{
        position: relative;
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        color: $normal;
        letter-spacing: .12em;
        margin: 0 0 28px;
        @media screen and (max-width:$responsive){
            font-size: 20px;
            margin: 0 0 16px;
        }
        &:before{
            content: '';
            width: 100%;
            height: 3px;
            background: $normal;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            z-index: -2;
        }
        &:after{
            content: '';
            width: 200px;
            height: 100%;
            background: #FFF;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            @media screen and (max-width:$responsive){
                width: 160px;   
            }
        }
    }
    .btnarea{
        margin: 32px 0 0;
        @media screen and (max-width:$responsive){
            margin: 24px 0 0;   
        }
        &.center{
            text-align: center;
            a{
                margin: 0 8px;
                @media screen and (max-width:$responsive){
                    margin: 0;   
                }
            }
        }
        a{
            background: $red;
            color: #FFF;
            padding: 10px 48px;
            min-width: 300px;
            text-align: center;
            font-size: 17px;
            font-weight: 700;
            position: relative;
            z-index: 2;
            display: inline-block;
            @media screen and (max-width:$responsive){
                display: block;
                padding: 10px 15px;
                text-align: center;
                font-size: 16px;
            }
            &:hover{
                background: $normal;
                color: #FFF;
                @include transition(240ms);
            }
            &.ghost{
                background: none;
                border: $red 2px solid;
                color: $red;
                position: relative;
                &:before{
                    content: '';
                    width: 0;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $red;
                    z-index: -1;
                    @include transition(240ms);
                }
                &:hover{
                    background: none;
                    color: #FFF;
                    &:before{
                        width: 100%;
                    }
                }
            }
            &.arrow{
                &:after{
                    content: "\f061";
                    font-size: 11px;
                    font-family: "Font Awesome 5 Free";
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    transform: translateY(-50%);
                }
            }
            &.black{
                background: $normal;
                color: #FFF;
                &:hover{
                    background: #FFF;
                    color: $normal;
                }
            }
            &.blue{
                border-color: $blue;
                background: $blue;
                color: #FFF;
                &:hover{
                    background: #FFF;
                    color: $blue;
                }
            }
        }
    }
    .table-style{
        border-collapse: collapse;
        width: 100%;
        @media screen and (max-width:$responsive){
            display: block;
            border: $border 1px solid;
        }
        &.white{
            background: #FFF;
            border: $border 1px solid;
            thead,
            tbody{
                tr{
                    th,
                    td{
                        padding: 10px 15px;
                        font-size: 16px;
                        @media screen and (max-width:$responsive){
                            padding: 6px 15px;
                            font-size: 13px;
                        }
                    }
                    th{
                        border-right: $border 1px solid;
                    }
                }
            }
        }
        thead,
        tbody{
            @media screen and (max-width:$responsive){
                display: block;   
            }
            tr{
                border-bottom: $border 1px solid;
                @media screen and (max-width:$responsive){
                    display: block;
                    &:last-child{
                        border: none;
                    }
                }
                &:first-child{
                    border-top: $border 1px solid;
                    @media screen and (max-width:$responsive){
                        border: none;
                    }
                }
                th,td{
                    padding: 15px 0;
                    font-size: 15px;
                    vertical-align: middle;
                    text-align: left;
                    @media screen and (max-width:$responsive){
                        display: block;
                        font-size: 14px;
                        padding: 10px 15px;
                    }
					input[type=text],
					input[type=email],
					input[type=tel],
					select,
					option,
					textarea{
						@include font();
						border: $border 1px solid;
						padding: 8px 10px;
						width: 100%;
						font-size: 15px;
						border-radius: 4px;
						overflow: hidden;
						&:focus{
							box-shadow: inset 1px 2px 2px rgba(#000,.16);
						}
					}
                    label{
                        display: inline-block;
                        margin: 0 20px 0 0;
                        input{
                            margin: 0 8px 0 0;
                            display: inline-block;
                            vertical-align: middle;
                            transform: translateY(-1px);
                            cursor: pointer;
                        }
                        &:last-of-type{
                            margin: 0;
                        }
                    }
					select{
						width: auto;
					}
					textarea{
						height: 200px;
					}
                }
                th{
                    width: 20%;
                    font-size: 16px;
                    @media screen and (max-width:$tab){
                        width: 200px;
                    }
					&.hiss{
                        width: 25%;
                        padding-right: 40px;
                        @media screen and (max-width:$tab){
                            width: 300px;   
                        }
                        @media screen and (max-width:$responsive){
                            width: auto;
                            padding: 15px;
                        }
						&:after{
							content: '必須';
							float: right;
							background: $red;
							color: #FFF;
							padding: 2px 12px;
							border-radius: 4px;
							font-weight: 700;
							font-size: 12px;
						}
					}
                    @media screen and (max-width:$responsive){
                        width: auto;
                        font-size: 15px;
                        background: $glay;
                    }
                }
            }
        }
    }
}

.site-404{
	dl.ttl{
		text-align: center;
		dt{
			font-weight: 700;
			font-size: 20px;
			margin: 0 0 8px;
		}
	}
}

// USE ANIMATE
.scroll-anime{
	opacity: 0;
	transform: translateY(40px);
	@include transition(600ms);
	&._active{
		opacity: 1;
		transform: translateY(0);
	}
}

.scroll-img{
	position: relative;
	$ease-transition: cubic-bezier(0.86, 0, 0.07, 1);
	$speed: 600ms;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
        z-index: 2;
	}
	&:before{
		background: $red;
		@include transition($speed , $delay:$speed , $ease:$ease-transition);
	}
	&:after{
		background: #FFF;
		@include transition($speed , $ease: $ease-transition);
	}
	&.__active{
		&:before{
			width: 0;
		}
		&:after{
			width: 0;
		}
	}
}

.scale-enter-active, .scale-leave-active {
	transition: 600ms;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
	transform: scale(1.1);
}


#intro{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    &.active{
        &:before,
        &:after{
            height: 0;
        }
        #bar{
            animation: eve 400ms forwards;
            @keyframes eve{
                0%{
                    left: 0;
                }
                100%{
                    left:100%;
                }
            }
        }
        #num{
            opacity: 0;
            transform: scale(2);
        }
    }
    &:before,
    &:after{
        content: '';
        width: 100%;
        height: 51%;
        position: absolute;
        background: $normal;
        z-index: 1;
        @include transition(400ms);
    }
    &:before{
        top: 0;
    }
    &:after{
        bottom: 0;
    }
    #bar{
        height: 2px;
        position: fixed;
        top: 50%;
        left: 0;
        z-index: 3;
        transform: translateY(-1px);
        background: $red;
    }
    #num{
        font-family: Arial, Helvetica, "sans-serif";
        @include fontsize(120px, 20);
        line-height: 1;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        pointer-events: none;
        color: $red;
        font-weight: 700;
        z-index: 2;
        @include flex();
        align-items: center;
        justify-content: center;
        transition: opacity 400ms, transform 400ms;
    }
}

.fade-enter-active, .fade-leave-active {
	transition: 400ms;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
