.site-index{    
    .firstsec{
        position: relative;
        &.wrap{
            padding: 80px 0 160px;
            @media screen and (max-width:$tab){
                padding: 64px 0 120px;   
            }
            @media screen and (max-width:$responsive){
                padding: 32px 0;   
            }
        }
        &:before{
            content: '';
            width: 88%;
            height: calc(160px + 72px);
            background: darken($glay,1%);
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: -1;
            @media screen and (max-width:$tab){
                height: calc(120px + 64px);
            }
            @media screen and (max-width:$responsive){
                display: none;
            }
        }
        &:after{
            content: 'technology';
            @include osw();
            font-size: 200px;
            position: absolute;
            bottom: 80px;
            right: -38px;
            color: #FFF;
            font-weight: 700;
            white-space: nowrap;
            text-transform: uppercase;
            line-height: 1;
            @media screen and (max-width:$tab){
                bottom: 60px;   
            }
            @media screen and (max-width:$responsive){
                display: none;
            }
        }
        &__inner{
            position: relative;
            padding: 72px 0 144px;
            @media screen and (max-width:$tab){
                padding: 64px 0 120px;   
            }
            @media screen and (max-width:$responsive){
                padding: 0;   
            }
            .display{
                @include flex();
                justify-content: flex-end;  
            }
        }
        &__swipe{
            width: calc(50% + 120px);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            @media screen and (max-width:$tab){
                width: 400px;   
            }
            @media screen and (max-width:$responsive){
                position: relative;
                width: auto;
                height: 250px;
                margin: 0 15px;
            }
            .swiper-wrapper{
                position: absolute;
                top: 0;
                left: 0;
            }
            .swiper-pagination{
                position: absolute;
                left: 0;
                bottom: 0;
                @include flex();
                width: 100%;
                justify-content: flex-start;
                z-index: 1;
                span{
                    $size: 64px;
                    width: $size;
                    height: $size;
                    line-height: $size;
                    display: block;
                    text-align: center;
                    color: #FFF;
                    opacity: 1;
                    font-size: 15px;
                    border-radius: 0;
                    margin: 0;
                    background: $normal;
                    @media screen and (max-width:$responsive){
                        $size: 48px;
                        width: $size;
                        height: $size;
                        line-height: $size;
                    }
                    &.swiper-pagination-bullet-active{
                        background: $red;
                    }
                }
            }
            .bg{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
            }
        }
        &__box{
            width: calc(50% - 120px);
            padding: 0 0 0 64px;
            @media screen and (max-width:$tab){
                width: calc(100% - 400px);
                padding: 0 0 0 24px;
            }
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 24px 0 0;
                padding: 0;
            }
            h2{
                font-weight: 700;
                @include fontsize(24px, 3);
                line-height: 1.6;
                white-space: nowrap;
                @media screen and (max-width:1280px){
                    white-space: normal;
                    br{
                        display: none;
                    }
                }
                &:before{
                    content: attr(data-eng);
                    font-size: 13px;
                    color: $red;
                    display: block;
                    text-indent: .1em;
                    margin: 0 0 16px;
                    @media screen and (max-width:$responsive){
                        margin: 0 0 12px;   
                    }
                }
            }
            &__txt{
                @include fontsize(12px, 2);
                margin: 24px 0 0;
                line-height: 2.2;
                text-align: justify;
                @media screen and (max-width:$responsive){
                    font-size: 13px;
                    line-height: 1.88;
                    margin: 16px 0 0;
                }
            }
        }
    }
    
    .secondsec{
        background: $normal url("../../../images/common/white_dotto.png") repeat;
        background-size: 16px;
        @include flex();
        color: #FFF;
        @media screen and (max-width:$tab){
            display: block;   
        }
        &.wrap{
            padding: 0;
        }
        a{
            color: #FFF;
        }
		h2{
			text-align: center;
			padding: 80px 0 40px;
			font-weight: 700;
			font-size: 40px;
			margin: 0 auto;
			@media screen and (max-width:$tab){
				padding: 40px 0 20px;
				font-size: 28px;
			}
			@media screen and (max-width:$responsive){
				padding: 32px 0 12px;
				font-size: 20px;
			}
		}
        &__rightside{
			width: 100%;
            &__items{
                @media screen and (min-width:$responsive + 1px){
                    @include flex();
                }
                transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
                li{
                    width: 25%;
                    height: 300px;
                    position: relative;
                    @media screen and (max-width:$responsive){
                        width: 100%;
                        height: 250px;
                    }
                }
                a{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                    overflow: hidden;
                    &:hover{
                        &:before{
							background: #FFF;
                            opacity: .64;
                        }
                    }
                    &:before{
                        content: '';
                        width: 100%;
                        height: 100%;
                        background: rgba($normal,.08);
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        @include transition(200ms);
                    }
                    .caption{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        padding: 25px;
                        font-size: 16px;
                        background: rgba($normal,.8);
                        @include transition(200ms);
                        dt{
                            font-size: 20px;
                            font-weight: 700;
                            &:before{
                                content: attr(data-number);
                                @include osw();
                                font-weight: 500;
                                color: $red;
                                display: inline-block;
                                line-height: 1;
                                font-size: 28px;
                                margin: 0 8px 0 0;
                                transform: translateY(1px);
                            }
                        }
                        dd{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
	
	.addsec{
        background: $normal url("../../../images/common/white_dotto.png") repeat;
        background-size: 16px;
		.flex{
			@include flex();
			align-items: center;
			@media screen and (max-width:$responsive){
				display: block;
			}
			&__video{
				width: calc(50% - 24px);
				@media screen and (max-width:$tab){
					iframe{
						height: 360px;
					}	
				}
				@media screen and (max-width:$responsive){
					width: auto;
					iframe{
						height: 200px;
					}
				}
			}
			&__txt{
				width: calc(50% - 24px);
				color: #FFF;
				padding: 0 0 20px;
				@media screen and (max-width:$responsive){
					width: auto;
					padding: 20px 0 0;
				}
				dt{
					font-size: 48px;
					font-weight: 700;
					@media screen and (max-width:$tab){
						font-size: 24px;	
					}
					strong{
						color: $red;
						line-height: 1;
						font-size: 80px;
						display: inline-block;
						margin: 0 8px;
						transform: translateY(2px);
						@media screen and (max-width:$tab){
							font-size: 40px;	
						}
					}
				}
				dd{
					margin: 20px 0 0;
					font-size: 17px;
					@media screen and (max-width:$responsive){
						font-size: 15px;	
					}
					@media screen and (max-width:$responsive){
						font-size: 14px;	
					}
				}
			}
		}
	}
    
	.news{
        position: relative;
        z-index: 2;
		background: $normal;
		color: #FFF;
        &.wrap{
            padding: 80px 0;
            @media screen and (max-width:$tab){
                padding: 56px 0;    
            }
        }
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width:$responsive){
					display: block;	
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width:$responsive){
				width: 100%;
				padding: 0;
				display: block;	
			}
		}
        .leftside{
            width: 280px;
			@media screen and (max-width:$responsive){
				width: 100%;
			}
			h3{
				line-height: 1;
				font-size: 14px;
				margin: 0 0 16px;
				font-weight: 700;
				@media screen and (max-width:$responsive){
					font-size: 12px;	
				}
				&:before{
					content: attr(data-eng);
					font-size: 36px;
					display: inline-block;
					margin: 0 24px 0 0;
					@media screen and (max-width:$responsive){
						font-size: 20px;	
					}
				}
			}
			a{
				display: inline-block;
				font-weight: 700;
				color: #FFF;
				font-size: 15px;
				@media screen and (max-width:$responsive){
					position: absolute;
					top: 0;
					right: 15px;
					font-size: 13px;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: 13px;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: calc(100% - 280px);
            height: 130px;
            padding: 0 40px 0 0;
            overflow: auto;
            @media screen and (max-width:$tab){
                padding: 0;   
            }
			@media screen and (max-width:$responsive){
				width: 100%;
				height: 164px;
				padding: 15px;
				border: rgba(#FFF,.2) 1px solid;
				border-radius: 4px;
			}
            li{
                border-bottom: rgba(#FFF,.12) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width:$responsive){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: #FFF;;
					@media screen and (max-width:$responsive){
						display: block;
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                time,.category{
                    display: block;
                    font-weight: 600;
					@media screen and (max-width:$responsive){
						float: left;
					}
                }
                time{
                    width: 14%;
                    font-size: 12px;
                    background: lighten($normal, 8%);
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
                    font-weight: 500;
					@media screen and (max-width:$responsive){
						width: 80px;
						font-size: 10px;
						padding: 1px 12px;
						margin: 2px 0 0;
					}
                }
                h4{
                    width: 86%;
                    font-size: 16px;
                    padding: 0 0 0 32px;
					@media screen and (max-width:$responsive){
						width: auto;
						font-size: 12px;
						padding: 8px 0 0;
						clear: both;
					}
                }
            }
        }
    }
    .map{
        background: $glay;
        height: 300px;
        @media screen and (max-width:$responsive){
            height: 200px;   
        }
        iframe{
            vertical-align: bottom;
            filter: grayscale(1);
        }
    }
    .bnrarea{
        background: #FFF url("../../../images/common/black_dotto.png") repeat;
        background-size: 16px;
        &__items{
            @include flex();
            width: 80%;
            margin: 0 auto;
            @media screen and (max-width:$responsive){
                width: auto;
                display: block;
            }
            li{
                width: calc(50% - 16px);
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 16px;
                    &:last-child{
                        margin: 0;
                    }
                }
                a{
                    color: $normal;
                    font-size: 18px;
                    font-weight: 700;
                    display: block;
                    padding: 15px 0;
                    text-align: center;
                    position: relative;
                    z-index: 2;
                    @include transition(120ms);
                    @media screen and (max-width:$responsive){
                        border: $normal 3px solid;
                        padding: 12px 15px;
                        font-size: 16px;
                    }
                    &:hover{
                        color: #FFF;
                        transform: translateX(4px) translateY(4px);
                        @media screen and (max-width:$responsive){
                            color: $normal;
                            transform: translateX(0) translateY(0);
                        }
                        &:after{
                            background: $normal;
                        }
                        &:before{
                            top: 0;
                            left: 0;
                        }
                    }
                    i{
                        position: absolute;
                        top: 50%;
                        right: 24px;
                        transform: translateY(-50%);
                        color: $red;
                    }
                    &:before,
                    &:after{
                        content: '';
                        width: 100%;
                        height: 100%;
                        border: $normal 3px solid;
                        position: absolute;
                        z-index: -1;
                        @media screen and (max-width:$responsive){
                            display: none;
                        }
                    }
                    &:before{
                        top: 10px;
                        left: 10px;
                        @include transition(120ms);
                    }
                    &:after{
                        background: #FFF;
                        top: 0;
                        left: 0;
                        @include transition(120ms);
                    }
                }
            }
        }
    }
    .kussion{
        background: url("../../../images/index/kussion.jpg") no-repeat fixed;
        background-size: cover;
        position: relative;
        &.wrap{
            padding: 64px 0;
            @media screen and (max-width:$responsive){
                padding: 32px 15px;   
            }
        }
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            background: #000;
            opacity: .48;
            position: absolute;
            top: 0;
            left: 0;
        }
        &__data{
            color: #FFF;
            text-align: center;
            &__title{
                font-size: 24px;
                font-weight: 700;
                margin: 0 0 16px;
                @media screen and (max-width:$responsive){
                    font-size: 20px;   
                }
                &:before{
                    content: attr(data-eng);
                    font-size: 10px;
                    color: $red;
                    display: block;
                }
            }
            &__meta{
                font-size: 17px;
                @media screen and (max-width:$responsive){
                    font-size: 14px;
                    br{
                        display: none;
                    }
                }
            }
        }
    }
}
