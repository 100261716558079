@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

@media screen and (min-width: 1024px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #121212;
    background: #FFF;
    font-size: 15px;
    line-height: 1.8;
    font-family: 'Noto Sans JP' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #f43300; }
    a:hover {
      text-decoration: none;
      color: #ff5528; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, .site-sitemap ul li dl:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    min-width: 1024px;
    max-width: 1324px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 1023px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #121212;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: 'Noto Sans JP' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #f43300; }
    a:hover {
      text-decoration: none;
      color: #ff5528; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, .site-sitemap ul li dl:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    min-width: auto;
    max-width: auto300px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #121212;
    background: #FFF;
    font-size: 13px;
    line-height: 1.8;
    font-family: 'Noto Sans JP' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #f43300; }
    a:hover {
      text-decoration: none;
      color: #ff5528; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, .site-sitemap ul li dl:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    min-width: auto;
    max-width: auto300px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  z-index: 2000;
  padding: 0 40px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  color: #FFF;
  -webkit-transition: all 400ms 0ms ease-in-out;
  -moz-transition: all 400ms 0ms ease-in-out;
  -ms-transition: all 400ms 0ms ease-in-out;
  -o-transition: all 400ms 0ms ease-in-out;
  transition: all 400ms 0ms ease-in-out;
  /*
    .menu-trigger{
        cursor: pointer;
        &__item{
            $side: 30px;
            width: $side;
            height: $side;
            margin: 0 auto;
            position: relative;
            &__border,
            &:before,
            &:after{
                content: '';
                width: 26px;
                height: 3px;
                background: $blue;
                position: absolute;
                border-radius: 999px;
                top: 7px;
                left: 50%;
                transform: translateX(-50%);
            }
            &:after{
                top: auto;
                bottom: 7px;
            }
            &__border{
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }
        &__text{
            @include osw();
            white-space: nowrap;
            display: block;
            font-size: 12px;
            line-height: 1;
            text-align: center;
            margin: 2px 0 0;
            letter-spacing: .08em;
        }
    }
    */ }
  @media screen and (max-width: 1023px) {
    #site-header {
      height: 70px;
      padding: 0 20px;
      position: absolute; } }
  #site-header .trigger,
  #site-header .close {
    display: none; }
    @media screen and (max-width: 1023px) {
      #site-header .trigger,
      #site-header .close {
        width: 40px;
        height: 40px;
        border: #FFF 1px solid;
        position: fixed;
        display: block;
        top: 15px;
        right: 20px;
        border-radius: 6px;
        position: fixed;
        cursor: pointer;
        -webkit-transition: all 200ms 0ms ease-in-out;
        -moz-transition: all 200ms 0ms ease-in-out;
        -ms-transition: all 200ms 0ms ease-in-out;
        -o-transition: all 200ms 0ms ease-in-out;
        transition: all 200ms 0ms ease-in-out; }
        #site-header .trigger:before, #site-header .trigger:after,
        #site-header .close:before,
        #site-header .close:after {
          content: '';
          width: 18px;
          height: 1px;
          background: #FFF;
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
        #site-header .trigger:after,
        #site-header .close:after {
          top: auto;
          bottom: 40%; } }
  @media screen and (min-width: 1024px) {
    #site-header.active, #site-header:hover {
      background: #FFF;
      color: #121212; }
      #site-header.active .logo:after, #site-header:hover .logo:after {
        opacity: 1; }
      #site-header.active .logo img, #site-header:hover .logo img {
        opacity: 0; }
      #site-header.active .gnavi__inner > ul li > a, #site-header:hover .gnavi__inner > ul li > a {
        color: #121212; }
      #site-header.active .gnavi__inner > ul li dl dt a, #site-header:hover .gnavi__inner > ul li dl dt a {
        color: #121212; }
      #site-header.active .subnavi .lang a, #site-header:hover .subnavi .lang a {
        color: #121212; } }
  @media screen and (max-width: 1023px) {
    #site-header.active .trigger {
      border-color: #121212;
      background: #121212; }
      #site-header.active .trigger:before, #site-header.active .trigger:after {
        background: #FFF; } }
  #site-header .logo {
    margin: 2px 0 0;
    width: 200px;
    position: relative; }
    #site-header .logo:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../../images/common/logo_2x.png") no-repeat;
      background-size: cover;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: all 400ms 0ms ease-in-out;
      -moz-transition: all 400ms 0ms ease-in-out;
      -ms-transition: all 400ms 0ms ease-in-out;
      -o-transition: all 400ms 0ms ease-in-out;
      transition: all 400ms 0ms ease-in-out; }
    #site-header .logo img {
      width: 100%;
      opacity: 1;
      -webkit-transition: all 400ms 0ms ease-in-out;
      -moz-transition: all 400ms 0ms ease-in-out;
      -ms-transition: all 400ms 0ms ease-in-out;
      -o-transition: all 400ms 0ms ease-in-out;
      transition: all 400ms 0ms ease-in-out; }
  #site-header .gnavi {
    margin: 0 0 0 80px; }
    @media screen and (max-width: 1120px) {
      #site-header .gnavi {
        margin: 0 0 0 48px; } }
    @media screen and (max-width: 1023px) {
      #site-header .gnavi {
        display: block;
        opacity: 0; }
        #site-header .gnavi.active {
          opacity: 1; } }
    @media screen and (min-width: 1024px) {
      #site-header .gnavi__inner {
        display: block !important; } }
    @media screen and (max-width: 1023px) {
      #site-header .gnavi__inner {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #FFF;
        z-index: 9000;
        padding: 70px 0 0;
        overflow: auto; } }
    @media screen and (max-width: 1023px) {
      #site-header .gnavi__inner .close {
        position: absolute;
        border-color: #f2f2f2;
        background: #f2f2f2; }
        #site-header .gnavi__inner .close:before, #site-header .gnavi__inner .close:after {
          background: #121212;
          bottom: auto;
          top: 50%;
          left: 10px;
          transform: rotate(45deg); }
        #site-header .gnavi__inner .close:after {
          transform: rotate(-45deg); } }
    #site-header .gnavi__inner > ul {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: flex-start; }
      @media screen and (max-width: 1023px) {
        #site-header .gnavi__inner > ul {
          display: block;
          color: #121212; } }
      #site-header .gnavi__inner > ul > li {
        list-style: none;
        font-weight: 500;
        margin: 0 40px 0 0; }
        @media screen and (max-width: 1120px) {
          #site-header .gnavi__inner > ul > li {
            margin: 0 24px 0 0; } }
        @media screen and (max-width: 1023px) {
          #site-header .gnavi__inner > ul > li {
            margin: 0;
            color: #121212;
            border-bottom: #e8e8e8 1px solid;
            font-size: 16px; }
            #site-header .gnavi__inner > ul > li:first-child {
              border-top: #e8e8e8 1px solid; } }
        #site-header .gnavi__inner > ul > li i {
          display: inline-block;
          margin: 0 0 0 8px;
          font-size: .8rem;
          transform: translateY(-1px); }
          @media screen and (max-width: 1023px) {
            #site-header .gnavi__inner > ul > li i {
              position: absolute;
              top: 50%;
              right: 20px;
              transform: translateY(-50%);
              margin: 0;
              color: #f43300; } }
        #site-header .gnavi__inner > ul > li > a {
          color: #FFF;
          -webkit-transition: all 400ms 0ms ease-in-out;
          -moz-transition: all 400ms 0ms ease-in-out;
          -ms-transition: all 400ms 0ms ease-in-out;
          -o-transition: all 400ms 0ms ease-in-out;
          transition: all 400ms 0ms ease-in-out;
          position: relative; }
          @media screen and (max-width: 1023px) {
            #site-header .gnavi__inner > ul > li > a {
              color: #121212;
              padding: 15px 20px;
              display: block;
              position: relative; } }
          #site-header .gnavi__inner > ul > li > a:after {
            content: '';
            width: 6px;
            height: 6px;
            background: #f43300;
            position: absolute;
            border-radius: 50%;
            left: 50%;
            opacity: 0;
            top: 0;
            transform: translateX(-50%);
            -webkit-transition: all 200ms 0ms ease-in-out;
            -moz-transition: all 200ms 0ms ease-in-out;
            -ms-transition: all 200ms 0ms ease-in-out;
            -o-transition: all 200ms 0ms ease-in-out;
            transition: all 200ms 0ms ease-in-out; }
          @media screen and (min-width: 1024px) {
            #site-header .gnavi__inner > ul > li > a:hover:after {
              opacity: 1;
              top: -8px; } }
        #site-header .gnavi__inner > ul > li:last-child {
          margin: 0; }
        @media screen and (min-width: 1024px) {
          #site-header .gnavi__inner > ul > li dl:hover dt i {
            color: #f43300;
            transform: rotate(-180deg); }
          #site-header .gnavi__inner > ul > li dl:hover dd {
            display: block !important;
            animation: fade 400ms forwards; }
          @keyframes fade {
            0% {
              opacity: 0;
              transform: translateY(-10px); }
            100% {
              opacity: 1;
              transform: translateY(0); } } }
        #site-header .gnavi__inner > ul > li dl dt {
          cursor: pointer;
          position: relative;
          font-weight: 500; }
          @media screen and (max-width: 1023px) {
            #site-header .gnavi__inner > ul > li dl dt {
              padding: 15px 20px; }
              #site-header .gnavi__inner > ul > li dl dt:before {
                display: none; }
              #site-header .gnavi__inner > ul > li dl dt.active i {
                transform: translateY(-50%) rotate(180deg); } }
          #site-header .gnavi__inner > ul > li dl dt a {
            color: #FFF;
            -webkit-transition: all 400ms 0ms ease-in-out;
            -moz-transition: all 400ms 0ms ease-in-out;
            -ms-transition: all 400ms 0ms ease-in-out;
            -o-transition: all 400ms 0ms ease-in-out;
            transition: all 400ms 0ms ease-in-out; }
            @media screen and (max-width: 1023px) {
              #site-header .gnavi__inner > ul > li dl dt a {
                color: #121212; } }
          #site-header .gnavi__inner > ul > li dl dt i {
            display: inline-block;
            -webkit-transition: all 120ms 0ms ease-in-out;
            -moz-transition: all 120ms 0ms ease-in-out;
            -ms-transition: all 120ms 0ms ease-in-out;
            -o-transition: all 120ms 0ms ease-in-out;
            transition: all 120ms 0ms ease-in-out; }
          #site-header .gnavi__inner > ul > li dl dt:before {
            content: '';
            width: 100%;
            height: 92px;
            position: absolute;
            top: -32px;
            left: 0;
            z-index: -1; }
        #site-header .gnavi__inner > ul > li dl dd {
          width: 100%;
          position: absolute;
          top: 88px;
          left: 0;
          background: #f2f2f2;
          opacity: 0;
          display: none;
          box-shadow: 0 16px 16px rgba(0, 0, 0, 0.05); }
          @media screen and (max-width: 1023px) {
            #site-header .gnavi__inner > ul > li dl dd {
              position: relative;
              top: 0;
              left: 0;
              display: block;
              box-shadow: none;
              opacity: 1; } }
          #site-header .gnavi__inner > ul > li dl dd ul {
            display: -webkit-flex;
            display: -moz-flex;
            display: -o-flex;
            display: -ms-flex;
            display: flex;
            -moz-flex-flow: row wrap;
            -webkit-flex-flow: row wrap;
            -o-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-justify-content: space-between;
            -o-justify-content: space-between;
            -ms-justify-content: space-between;
            -moz-justify-content: space-between;
            justify-content: space-between;
            justify-content: flex-start;
            padding: 20px 0; }
            @media screen and (max-width: 1023px) {
              #site-header .gnavi__inner > ul > li dl dd ul {
                display: block;
                padding: 0; } }
            #site-header .gnavi__inner > ul > li dl dd ul li {
              width: 16.6%;
              padding: 20px 0;
              border-right: rgba(0, 0, 0, 0.08) 1px solid; }
              #site-header .gnavi__inner > ul > li dl dd ul li:nth-child(6n+6) {
                border-right: none; }
              @media screen and (max-width: 1023px) {
                #site-header .gnavi__inner > ul > li dl dd ul li {
                  width: auto;
                  padding: 0;
                  border-bottom: rgba(0, 0, 0, 0.08) 1px solid; }
                  #site-header .gnavi__inner > ul > li dl dd ul li:last-child {
                    border: none; } }
              #site-header .gnavi__inner > ul > li dl dd ul li figure {
                text-align: center; }
                @media screen and (max-width: 1023px) {
                  #site-header .gnavi__inner > ul > li dl dd ul li figure {
                    display: -webkit-flex;
                    display: -moz-flex;
                    display: -o-flex;
                    display: -ms-flex;
                    display: flex;
                    -moz-flex-flow: row wrap;
                    -webkit-flex-flow: row wrap;
                    -o-flex-flow: row wrap;
                    -ms-flex-flow: row wrap;
                    flex-flow: row wrap;
                    -webkit-justify-content: space-between;
                    -o-justify-content: space-between;
                    -ms-justify-content: space-between;
                    -moz-justify-content: space-between;
                    justify-content: space-between;
                    align-items: center;
                    justify-content: flex-start; } }
                #site-header .gnavi__inner > ul > li dl dd ul li figure img {
                  width: 80px; }
                  @media screen and (max-width: 1023px) {
                    #site-header .gnavi__inner > ul > li dl dd ul li figure img {
                      width: 32px;
                      margin: 0 15px 0 0; } }
                #site-header .gnavi__inner > ul > li dl dd ul li figure figcaption .mini {
                  font-size: 12px;
                  display: block;
                  margin: 0 15px; }
                  @media screen and (max-width: 1023px) {
                    #site-header .gnavi__inner > ul > li dl dd ul li figure figcaption .mini {
                      display: none; } }
              #site-header .gnavi__inner > ul > li dl dd ul li a {
                font-size: 13.5px;
                display: block; }
                @media screen and (max-width: 1023px) {
                  #site-header .gnavi__inner > ul > li dl dd ul li a {
                    color: #121212;
                    font-size: 16px;
                    padding: 15px 20px; } }
    #site-header .gnavi .spmeta {
      display: none; }
      @media screen and (max-width: 1023px) {
        #site-header .gnavi .spmeta {
          display: block;
          margin: 16px 20px 0; }
          #site-header .gnavi .spmeta__contact {
            display: block;
            background: #f43300;
            color: #FFF;
            padding: 10px 0;
            font-size: 16px;
            border-radius: 5px;
            text-align: center; }
            #site-header .gnavi .spmeta__contact i {
              display: inline-block;
              margin: 0 8px 0 0; }
          #site-header .gnavi .spmeta__lang {
            margin: 15px 0 0;
            text-align: center; }
            #site-header .gnavi .spmeta__lang li {
              list-style: none;
              display: inline-block;
              margin: 0 8px; }
              #site-header .gnavi .spmeta__lang li a {
                color: #121212; }
              #site-header .gnavi .spmeta__lang li.active a {
                color: #f43300;
                font-weight: 700; } }
  #site-header .subnavi {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0 0 40px;
    -webkit-transition: all 400ms 0ms ease-in-out;
    -moz-transition: all 400ms 0ms ease-in-out;
    -ms-transition: all 400ms 0ms ease-in-out;
    -o-transition: all 400ms 0ms ease-in-out;
    transition: all 400ms 0ms ease-in-out; }
    @media screen and (max-width: 1023px) {
      #site-header .subnavi {
        display: none; } }
    #site-header .subnavi ul {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center; }
      #site-header .subnavi ul a {
        color: #FFF; }
      #site-header .subnavi ul li.lang {
        margin: 0 40px 0 0; }
        #site-header .subnavi ul li.lang a {
          display: inline-block;
          position: relative;
          -webkit-transition: all 400ms 0ms ease-in-out;
          -moz-transition: all 400ms 0ms ease-in-out;
          -ms-transition: all 400ms 0ms ease-in-out;
          -o-transition: all 400ms 0ms ease-in-out;
          transition: all 400ms 0ms ease-in-out; }
          #site-header .subnavi ul li.lang a:hover {
            color: #f43300; }
            #site-header .subnavi ul li.lang a:hover:first-of-type:after {
              color: #121212; }
          #site-header .subnavi ul li.lang a.active:before {
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            top: -8px;
            left: 8px;
            background: #f43300; }
          #site-header .subnavi ul li.lang a:first-of-type:after {
            content: '/';
            margin: 0 12px;
            display: inline-block; }
      #site-header .subnavi ul li .contact {
        position: relative;
        padding: 14px 24px;
        display: block;
        height: 88px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        -webkit-transition: all 400ms 0ms ease-in-out;
        -moz-transition: all 400ms 0ms ease-in-out;
        -ms-transition: all 400ms 0ms ease-in-out;
        -o-transition: all 400ms 0ms ease-in-out;
        transition: all 400ms 0ms ease-in-out; }
        #site-header .subnavi ul li .contact:before, #site-header .subnavi ul li .contact:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #121212;
          z-index: 1; }
        #site-header .subnavi ul li .contact:after {
          width: 0;
          background: #f43300;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
        #site-header .subnavi ul li .contact:hover {
          text-decoration: none;
          opacity: 1; }
          #site-header .subnavi ul li .contact:hover:after {
            width: 100%; }
        #site-header .subnavi ul li .contact i, #site-header .subnavi ul li .contact span {
          position: relative;
          z-index: 2; }
        #site-header .subnavi ul li .contact i {
          width: 100%;
          margin: 0;
          font-size: 20px;
          display: block;
          text-align: center; }
        #site-header .subnavi ul li .contact span {
          display: block;
          font-size: 13px;
          text-align: center;
          letter-spacing: .12em; }
      #site-header .subnavi ul li i {
        display: inline-block;
        margin: 0 0 0 8px;
        font-size: .8rem;
        transform: translateY(-1px);
        -webkit-transition: all 400ms 0ms ease-in-out;
        -moz-transition: all 400ms 0ms ease-in-out;
        -ms-transition: all 400ms 0ms ease-in-out;
        -o-transition: all 400ms 0ms ease-in-out;
        transition: all 400ms 0ms ease-in-out; }

#site-footer {
  background: #121212; }
  #site-footer .box {
    padding: 0 0 64px; }
    @media screen and (max-width: 1023px) {
      #site-footer .box {
        padding: 0 0 40px; } }
    #site-footer .box .pagetop {
      background: #212121;
      color: rgba(255, 255, 255, 0.88);
      text-align: center;
      display: block;
      padding: 16px 0;
      margin: 0 0 64px;
      width: 100%;
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out; }
      @media screen and (max-width: 1023px) {
        #site-footer .box .pagetop {
          margin: 0 0 40px; } }
      #site-footer .box .pagetop:hover {
        background: #363636; }
    #site-footer .box .flexer {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      @media screen and (max-width: 1023px) {
        #site-footer .box .flexer {
          display: block;
          margin: 0 15px; } }
      @media screen and (max-width: 768px) {
        #site-footer .box .flexer {
          margin: 0; } }
    #site-footer .box .company {
      order: 2;
      width: 50%;
      color: #FFF;
      padding: 0 0 0 40px;
      position: relative; }
      @media screen and (max-width: 1023px) {
        #site-footer .box .company {
          padding: 0;
          margin: 0 0 24px; } }
      @media screen and (max-width: 1023px) {
        #site-footer .box .company {
          width: auto; } }
      #site-footer .box .company .youtube {
        position: absolute;
        top: 0;
        right: 0;
        color: #FFF;
        font-size: 12px; }
        #site-footer .box .company .youtube:hover {
          text-decoration: underline; }
        #site-footer .box .company .youtube i {
          color: #f43300;
          display: inline-block;
          margin: 0 8px 0 0; }
      #site-footer .box .company dl dt {
        font-size: 15px;
        font-weight: 700;
        margin: 0 0 14px;
        padding: 0 0 14px;
        border-bottom: rgba(255, 255, 255, 0.16) 1px solid; }
      #site-footer .box .company dl dd {
        font-size: 13px; }
      #site-footer .box .company .lang {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.32); }
        #site-footer .box .company .lang a {
          color: #FFF;
          display: inline-block;
          margin: 0 8px; }
          #site-footer .box .company .lang a.active {
            color: #f43300; }
          #site-footer .box .company .lang a:hover {
            text-decoration: underline; }
          #site-footer .box .company .lang a:last-of-type {
            margin-right: 0; }
    #site-footer .box nav {
      width: 50%; }
      @media screen and (max-width: 1023px) {
        #site-footer .box nav {
          width: auto; } }
      #site-footer .box nav ul.fnav {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between; }
        @media screen and (max-width: 1023px) {
          #site-footer .box nav ul.fnav {
            display: block; } }
        #site-footer .box nav ul.fnav li {
          list-style: none;
          color: #FFF;
          width: 33.3%; }
          @media screen and (max-width: 1023px) {
            #site-footer .box nav ul.fnav li {
              width: 100%;
              margin: 0 0 16px;
              border: rgba(255, 255, 255, 0.16) 1px solid;
              border-radius: 6px; }
              #site-footer .box nav ul.fnav li:last-child {
                margin: 0; } }
          #site-footer .box nav ul.fnav li a {
            color: rgba(255, 255, 255, 0.8); }
            #site-footer .box nav ul.fnav li a:hover {
              color: #FFF;
              text-decoration: underline; }
          @media screen and (max-width: 1023px) {
            #site-footer .box nav ul.fnav li dl.active dt:after {
              transform: translateY(-50%) rotate(180deg); }
            #site-footer .box nav ul.fnav li dl.active dd {
              display: block; } }
          #site-footer .box nav ul.fnav li dl dt {
            font-weight: 700;
            margin: 0 0 10px;
            font-size: 15px;
            position: relative; }
            @media screen and (max-width: 1023px) {
              #site-footer .box nav ul.fnav li dl dt {
                font-size: 14px;
                padding: 12px 20px;
                background: rgba(255, 255, 255, 0.08);
                margin: 0; }
                #site-footer .box nav ul.fnav li dl dt:after {
                  content: "\f078";
                  font-family: "Font Awesome 5 Free";
                  font-weight: 900;
                  font-size: 11px;
                  position: absolute;
                  top: 50%;
                  right: 20px;
                  transform: translateY(-50%);
                  opacity: .48;
                  -webkit-transition: all 200ms 0ms ease-in-out;
                  -moz-transition: all 200ms 0ms ease-in-out;
                  -ms-transition: all 200ms 0ms ease-in-out;
                  -o-transition: all 200ms 0ms ease-in-out;
                  transition: all 200ms 0ms ease-in-out; } }
          #site-footer .box nav ul.fnav li dl dd {
            font-size: 12px;
            margin: 4px 0 0;
            display: block; }
            @media screen and (max-width: 1023px) {
              #site-footer .box nav ul.fnav li dl dd {
                border-bottom: rgba(255, 255, 255, 0.16) 1px solid; }
                #site-footer .box nav ul.fnav li dl dd a {
                  padding: 12px 20px;
                  display: block;
                  position: relative; }
                  #site-footer .box nav ul.fnav li dl dd a:after {
                    content: "\f0a9";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    font-size: 11px;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                    opacity: .24; }
                  #site-footer .box nav ul.fnav li dl dd a:hover {
                    text-decoration: none; }
                #site-footer .box nav ul.fnav li dl dd:last-of-type {
                  border: none; } }
  #site-footer .copy {
    text-align: center;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    border-top: rgba(255, 255, 255, 0.12) 1px solid;
    padding: 24px 0; }

#pagetop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  overflow: hidden;
  transform: scale(0);
  transition: transform 0.2s linear; }
  @media screen and (max-width: 1023px) {
    #pagetop {
      width: 40px;
      height: 40px;
      right: 15px;
      bottom: 15px; } }
  #pagetop.active {
    transform: scale(1);
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  #pagetop img {
    width: 100%; }

#page-title {
  position: relative;
  overflow: hidden; }
  #page-title.active .bg {
    transform: scale(1); }
  #page-title.active h1 {
    opacity: 1;
    transform: translateY(0); }
    @media screen and (max-width: 768px) {
      #page-title.active h1 {
        transform: translateY(-8px); } }
  #page-title #particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none; }
  #page-title .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    z-index: 1;
    transform: scale(1.04);
    -webkit-transition: all 1600ms 0ms linear;
    -moz-transition: all 1600ms 0ms linear;
    -ms-transition: all 1600ms 0ms linear;
    -o-transition: all 1600ms 0ms linear;
    transition: all 1600ms 0ms linear; }
  #page-title:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(18, 18, 18, 0.48);
    box-shadow: inset 0 88px 88px rgba(18, 18, 18, 0.72);
    z-index: 2; }
    @media screen and (max-width: 768px) {
      #page-title:before {
        opacity: .88; } }
  #page-title .display {
    z-index: 3;
    height: 400px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    padding: 53.01205px 0 0; }
    @media screen and (max-width: 1023px) {
      #page-title .display {
        height: 240px; } }
    @media screen and (max-width: 768px) {
      #page-title .display {
        height: 220px; } }
  #page-title h1 {
    color: #FFF;
    font-size: 20px;
    font-weight: 800;
    line-height: 1;
    letter-spacing: .08em;
    text-shadow: 0 0 32px rgba(0, 0, 0, 0.24);
    transform: translateY(20px);
    opacity: 0;
    text-align: center;
    -webkit-transition: all 800ms 200ms ease-in-out;
    -moz-transition: all 800ms 200ms ease-in-out;
    -ms-transition: all 800ms 200ms ease-in-out;
    -o-transition: all 800ms 200ms ease-in-out;
    transition: all 800ms 200ms ease-in-out; }
    @media screen and (max-width: 1023px) {
      #page-title h1 {
        font-size: 16px; } }
    @media screen and (max-width: 768px) {
      #page-title h1 {
        font-size: 13px; } }
    #page-title h1:before {
      font-family: 'Open Sans', sans-serif;
      content: attr(data-eng);
      letter-spacing: .06em;
      font-size: 56px;
      display: block;
      margin: 0 0 16px; }
      @media screen and (max-width: 1023px) {
        #page-title h1:before {
          font-size: 40px; } }
      @media screen and (max-width: 768px) {
        #page-title h1:before {
          font-size: 24px;
          margin: 0 0 10px; } }

#breadcrumb {
  background: #ededed; }
  @media screen and (max-width: 768px) {
    #breadcrumb {
      width: 100%;
      overflow: hidden;
      position: relative; }
      #breadcrumb:after {
        content: '';
        position: absolute;
        background: -moz-linear-gradient(left, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
        background: -webkit-linear-gradient(left, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
        background: linear-gradient(to right, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
        z-index: 100; } }
  #breadcrumb a {
    color: #121212; }
  #breadcrumb ol {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 13px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start; }
    @media screen and (max-width: 1023px) {
      #breadcrumb ol {
        padding: 20px; } }
    @media screen and (max-width: 768px) {
      #breadcrumb ol {
        padding: 15px;
        font-size: 12px;
        width: 200%; } }
    #breadcrumb ol li:first-child a:hover {
      text-decoration: underline; }
    #breadcrumb ol li:first-child a i {
      display: inline-block;
      margin: 0 8px 0 0;
      color: #f43300; }
    #breadcrumb ol li:last-child:after {
      display: none; }
    #breadcrumb ol li:after {
      content: "\f054";
      font-family: "Font Awesome 5 Free";
      font-size: 8px;
      font-weight: 900;
      opacity: .4;
      display: inline-block;
      margin: 0 16px;
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px); }

.site-index .firstsec {
  position: relative; }
  .site-index .firstsec.wrap {
    padding: 80px 0 160px; }
    @media screen and (max-width: 1023px) {
      .site-index .firstsec.wrap {
        padding: 64px 0 120px; } }
    @media screen and (max-width: 768px) {
      .site-index .firstsec.wrap {
        padding: 32px 0; } }
  .site-index .firstsec:before {
    content: '';
    width: 88%;
    height: calc(160px + 72px);
    background: #efefef;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1; }
    @media screen and (max-width: 1023px) {
      .site-index .firstsec:before {
        height: calc(120px + 64px); } }
    @media screen and (max-width: 768px) {
      .site-index .firstsec:before {
        display: none; } }
  .site-index .firstsec:after {
    content: 'technology';
    font-family: 'Oswald', sans-serif;
    font-size: 200px;
    position: absolute;
    bottom: 80px;
    right: -38px;
    color: #FFF;
    font-weight: 700;
    white-space: nowrap;
    text-transform: uppercase;
    line-height: 1; }
    @media screen and (max-width: 1023px) {
      .site-index .firstsec:after {
        bottom: 60px; } }
    @media screen and (max-width: 768px) {
      .site-index .firstsec:after {
        display: none; } }
  .site-index .firstsec__inner {
    position: relative;
    padding: 72px 0 144px; }
    @media screen and (max-width: 1023px) {
      .site-index .firstsec__inner {
        padding: 64px 0 120px; } }
    @media screen and (max-width: 768px) {
      .site-index .firstsec__inner {
        padding: 0; } }
    .site-index .firstsec__inner .display {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      justify-content: flex-end; }
  .site-index .firstsec__swipe {
    width: calc(50% + 120px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
    @media screen and (max-width: 1023px) {
      .site-index .firstsec__swipe {
        width: 400px; } }
    @media screen and (max-width: 768px) {
      .site-index .firstsec__swipe {
        position: relative;
        width: auto;
        height: 250px;
        margin: 0 15px; } }
    .site-index .firstsec__swipe .swiper-wrapper {
      position: absolute;
      top: 0;
      left: 0; }
    .site-index .firstsec__swipe .swiper-pagination {
      position: absolute;
      left: 0;
      bottom: 0;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      width: 100%;
      justify-content: flex-start;
      z-index: 1; }
      .site-index .firstsec__swipe .swiper-pagination span {
        width: 64px;
        height: 64px;
        line-height: 64px;
        display: block;
        text-align: center;
        color: #FFF;
        opacity: 1;
        font-size: 15px;
        border-radius: 0;
        margin: 0;
        background: #121212; }
        @media screen and (max-width: 768px) {
          .site-index .firstsec__swipe .swiper-pagination span {
            width: 48px;
            height: 48px;
            line-height: 48px; } }
        .site-index .firstsec__swipe .swiper-pagination span.swiper-pagination-bullet-active {
          background: #f43300; }
    .site-index .firstsec__swipe .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover; }
  .site-index .firstsec__box {
    width: calc(50% - 120px);
    padding: 0 0 0 64px; }
    @media screen and (max-width: 1023px) {
      .site-index .firstsec__box {
        width: calc(100% - 400px);
        padding: 0 0 0 24px; } }
    @media screen and (max-width: 768px) {
      .site-index .firstsec__box {
        width: auto;
        margin: 24px 0 0;
        padding: 0; } }
    .site-index .firstsec__box h2 {
      font-weight: 700;
      font-size: calc(24px + 3 * (100vw - 450px)/ 900);
      line-height: 1.6;
      white-space: nowrap; }
      @media screen and (max-width: 1280px) {
        .site-index .firstsec__box h2 {
          white-space: normal; }
          .site-index .firstsec__box h2 br {
            display: none; } }
      .site-index .firstsec__box h2:before {
        content: attr(data-eng);
        font-size: 13px;
        color: #f43300;
        display: block;
        text-indent: .1em;
        margin: 0 0 16px; }
        @media screen and (max-width: 768px) {
          .site-index .firstsec__box h2:before {
            margin: 0 0 12px; } }
    .site-index .firstsec__box__txt {
      font-size: calc(12px + 2 * (100vw - 450px)/ 900);
      margin: 24px 0 0;
      line-height: 2.2;
      text-align: justify; }
      @media screen and (max-width: 768px) {
        .site-index .firstsec__box__txt {
          font-size: 13px;
          line-height: 1.88;
          margin: 16px 0 0; } }

.site-index .secondsec {
  background: #121212 url("../../../images/common/white_dotto.png") repeat;
  background-size: 16px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  color: #FFF; }
  @media screen and (max-width: 1023px) {
    .site-index .secondsec {
      display: block; } }
  .site-index .secondsec.wrap {
    padding: 0; }
  .site-index .secondsec a {
    color: #FFF; }
  .site-index .secondsec h2 {
    text-align: center;
    padding: 80px 0 40px;
    font-weight: 700;
    font-size: 40px;
    margin: 0 auto; }
    @media screen and (max-width: 1023px) {
      .site-index .secondsec h2 {
        padding: 40px 0 20px;
        font-size: 28px; } }
    @media screen and (max-width: 768px) {
      .site-index .secondsec h2 {
        padding: 32px 0 12px;
        font-size: 20px; } }
  .site-index .secondsec__rightside {
    width: 100%; }
    .site-index .secondsec__rightside__items {
      transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1); }
      @media screen and (min-width: 769px) {
        .site-index .secondsec__rightside__items {
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between; } }
      .site-index .secondsec__rightside__items li {
        width: 25%;
        height: 300px;
        position: relative; }
        @media screen and (max-width: 768px) {
          .site-index .secondsec__rightside__items li {
            width: 100%;
            height: 250px; } }
      .site-index .secondsec__rightside__items a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        overflow: hidden; }
        .site-index .secondsec__rightside__items a:hover:before {
          background: #FFF;
          opacity: .64; }
        .site-index .secondsec__rightside__items a:before {
          content: '';
          width: 100%;
          height: 100%;
          background: rgba(18, 18, 18, 0.08);
          position: absolute;
          left: 0;
          bottom: 0;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
        .site-index .secondsec__rightside__items a .caption {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          padding: 25px;
          font-size: 16px;
          background: rgba(18, 18, 18, 0.8);
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
          .site-index .secondsec__rightside__items a .caption dt {
            font-size: 20px;
            font-weight: 700; }
            .site-index .secondsec__rightside__items a .caption dt:before {
              content: attr(data-number);
              font-family: 'Oswald', sans-serif;
              font-weight: 500;
              color: #f43300;
              display: inline-block;
              line-height: 1;
              font-size: 28px;
              margin: 0 8px 0 0;
              transform: translateY(1px); }
          .site-index .secondsec__rightside__items a .caption dd {
            font-size: 12px; }

.site-index .addsec {
  background: #121212 url("../../../images/common/white_dotto.png") repeat;
  background-size: 16px; }
  .site-index .addsec .flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .site-index .addsec .flex {
        display: block; } }
    .site-index .addsec .flex__video {
      width: calc(50% - 24px); }
      @media screen and (max-width: 1023px) {
        .site-index .addsec .flex__video iframe {
          height: 360px; } }
      @media screen and (max-width: 768px) {
        .site-index .addsec .flex__video {
          width: auto; }
          .site-index .addsec .flex__video iframe {
            height: 200px; } }
    .site-index .addsec .flex__txt {
      width: calc(50% - 24px);
      color: #FFF;
      padding: 0 0 20px; }
      @media screen and (max-width: 768px) {
        .site-index .addsec .flex__txt {
          width: auto;
          padding: 20px 0 0; } }
      .site-index .addsec .flex__txt dt {
        font-size: 48px;
        font-weight: 700; }
        @media screen and (max-width: 1023px) {
          .site-index .addsec .flex__txt dt {
            font-size: 24px; } }
        .site-index .addsec .flex__txt dt strong {
          color: #f43300;
          line-height: 1;
          font-size: 80px;
          display: inline-block;
          margin: 0 8px;
          transform: translateY(2px); }
          @media screen and (max-width: 1023px) {
            .site-index .addsec .flex__txt dt strong {
              font-size: 40px; } }
      .site-index .addsec .flex__txt dd {
        margin: 20px 0 0;
        font-size: 17px; }
        @media screen and (max-width: 768px) {
          .site-index .addsec .flex__txt dd {
            font-size: 15px; } }
        @media screen and (max-width: 768px) {
          .site-index .addsec .flex__txt dd {
            font-size: 14px; } }

.site-index .news {
  position: relative;
  z-index: 2;
  background: #121212;
  color: #FFF; }
  .site-index .news.wrap {
    padding: 80px 0; }
    @media screen and (max-width: 1023px) {
      .site-index .news.wrap {
        padding: 56px 0; } }
  .site-index .news .display.flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-index .news .display.flex {
        display: block; } }
  .site-index .news .newsbox {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-index .news .newsbox {
        width: 100%;
        padding: 0;
        display: block; } }
  .site-index .news .leftside {
    width: 280px; }
    @media screen and (max-width: 768px) {
      .site-index .news .leftside {
        width: 100%; } }
    .site-index .news .leftside h3 {
      line-height: 1;
      font-size: 14px;
      margin: 0 0 16px;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .site-index .news .leftside h3 {
          font-size: 12px; } }
      .site-index .news .leftside h3:before {
        content: attr(data-eng);
        font-size: 36px;
        display: inline-block;
        margin: 0 24px 0 0; }
        @media screen and (max-width: 768px) {
          .site-index .news .leftside h3:before {
            font-size: 20px; } }
    .site-index .news .leftside a {
      display: inline-block;
      font-weight: 700;
      color: #FFF;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .site-index .news .leftside a {
          position: absolute;
          top: 0;
          right: 15px;
          font-size: 13px; } }
      .site-index .news .leftside a:hover {
        text-decoration: underline; }
      .site-index .news .leftside a i {
        display: inline-block;
        font-size: 13px;
        margin: 0 0 0 12px; }
  .site-index .news ol {
    width: calc(100% - 280px);
    height: 130px;
    padding: 0 40px 0 0;
    overflow: auto; }
    @media screen and (max-width: 1023px) {
      .site-index .news ol {
        padding: 0; } }
    @media screen and (max-width: 768px) {
      .site-index .news ol {
        width: 100%;
        height: 164px;
        padding: 15px;
        border: rgba(255, 255, 255, 0.2) 1px solid;
        border-radius: 4px; } }
    .site-index .news ol li {
      border-bottom: rgba(255, 255, 255, 0.12) 1px solid;
      padding: 0 0 24px;
      margin: 0 0 24px; }
      @media screen and (max-width: 768px) {
        .site-index .news ol li {
          margin: 0 0 15px;
          padding: 0 0 15px; } }
      .site-index .news ol li:last-child {
        margin: 0; }
      .site-index .news ol li a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        color: #FFF; }
        @media screen and (max-width: 768px) {
          .site-index .news ol li a {
            display: block; } }
        .site-index .news ol li a:hover h4 {
          text-decoration: underline; }
      .site-index .news ol li time, .site-index .news ol li .category {
        display: block;
        font-weight: 600; }
        @media screen and (max-width: 768px) {
          .site-index .news ol li time, .site-index .news ol li .category {
            float: left; } }
      .site-index .news ol li time {
        width: 14%;
        font-size: 12px;
        background: #262626;
        color: #FFF;
        padding: 2px 0;
        text-align: center;
        font-weight: 500; }
        @media screen and (max-width: 768px) {
          .site-index .news ol li time {
            width: 80px;
            font-size: 10px;
            padding: 1px 12px;
            margin: 2px 0 0; } }
      .site-index .news ol li h4 {
        width: 86%;
        font-size: 16px;
        padding: 0 0 0 32px; }
        @media screen and (max-width: 768px) {
          .site-index .news ol li h4 {
            width: auto;
            font-size: 12px;
            padding: 8px 0 0;
            clear: both; } }

.site-index .map {
  background: #f2f2f2;
  height: 300px; }
  @media screen and (max-width: 768px) {
    .site-index .map {
      height: 200px; } }
  .site-index .map iframe {
    vertical-align: bottom;
    filter: grayscale(1); }

.site-index .bnrarea {
  background: #FFF url("../../../images/common/black_dotto.png") repeat;
  background-size: 16px; }
  .site-index .bnrarea__items {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .site-index .bnrarea__items {
        width: auto;
        display: block; } }
    .site-index .bnrarea__items li {
      width: calc(50% - 16px); }
      @media screen and (max-width: 768px) {
        .site-index .bnrarea__items li {
          width: auto;
          margin: 0 0 16px; }
          .site-index .bnrarea__items li:last-child {
            margin: 0; } }
      .site-index .bnrarea__items li a {
        color: #121212;
        font-size: 18px;
        font-weight: 700;
        display: block;
        padding: 15px 0;
        text-align: center;
        position: relative;
        z-index: 2;
        -webkit-transition: all 120ms 0ms ease-in-out;
        -moz-transition: all 120ms 0ms ease-in-out;
        -ms-transition: all 120ms 0ms ease-in-out;
        -o-transition: all 120ms 0ms ease-in-out;
        transition: all 120ms 0ms ease-in-out; }
        @media screen and (max-width: 768px) {
          .site-index .bnrarea__items li a {
            border: #121212 3px solid;
            padding: 12px 15px;
            font-size: 16px; } }
        .site-index .bnrarea__items li a:hover {
          color: #FFF;
          transform: translateX(4px) translateY(4px); }
          @media screen and (max-width: 768px) {
            .site-index .bnrarea__items li a:hover {
              color: #121212;
              transform: translateX(0) translateY(0); } }
          .site-index .bnrarea__items li a:hover:after {
            background: #121212; }
          .site-index .bnrarea__items li a:hover:before {
            top: 0;
            left: 0; }
        .site-index .bnrarea__items li a i {
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
          color: #f43300; }
        .site-index .bnrarea__items li a:before, .site-index .bnrarea__items li a:after {
          content: '';
          width: 100%;
          height: 100%;
          border: #121212 3px solid;
          position: absolute;
          z-index: -1; }
          @media screen and (max-width: 768px) {
            .site-index .bnrarea__items li a:before, .site-index .bnrarea__items li a:after {
              display: none; } }
        .site-index .bnrarea__items li a:before {
          top: 10px;
          left: 10px;
          -webkit-transition: all 120ms 0ms ease-in-out;
          -moz-transition: all 120ms 0ms ease-in-out;
          -ms-transition: all 120ms 0ms ease-in-out;
          -o-transition: all 120ms 0ms ease-in-out;
          transition: all 120ms 0ms ease-in-out; }
        .site-index .bnrarea__items li a:after {
          background: #FFF;
          top: 0;
          left: 0;
          -webkit-transition: all 120ms 0ms ease-in-out;
          -moz-transition: all 120ms 0ms ease-in-out;
          -ms-transition: all 120ms 0ms ease-in-out;
          -o-transition: all 120ms 0ms ease-in-out;
          transition: all 120ms 0ms ease-in-out; }

.site-index .kussion {
  background: url("../../../images/index/kussion.jpg") no-repeat fixed;
  background-size: cover;
  position: relative; }
  .site-index .kussion.wrap {
    padding: 64px 0; }
    @media screen and (max-width: 768px) {
      .site-index .kussion.wrap {
        padding: 32px 15px; } }
  .site-index .kussion:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .48;
    position: absolute;
    top: 0;
    left: 0; }
  .site-index .kussion__data {
    color: #FFF;
    text-align: center; }
    .site-index .kussion__data__title {
      font-size: 24px;
      font-weight: 700;
      margin: 0 0 16px; }
      @media screen and (max-width: 768px) {
        .site-index .kussion__data__title {
          font-size: 20px; } }
      .site-index .kussion__data__title:before {
        content: attr(data-eng);
        font-size: 10px;
        color: #f43300;
        display: block; }
    .site-index .kussion__data__meta {
      font-size: 17px; }
      @media screen and (max-width: 768px) {
        .site-index .kussion__data__meta {
          font-size: 14px; }
          .site-index .kussion__data__meta br {
            display: none; } }

.site-index .firstview {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  border-top: none;
  background: #000;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden; }
  .site-index .firstview:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .24;
    z-index: 2; }
  .site-index .firstview:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    box-shadow: inset 0 0 88px rgba(0, 0, 0, 0.88); }
  .site-index .firstview.active .catch {
    opacity: .96;
    margin-top: 0; }
  .site-index .firstview.active .topics {
    transform: translateX(0);
    opacity: 1; }
  .site-index .firstview #video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: bottom center;
    width: 100vw;
    height: 100vh;
    z-index: 1; }
  .site-index .firstview #particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none; }
  .site-index .firstview .catch {
    position: relative;
    z-index: 3;
    font-weight: 700;
    color: #FFF;
    line-height: 1;
    margin: 0 0 0 10%;
    margin-top: 40px;
    opacity: 0;
    -webkit-transition: all 600ms 400ms ease-in-out;
    -moz-transition: all 600ms 400ms ease-in-out;
    -ms-transition: all 600ms 400ms ease-in-out;
    -o-transition: all 600ms 400ms ease-in-out;
    transition: all 600ms 400ms ease-in-out; }
    .site-index .firstview .catch dt {
      font-size: calc(12px + 5 * (100vw - 450px)/ 900);
      font-weight: 500;
      margin: 18px 0 0;
      text-indent: 2px; }
      @media screen and (max-width: 768px) {
        .site-index .firstview .catch dt {
          margin: 16px 0 0;
          font-size: 16px;
          width: 72%;
          line-height: 1.66; } }
    .site-index .firstview .catch dd {
      font-size: calc(70px + 32 * (100vw - 450px)/ 900);
      font-family: 'Oswald', sans-serif;
      line-height: 1.12;
      text-transform: uppercase;
      letter-spacing: .0em; }
      @media screen and (max-width: 768px) {
        .site-index .firstview .catch dd {
          font-size: 70px;
          white-space: nowrap; }
          .site-index .firstview .catch dd br {
            display: none; } }
    .site-index .firstview .catch strong {
      color: #f43300; }
      @media screen and (max-width: 768px) {
        .site-index .firstview .catch strong {
          display: block; } }
  .site-index .firstview .topics {
    color: rgba(255, 255, 255, 0.88);
    min-width: 300px;
    max-width: 500px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 1px;
    z-index: 3;
    padding: 20px 15px;
    line-height: 1;
    transform: translateX(-20%);
    opacity: 0;
    transition: transform 600ms 400ms, opacity 600ms 400ms; }
    @media screen and (max-width: 768px) {
      .site-index .firstview .topics {
        min-width: 1px;
        padding: 15px;
        transform: translateX(0);
        bottom: 0;
        background: #121212; } }
    .site-index .firstview .topics:hover i {
      color: #f43300; }
    .site-index .firstview .topics:before {
      content: '';
      z-index: -1;
      width: 120%;
      height: 100%;
      background: #121212;
      transform: skewX(24deg);
      position: absolute;
      top: 0;
      left: -10%; }
      @media screen and (max-width: 768px) {
        .site-index .firstview .topics:before {
          display: none; } }
    .site-index .firstview .topics i {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
      @media screen and (max-width: 768px) {
        .site-index .firstview .topics i {
          right: 15px; } }
    .site-index .firstview .topics time {
      width: 110px;
      font-size: 11px;
      padding: 4px 0;
      text-align: center;
      border-right: rgba(255, 255, 255, 0.12) 1px solid; }
      @media screen and (max-width: 768px) {
        .site-index .firstview .topics time {
          width: 100px; } }
    .site-index .firstview .topics__mes {
      width: calc(100% - 110px);
      padding: 0 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px; }
      @media screen and (max-width: 768px) {
        .site-index .firstview .topics__mes {
          width: calc(100% - 100px); } }
  .site-index .firstview .scroll {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3; }
    @media screen and (max-width: 768px) {
      .site-index .firstview .scroll {
        display: none; } }
    .site-index .firstview .scroll__line {
      position: absolute;
      right: 29px;
      bottom: 24px;
      z-index: 3;
      height: 96px; }
      .site-index .firstview .scroll__line__circle {
        content: '';
        width: 32px;
        height: 32px;
        border: #f43300 1px solid;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 0;
        animation: move 2s infinite; }
        .site-index .firstview .scroll__line__circle:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 30%;
          height: 30%;
          border-radius: 50%;
          background: #f43300; }

@keyframes move {
  0% {
    transform: scale(0);
    opacity: 1; }
  70% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0); }
  100% {
    transform: scale(1.2);
    opacity: 0;
    filter: blur(5px); } }
      .site-index .firstview .scroll__line:after {
        font-size: 11px;
        -webkit-writing-mode: vertical-rl;
        -moz-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        white-space: nowrap;
        letter-spacing: .12em;
        content: 'SCROLL';
        position: absolute;
        top: 0;
        right: 8px;
        color: #FFF; }
  .site-index .firstview .copy {
    -webkit-writing-mode: vertical-rl;
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
    letter-spacing: .08em;
    position: absolute;
    top: 0;
    right: 40px;
    height: 100%;
    color: rgba(255, 255, 255, 0.88);
    font-size: 9px;
    text-align: center;
    letter-spacing: .24em;
    z-index: 3;
    color: #FFF;
    text-transform: uppercase; }
    @media screen and (max-width: 768px) {
      .site-index .firstview .copy {
        right: 18px; } }
  .site-index .firstview .bg {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100vh;
    background: #FFF;
    z-index: 100; }
    @media screen and (max-width: 768px) {
      .site-index .firstview .bg {
        display: none; } }

.site-company .box {
  margin: 0 0 80px; }
  @media screen and (max-width: 1023px) {
    .site-company .box {
      margin: 0 0 56px; } }
  @media screen and (max-width: 768px) {
    .site-company .box {
      margin: 0 0 32px; } }
  .site-company .box__flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    .site-company .box__flex figure {
      width: 640px; }
      @media screen and (max-width: 1023px) {
        .site-company .box__flex figure {
          width: 100%;
          margin: 0 0 32px; } }
      @media screen and (max-width: 768px) {
        .site-company .box__flex figure {
          margin: 0 0 20px; } }
      .site-company .box__flex figure img {
        width: 100%; }
    .site-company .box__flex .greeting {
      width: calc(100% - 640px);
      padding: 0 0 0 48px; }
      @media screen and (max-width: 1023px) {
        .site-company .box__flex .greeting {
          width: auto;
          padding: 0;
          margin: 32px 0 0; } }
      @media screen and (max-width: 768px) {
        .site-company .box__flex .greeting {
          margin: 0; } }
      .site-company .box__flex .greeting dd {
        font-size: 15px;
        text-align: justify;
        line-height: 2; }
        @media screen and (max-width: 768px) {
          .site-company .box__flex .greeting dd {
            font-size: 13px; } }
      .site-company .box__flex .greeting dt {
        text-align: right;
        margin: 40px 0 0;
        font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.04em;
        font-weight: 700;
        font-size: 14px; }
        .site-company .box__flex .greeting dt strong {
          font-size: 32px;
          line-height: 1;
          display: inline-block;
          margin: 0 0 0 8px; }
          @media screen and (max-width: 768px) {
            .site-company .box__flex .greeting dt strong {
              font-size: 24px; } }

.site-company .solution {
  position: relative; }
  .site-company .solution__list {
    padding: 12px 0 48px; }
    .site-company .solution__list li {
      background: #FFF; }
      .site-company .solution__list li dl {
        text-align: center;
        line-height: 1; }
        .site-company .solution__list li dl dt {
          font-family: 'Open Sans', sans-serif;
          font-weight: 800;
          font-size: 72px;
          letter-spacing: 0em;
          margin: 0 0 20px;
          text-transform: uppercase; }
          @media screen and (max-width: 768px) {
            .site-company .solution__list li dl dt {
              font-size: 28px;
              margin: 0 0 8px; } }
        .site-company .solution__list li dl dd {
          font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
          font-weight: 500;
          -webkit-font-feature-settings: "palt";
          font-feature-settings: "palt";
          letter-spacing: 0.04em;
          font-size: 20px;
          font-weight: 700; }
          @media screen and (max-width: 768px) {
            .site-company .solution__list li dl dd {
              font-size: 15px;
              line-height: 1.56;
              width: 88%;
              margin: 0 auto; } }
  .site-company .solution__ctr {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    justify-content: flex-start; }
    .site-company .solution__ctr__btn {
      width: 48px;
      height: 48px;
      background: #121212;
      color: #FFF;
      font-size: 12px;
      margin: 0 2px 0 0;
      cursor: pointer;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-content: center; }
      @media screen and (max-width: 768px) {
        .site-company .solution__ctr__btn {
          width: 40px;
          height: 40px; } }
      .site-company .solution__ctr__btn:hover {
        background: #f43300; }

.site-company .map {
  margin: 48px 0 0;
  border: #e8e8e8 1px solid;
  background: #FFF;
  padding: 8px; }
  @media screen and (max-width: 768px) {
    .site-company .map {
      margin: 32px 0 0; } }
  .site-company .map iframe {
    width: 100%;
    height: 300px;
    filter: grayscale(1);
    vertical-align: bottom; }
    @media screen and (max-width: 768px) {
      .site-company .map iframe {
        height: 200px; } }

.site-company .client dl {
  border: #e8e8e8 1px solid;
  border-bottom: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  position: relative; }
  @media screen and (max-width: 768px) {
    .site-company .client dl {
      display: block; } }
  .site-company .client dl:first-of-type {
    margin: 10px 0 0; }
  .site-company .client dl:last-of-type {
    border-bottom: #e8e8e8 1px solid; }
  .site-company .client dl:before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 160px;
    background: #e8e8e8; }
    @media screen and (max-width: 768px) {
      .site-company .client dl:before {
        display: none; } }
  .site-company .client dl dt {
    width: 160px;
    padding: 10px 0;
    padding-left: 20px;
    background: #f2f2f2; }
    @media screen and (max-width: 768px) {
      .site-company .client dl dt {
        width: auto;
        padding: 10px 15px;
        background: none;
        border-bottom: #e8e8e8 1px solid;
        background: #f2f2f2;
        font-weight: 700; } }
  .site-company .client dl dd {
    width: calc(100% - 160px);
    padding: 10px 20px;
    font-size: 14px; }
    @media screen and (max-width: 768px) {
      .site-company .client dl dd {
        width: auto;
        padding: 10px 15px;
        font-size: 13px; } }

.site-catalog .localnav {
  background: #f2f2f2;
  padding: 20px 40px;
  border-radius: 4px; }
  @media screen and (max-width: 768px) {
    .site-catalog .localnav {
      padding: 0; } }
  .site-catalog .localnav ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    justify-content: flex-start;
    align-items: flex-start; }
    @media screen and (max-width: 768px) {
      .site-catalog .localnav ul {
        display: block; } }
    .site-catalog .localnav ul li {
      list-style: none; }
      @media screen and (max-width: 768px) {
        .site-catalog .localnav ul li {
          border-bottom: #e8e8e8 1px solid; }
          .site-catalog .localnav ul li:last-child {
            border: none; } }
      .site-catalog .localnav ul li:after {
        content: '|';
        opacity: .16;
        display: inline-block;
        margin: 0 20px; }
        @media screen and (max-width: 768px) {
          .site-catalog .localnav ul li:after {
            display: none; } }
      .site-catalog .localnav ul li a {
        color: #121212; }
        @media screen and (max-width: 768px) {
          .site-catalog .localnav ul li a {
            display: block;
            padding: 10px 15px; } }
        .site-catalog .localnav ul li a:before {
          content: "\f0a9";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          display: inline-block;
          margin: 0 6px 0 0;
          color: #f43300; }
        .site-catalog .localnav ul li a:hover {
          text-decoration: underline; }

.site-catalog .catalist {
  padding: 72px 0 0; }
  @media screen and (max-width: 1023px) {
    .site-catalog .catalist {
      padding: 56px 0 0; } }
  @media screen and (max-width: 768px) {
    .site-catalog .catalist {
      padding: 32px 0 0; } }
  .site-catalog .catalist__item {
    margin: 0 0 0 -32px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    justify-content: flex-start; }
    @media screen and (max-width: 1023px) {
      .site-catalog .catalist__item {
        margin: 0 0 0 -20px; } }
    @media screen and (max-width: 768px) {
      .site-catalog .catalist__item {
        margin: 0;
        display: block; } }
    .site-catalog .catalist__item > li {
      width: 25%;
      padding: 0 0 0 32px;
      margin: 32px 0 0; }
      @media screen and (min-width: 1024px) {
        .site-catalog .catalist__item > li:nth-child(-n+4) {
          margin-top: 0; } }
      @media screen and (max-width: 1023px) {
        .site-catalog .catalist__item > li {
          width: 33.3%;
          padding: 0 0 0 20px; }
          .site-catalog .catalist__item > li:nth-child(-n+3) {
            margin-top: 0; } }
      @media screen and (max-width: 768px) {
        .site-catalog .catalist__item > li {
          width: auto;
          padding: 0;
          margin: 0 0 16px; }
          .site-catalog .catalist__item > li:last-child {
            margin: 0; } }
      .site-catalog .catalist__item > li a {
        border: #e8e8e8 1px solid;
        display: block;
        padding: 20px;
        color: #121212;
        background: #FFF;
        position: relative; }
        @media screen and (max-width: 768px) {
          .site-catalog .catalist__item > li a {
            padding: 15px; } }
        .site-catalog .catalist__item > li a:after {
          content: '';
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          border: #f43300 2px solid;
          opacity: 0;
          transform: scale(0.88);
          position: absolute;
          top: -1px;
          left: -1px;
          z-index: 2;
          pointer-events: none;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
          @media screen and (max-width: 768px) {
            .site-catalog .catalist__item > li a:after {
              display: none; } }
        .site-catalog .catalist__item > li a:hover:after {
          transform: scale(1);
          opacity: 1; }
        .site-catalog .catalist__item > li a figure {
          text-align: center; }
          @media screen and (max-width: 768px) {
            .site-catalog .catalist__item > li a figure {
              display: -webkit-flex;
              display: -moz-flex;
              display: -o-flex;
              display: -ms-flex;
              display: flex;
              -moz-flex-flow: row wrap;
              -webkit-flex-flow: row wrap;
              -o-flex-flow: row wrap;
              -ms-flex-flow: row wrap;
              flex-flow: row wrap;
              -webkit-justify-content: space-between;
              -o-justify-content: space-between;
              -ms-justify-content: space-between;
              -moz-justify-content: space-between;
              justify-content: space-between;
              justify-content: flex-start;
              align-items: center; } }
          .site-catalog .catalist__item > li a figure img {
            width: 100px;
            -webkit-transition: all 200ms 0ms ease-in-out;
            -moz-transition: all 200ms 0ms ease-in-out;
            -ms-transition: all 200ms 0ms ease-in-out;
            -o-transition: all 200ms 0ms ease-in-out;
            transition: all 200ms 0ms ease-in-out; }
            @media screen and (max-width: 768px) {
              .site-catalog .catalist__item > li a figure img {
                width: 80px;
                margin: 0 15px 0 0; } }
          .site-catalog .catalist__item > li a figure figcaption {
            margin: 16px 0 0;
            font-size: 15px; }
            @media screen and (max-width: 768px) {
              .site-catalog .catalist__item > li a figure figcaption {
                margin: 0;
                font-size: 14px; } }
  .site-catalog .catalist__video {
    margin: 0 0 0 -32px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    justify-content: flex-start; }
    @media screen and (max-width: 1023px) {
      .site-catalog .catalist__video {
        margin: 0 0 0 -20px; } }
    @media screen and (max-width: 768px) {
      .site-catalog .catalist__video {
        margin: 0;
        display: block; } }
    .site-catalog .catalist__video > li {
      width: 33.3%;
      padding: 0 0 0 32px;
      margin: 32px 0 0; }
      @media screen and (max-width: 1023px) {
        .site-catalog .catalist__video > li {
          width: 50%;
          margin: 20px 0 0;
          padding: 0 0 0 20px; } }
      @media screen and (min-width: 1024px) {
        .site-catalog .catalist__video > li:nth-child(-n+3) {
          margin-top: 0; } }
      @media screen and (max-width: 1023px) {
        .site-catalog .catalist__video > li:nth-child(-n+2) {
          margin-top: 0; } }
      @media screen and (max-width: 768px) {
        .site-catalog .catalist__video > li {
          margin: 0 0 15px;
          width: auto;
          padding: 0; }
          .site-catalog .catalist__video > li:last-child {
            margin: 0; } }
      .site-catalog .catalist__video > li iframe {
        width: 100%;
        height: 250px;
        vertical-align: bottom; }
        @media screen and (max-width: 1023px) {
          .site-catalog .catalist__video > li iframe {
            height: 200px; } }
    .site-catalog .catalist__video__caption {
      text-align: center;
      padding: 15px;
      background: #f2f2f2; }

.site-catalog .productlist {
  padding: 72px 0 0; }
  @media screen and (max-width: 768px) {
    .site-catalog .productlist {
      padding: 32px 0 0; } }
  .site-catalog .productlist--no {
    padding: 0; }
  .site-catalog .productlist__item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    justify-content: flex-start; }
    @media screen and (max-width: 768px) {
      .site-catalog .productlist__item {
        display: block; } }
    .site-catalog .productlist__item li {
      width: 23.5%;
      margin: 2% 2% 0 0;
      border: #e8e8e8 1px solid;
      position: relative;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.04);
      background: #FFF; }
      @media screen and (max-width: 1023px) {
        .site-catalog .productlist__item li {
          width: 32%; } }
      @media screen and (max-width: 768px) {
        .site-catalog .productlist__item li {
          width: auto;
          margin: 0 0 8px; }
          .site-catalog .productlist__item li:after {
            clear: both;
            display: block;
            content: ''; }
          .site-catalog .productlist__item li:last-child {
            margin: 0; } }
      .site-catalog .productlist__item li:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 12px 12px;
        border-color: transparent transparent #f43300 transparent;
        position: absolute;
        right: -1px;
        bottom: -1px; }
        @media screen and (max-width: 768px) {
          .site-catalog .productlist__item li:after {
            display: none; } }
      @media screen and (min-width: 1024px) {
        .site-catalog .productlist__item li:nth-child(-n+4) {
          margin-top: 0; }
        .site-catalog .productlist__item li:nth-child(4n+4) {
          margin-right: 0; } }
      @media screen and (max-width: 1023px) {
        .site-catalog .productlist__item li:nth-child(-n+3) {
          margin-top: 0; }
        .site-catalog .productlist__item li:nth-child(3n+3) {
          margin-right: 0; } }
      .site-catalog .productlist__item li a {
        display: block;
        color: #121212; }
        @media screen and (max-width: 768px) {
          .site-catalog .productlist__item li a {
            padding: 15px; } }
      .site-catalog .productlist__item li figure {
        width: 56%;
        min-width: 160px;
        margin: 25px auto; }
        @media screen and (max-width: 768px) {
          .site-catalog .productlist__item li figure {
            margin: 0;
            min-width: 1px;
            width: 100px;
            margin: 0 15px 0 0;
            float: left; } }
        .site-catalog .productlist__item li figure img {
          width: 100%; }
      .site-catalog .productlist__item li dl {
        padding: 25px;
        padding-top: 0; }
        @media screen and (max-width: 768px) {
          .site-catalog .productlist__item li dl {
            padding: 0; } }
        .site-catalog .productlist__item li dl dt {
          font-weight: 700;
          font-size: 16px;
          text-align: center; }
          @media screen and (max-width: 768px) {
            .site-catalog .productlist__item li dl dt {
              text-align: left; } }
          .site-catalog .productlist__item li dl dt:before {
            content: attr(data-eng);
            display: block;
            color: #f43300;
            font-size: 10px; }
        .site-catalog .productlist__item li dl dd {
          text-align: justify;
          margin: 12px 0 0;
          padding: 12px 0 0;
          font-size: 13px;
          border-top: #e8e8e8 1px solid; }
          @media screen and (max-width: 768px) {
            .site-catalog .productlist__item li dl dd {
              clear: both;
              margin: 0;
              border: none; } }

.site-catalog .downloadlist {
  padding: 72px 0 0; }
  @media screen and (max-width: 768px) {
    .site-catalog .downloadlist {
      padding: 32px 0 0; } }
  .site-catalog .downloadlist--no {
    padding: 0; }
  .site-catalog .downloadlist__item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    justify-content: flex-start; }
    @media screen and (max-width: 768px) {
      .site-catalog .downloadlist__item {
        display: block; } }
    .site-catalog .downloadlist__item li {
      width: 23.5%;
      margin: 2% 2% 0 0;
      border: #e8e8e8 1px solid;
      position: relative;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.04);
      background: #FFF; }
      @media screen and (max-width: 768px) {
        .site-catalog .downloadlist__item li {
          width: auto;
          margin: 0 0 8px; }
          .site-catalog .downloadlist__item li:last-child {
            margin: 0; } }
      .site-catalog .downloadlist__item li:nth-child(-n+4) {
        margin-top: 0; }
      .site-catalog .downloadlist__item li:nth-child(4n+4) {
        margin-right: 0; }
      .site-catalog .downloadlist__item li a {
        display: block;
        color: #121212;
        padding: 15px; }
        @media screen and (max-width: 768px) {
          .site-catalog .downloadlist__item li a {
            padding: 10px 15px; } }
        .site-catalog .downloadlist__item li a i {
          color: #bfbfbf;
          display: inline-block;
          margin: 0 10px 0 0; }

.site-product .firstbox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-product .firstbox {
      padding: 0;
      border: none;
      display: block; } }
  .site-product .firstbox__img {
    width: 400px;
    order: 2; }
    @media screen and (max-width: 768px) {
      .site-product .firstbox__img {
        order: 1;
        width: 240px;
        margin: 0 auto; } }
    .site-product .firstbox__img img {
      width: 100%; }
  .site-product .firstbox__data {
    width: calc(100% - 400px);
    padding: 0 48px 0 0; }
    @media screen and (max-width: 768px) {
      .site-product .firstbox__data {
        width: auto;
        padding: 20px 0 0; } }
    .site-product .firstbox__data__dl dt {
      font-size: 24px;
      font-weight: 700;
      margin: 0 0 12px;
      padding: 0 0 12px;
      border-bottom: #e8e8e8 1px solid; }
      @media screen and (max-width: 768px) {
        .site-product .firstbox__data__dl dt {
          font-size: 18px;
          text-align: center; } }
      .site-product .firstbox__data__dl dt:after {
        content: attr(data-eng);
        display: inline-block;
        margin: 0 0 0 16px;
        font-size: 10px;
        color: #f43300; }
        @media screen and (max-width: 768px) {
          .site-product .firstbox__data__dl dt:after {
            display: block;
            margin: 4px 0 0; } }
    .site-product .firstbox__data__dl dd {
      text-align: justify; }
    .site-product .firstbox__data__dl--mini {
      margin: 32px 0 0;
      background: #f2f2f2;
      padding: 15px 24px;
      border-radius: 4px; }
      @media screen and (max-width: 768px) {
        .site-product .firstbox__data__dl--mini {
          margin: 24px 0 0;
          padding: 15px; } }
      .site-product .firstbox__data__dl--mini dt {
        font-size: 18px; }
        @media screen and (max-width: 768px) {
          .site-product .firstbox__data__dl--mini dt {
            text-align: left;
            margin: 0 0 10px;
            padding: 0 0 6px; } }
        .site-product .firstbox__data__dl--mini dt:before {
          content: '■';
          color: #f43300;
          font-size: 12px;
          display: inline-block;
          margin: 0 6px 0 0;
          transform: translateY(-2px); }

.site-product .listitem {
  margin: 32px 0 0;
  padding: 25px;
  background: #f2f2f2; }
  @media screen and (max-width: 768px) {
    .site-product .listitem {
      padding: 15px;
      margin: 24px 0 0; } }
  .site-product .listitem--mt {
    margin-top: 0; }
  .site-product .listitem__items {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    justify-content: flex-start; }
    @media screen and (max-width: 768px) {
      .site-product .listitem__items {
        display: block; } }
    .site-product .listitem__items li {
      width: 32%;
      margin: 2% 2% 0 0; }
      .site-product .listitem__items li:nth-child(-n+3) {
        margin-top: 0; }
      .site-product .listitem__items li:nth-child(3n+3) {
        margin-right: 0; }
      @media screen and (max-width: 768px) {
        .site-product .listitem__items li {
          width: auto;
          margin: 0 0 15px; }
          .site-product .listitem__items li:last-child {
            margin: 0; } }
      .site-product .listitem__items li img {
        width: 100%; }
      .site-product .listitem__items li figcaption {
        text-align: center;
        margin: 8px 15px 0;
        font-size: 15px; }
  .site-product .listitem__txt {
    margin: 24px;
    text-align: center;
    font-size: 15px; }
  .site-product .listitem .btnarea {
    margin: 0;
    padding: 0 0 8px; }
    @media screen and (max-width: 768px) {
      .site-product .listitem .btnarea {
        padding: 0; } }

.site-product .itembox {
  margin: 72px 0 0; }
  @media screen and (max-width: 768px) {
    .site-product .itembox {
      margin: 32px 0 0; } }
  .site-product .itembox .mb {
    margin-bottom: 32px; }
    @media screen and (max-width: 768px) {
      .site-product .itembox .mb {
        margin-bottom: 24px; } }
    .site-product .itembox .mb:last-of-type {
      margin-bottom: 0; }
  .site-product .itembox > h4 {
    margin-top: 56px; }
    @media screen and (max-width: 768px) {
      .site-product .itembox > h4 {
        margin-top: 32px; } }
    .site-product .itembox > h4:first-of-type {
      margin-top: 0; }

.site-product .usebox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .site-product .usebox {
      display: block; } }
  .site-product .usebox__video {
    width: 380px;
    order: 2; }
    .site-product .usebox__video iframe {
      width: 100%;
      vertical-align: bottom; }
    @media screen and (max-width: 768px) {
      .site-product .usebox__video {
        width: auto; } }
  .site-product .usebox__list {
    width: calc(100% - 380px);
    padding: 15px 32px 0 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-product .usebox__list {
        margin: 15px 0 0;
        padding: 0;
        width: auto;
        display: block; } }
    .site-product .usebox__list li {
      width: calc(50% - 12px);
      font-size: 15px;
      margin: 0 0 15px;
      padding: 0 0 15px;
      border-bottom: #e8e8e8 1px solid; }
      @media screen and (max-width: 768px) {
        .site-product .usebox__list li {
          width: auto; }
          .site-product .usebox__list li:last-child {
            margin: 0;
            padding: 0;
            border-bottom: none;
            font-size: 13px; } }
      .site-product .usebox__list li:before {
        content: "\f058";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        display: inline-block;
        margin: 0 6px 0 0;
        color: #f43300; }

.site-product .featurebox {
  border: #f2f2f2 8px solid;
  background: #f2f2f2; }
  .site-product .featurebox__list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    justify-content: flex-start;
    background: #f2f2f2; }
    @media screen and (max-width: 768px) {
      .site-product .featurebox__list {
        display: block; } }
    .site-product .featurebox__list__item {
      width: calc(33.3%);
      border-right: #f2f2f2 8px solid;
      border-top: #f2f2f2 8px solid;
      background: #FFF; }
      .site-product .featurebox__list__item:nth-child(3n+3) {
        border-right: none; }
      .site-product .featurebox__list__item:nth-child(-n+3) {
        border-top: none; }
      @media screen and (max-width: 768px) {
        .site-product .featurebox__list__item {
          width: auto;
          border: none;
          margin: 0 0 8px; }
          .site-product .featurebox__list__item:last-child {
            margin: 0; } }
      .site-product .featurebox__list__item__bg {
        display: block;
        height: 300px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain; }
        @media screen and (max-width: 768px) {
          .site-product .featurebox__list__item__bg {
            height: 200px; } }
      .site-product .featurebox__list__item__txt {
        text-align: center;
        font-size: 17px;
        padding: 15px;
        border-top: #f2f2f2 1px solid; }
        @media screen and (max-width: 768px) {
          .site-product .featurebox__list__item__txt {
            font-size: 14px;
            padding: 10px 15px; } }
  .site-product .featurebox__txt {
    margin: 24px;
    text-align: center;
    font-size: 15px; }
    @media screen and (max-width: 768px) {
      .site-product .featurebox__txt {
        margin: 20px 8px 0; } }
  .site-product .featurebox .btnarea {
    margin: 0;
    padding: 0 0 24px; }
    @media screen and (max-width: 768px) {
      .site-product .featurebox .btnarea {
        padding: 0;
        margin: 20px 8px 8px; } }

.site-product .figurebox {
  text-align: center;
  border: #e8e8e8 1px solid;
  background: #FFF;
  padding: 40px; }
  @media screen and (max-width: 768px) {
    .site-product .figurebox {
      padding: 15px; } }
  .site-product .figurebox img {
    max-width: 80%;
    width: auto;
    height: auto; }
    @media screen and (max-width: 768px) {
      .site-product .figurebox img {
        max-width: 88%; } }

.site-product h4 {
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  margin: 0 0 8px;
  padding: 0 0 0 16px;
  position: relative; }
  @media screen and (max-width: 768px) {
    .site-product h4 {
      font-size: 16px; } }
  .site-product h4:before {
    content: '';
    width: 9px;
    height: 9px;
    background: #f43300;
    border-radius: 50%;
    position: absolute;
    top: calc(50% + 1px);
    left: 0;
    transform: translateY(-50%); }

.site-product .tablebox .txtbox {
  margin: 0 0 16px; }
  @media screen and (max-width: 768px) {
    .site-product .tablebox .txtbox {
      margin: 0 0 12px; } }

@media screen and (max-width: 768px) {
  .site-product .tablebox__responsive {
    width: 100%;
    overflow: auto;
    position: relative; } }

.site-product .tablebox__responsive .hand {
  display: none; }
  @media screen and (max-width: 768px) {
    .site-product .tablebox__responsive .hand {
      display: block;
      margin: 0 0 12px;
      position: relative;
      padding: 0 0 0 36px; }
      .site-product .tablebox__responsive .hand i {
        position: absolute;
        top: 5px;
        left: 0;
        color: #f43300;
        animation: swipe 1s infinite alternate; }
    @keyframes swipe {
      0% {
        left: 0; }
      100% {
        left: 12px; } } }

.site-product .tablebox table {
  border-collapse: collapse;
  border: #e8e8e8 1px solid;
  width: 100%; }
  .site-product .tablebox table thead tr th, .site-product .tablebox table thead tr td,
  .site-product .tablebox table tbody tr th,
  .site-product .tablebox table tbody tr td {
    padding: 5px 8px;
    border: #e8e8e8 1px solid;
    font-size: 14px;
    vertical-align: middle; }
    @media screen and (max-width: 768px) {
      .site-product .tablebox table thead tr th, .site-product .tablebox table thead tr td,
      .site-product .tablebox table tbody tr th,
      .site-product .tablebox table tbody tr td {
        padding: 8 15px;
        font-size: 13px;
        min-width: 140px; } }
    .site-product .tablebox table thead tr th img, .site-product .tablebox table thead tr td img,
    .site-product .tablebox table tbody tr th img,
    .site-product .tablebox table tbody tr td img {
      max-width: 100%; }
  .site-product .tablebox table thead {
    background: #f2f2f2;
    font-weight: 700; }

.site-product p.sup {
  margin: 16px 0 0;
  font-size: 12px; }

.site-product .otherbox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: flex-start; }
  @media screen and (max-width: 768px) {
    .site-product .otherbox {
      display: block; } }

.site-product .otherlist {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  justify-content: flex-start;
  width: calc(100% - 200px);
  padding: 0 24px 0 0; }
  @media screen and (max-width: 768px) {
    .site-product .otherlist {
      width: auto;
      display: block;
      padding: 0;
      margin: 0 0 20px; } }
  @media screen and (min-width: 1024px) {
    .site-product .otherlist li {
      width: calc(32%);
      margin: 2% 2% 0 0;
      background: #FFF;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.04); }
      .site-product .otherlist li:nth-child(3n+3) {
        margin-right: 0; }
      .site-product .otherlist li:nth-child(-n+3) {
        margin-top: 0; } }
  @media screen and (max-width: 1023px) {
    .site-product .otherlist li {
      background: #FFF;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.04);
      margin: 0 0 20px; }
      .site-product .otherlist li:last-child {
        margin: 0; } }
  .site-product .otherlist li a {
    padding: 20px;
    color: #121212;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    .site-product .otherlist li a figure {
      width: 72px; }
      .site-product .otherlist li a figure img {
        width: 100%; }
    .site-product .otherlist li a dl {
      width: calc(100% - 72px);
      padding: 0 0 0 20px; }
      .site-product .otherlist li a dl dt {
        font-size: 13.5px;
        font-weight: 700; }
      .site-product .otherlist li a dl dd {
        font-size: 10px;
        color: #f43300;
        text-align: justify; }
    .site-product .otherlist li a .tt {
      font-size: 12px;
      text-align: justify;
      margin: 8px 0 0;
      padding: 8px 0 0;
      border-top: #e8e8e8 1px solid;
      width: 100%; }

.site-product .productmore {
  width: 200px; }
  @media screen and (max-width: 768px) {
    .site-product .productmore {
      width: auto; } }
  .site-product .productmore a {
    background: #FFF;
    border: #f43300 2px solid;
    color: #f43300;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    display: block;
    padding: 8px 0;
    margin: 0 0 10px; }
    .site-product .productmore a:last-of-type {
      margin: 0; }
    .site-product .productmore a:hover {
      background: #f43300;
      color: #FFF; }
    .site-product .productmore a i {
      display: inline-block;
      margin: 0 8px 0 0; }

@media screen and (min-width: 1024px) {
  .site-product .itemflex .figurebox {
    border: #e8e8e8 1px solid;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    padding: 32px; }
    .site-product .itemflex .figurebox img {
      max-width: 100%;
      width: auto; }
  .site-product .itemflex .tablebox {
    overflow: auto; } }

.site-category .site-module.wrap.mids {
  padding-top: 56px; }
  @media screen and (max-width: 768px) {
    .site-category .site-module.wrap.mids {
      padding-top: 32px; } }

.site-category .main {
  margin: 0 0 56px;
  padding: 0 16% 32px;
  border-bottom: #e8e8e8 1px solid; }
  @media screen and (max-width: 768px) {
    .site-category .main {
      margin: 0 0 20px;
      padding: 0 15px;
      border: none; } }
  .site-category .main img {
    width: 100%; }

.site-category .firstbox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .site-category .firstbox.mb {
    margin-bottom: 40px; }
    @media screen and (max-width: 768px) {
      .site-category .firstbox.mb {
        margin-bottom: 24px; } }
  @media screen and (max-width: 768px) {
    .site-category .firstbox {
      display: block; } }
  .site-category .firstbox__fig {
    width: 300px; }
    @media screen and (max-width: 768px) {
      .site-category .firstbox__fig {
        width: 260px;
        margin: 0 auto; } }
    .site-category .firstbox__fig img {
      width: 100%; }
  .site-category .firstbox__data {
    width: calc(100% - 300px);
    padding: 0 0 0 48px; }
    @media screen and (max-width: 768px) {
      .site-category .firstbox__data {
        width: auto;
        padding: 20px 0 0;
        margin: 20px 0 0;
        border-top: #e8e8e8 1px solid; } }
    .site-category .firstbox__data__dl {
      position: relative; }
      .site-category .firstbox__data__dl dt {
        font-size: 28px;
        font-weight: 700; }
        @media screen and (max-width: 1023px) {
          .site-category .firstbox__data__dl dt {
            font-size: 22px; } }
        @media screen and (max-width: 768px) {
          .site-category .firstbox__data__dl dt {
            text-align: center;
            font-size: 19px; } }
        .site-category .firstbox__data__dl dt:after {
          content: attr(data-eng);
          color: #f43300;
          font-size: 10px;
          display: inline-block;
          margin: 0 0 0 20px; }
          @media screen and (max-width: 768px) {
            .site-category .firstbox__data__dl dt:after {
              display: block;
              margin: 4px 0 0; } }
      .site-category .firstbox__data__dl dd {
        margin: 8px 0 0;
        padding: 12px 0 0;
        border-top: #e8e8e8 1px solid; }
        @media screen and (max-width: 768px) {
          .site-category .firstbox__data__dl dd {
            padding: 20px 0 0;
            margin: 0;
            border: none; } }
        .site-category .firstbox__data__dl dd.custumorder {
          border: #121212 2px solid;
          padding: 6px 32px;
          text-align: center;
          font-size: 16px;
          font-weight: 700;
          margin: 0;
          position: absolute;
          top: 0;
          right: 0; }
          @media screen and (max-width: 1023px) {
            .site-category .firstbox__data__dl dd.custumorder {
              position: relative;
              margin: 10px 0; } }
        .site-category .firstbox__data__dl dd.str {
          font-weight: 700;
          border: none;
          font-size: 17px;
          margin: 0;
          padding: 0; }
        .site-category .firstbox__data__dl dd.iso {
          margin: 0;
          padding: 24px 0 0;
          border: none; }
          @media screen and (max-width: 768px) {
            .site-category .firstbox__data__dl dd.iso {
              padding: 12px 0 0; } }
          .site-category .firstbox__data__dl dd.iso figure {
            display: -webkit-flex;
            display: -moz-flex;
            display: -o-flex;
            display: -ms-flex;
            display: flex;
            -moz-flex-flow: row wrap;
            -webkit-flex-flow: row wrap;
            -o-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-justify-content: space-between;
            -o-justify-content: space-between;
            -ms-justify-content: space-between;
            -moz-justify-content: space-between;
            justify-content: space-between;
            align-items: center;
            justify-content: flex-start; }
            .site-category .firstbox__data__dl dd.iso figure img {
              width: 200px; }
              @media screen and (max-width: 1023px) {
                .site-category .firstbox__data__dl dd.iso figure img {
                  width: 140px; } }
              @media screen and (max-width: 768px) {
                .site-category .firstbox__data__dl dd.iso figure img {
                  width: 120px; } }
            .site-category .firstbox__data__dl dd.iso figure figcaption {
              margin: 0 0 0 32px; }
              @media screen and (max-width: 768px) {
                .site-category .firstbox__data__dl dd.iso figure figcaption {
                  width: calc(100% - 120px);
                  padding: 0 0 0 20px;
                  margin: 0; } }
      .site-category .firstbox__data__dl--mini {
        margin: 32px 0 0;
        background: #f2f2f2;
        padding: 15px 24px;
        border-radius: 4px; }
        @media screen and (max-width: 768px) {
          .site-category .firstbox__data__dl--mini {
            margin: 16px 0 0;
            padding: 15px; } }
        .site-category .firstbox__data__dl--mini dt {
          font-size: 18px; }
          @media screen and (max-width: 768px) {
            .site-category .firstbox__data__dl--mini dt {
              font-size: 16px;
              text-align: left; } }
          .site-category .firstbox__data__dl--mini dt:before {
            content: '■';
            color: #f43300;
            font-size: 12px;
            display: inline-block;
            margin: 0 6px 0 0;
            transform: translateY(-2px); }
        @media screen and (max-width: 768px) {
          .site-category .firstbox__data__dl--mini dd {
            padding: 4px 0 0; } }

.site-category .thumbarea {
  background: #f2f2f2;
  padding: 25px;
  margin: 56px 0 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  justify-content: flex-start; }
  @media screen and (max-width: 768px) {
    .site-category .thumbarea {
      margin: 32px 0 0;
      padding: 15px;
      display: block; } }
  .site-category .thumbarea li {
    width: 32%;
    margin: 2% 2% 0 0; }
    @media screen and (max-width: 768px) {
      .site-category .thumbarea li {
        width: auto;
        margin: 0 0 15px; }
        .site-category .thumbarea li:last-child {
          margin: 0; }
          .site-category .thumbarea li:last-child > figure {
            margin: 0; }
        .site-category .thumbarea li:first-child a {
          margin: 0; }
        .site-category .thumbarea li > figure {
          margin: 0 0 8px; }
          .site-category .thumbarea li > figure img {
            width: 100%;
            height: auto; } }
    .site-category .thumbarea li a {
      display: block;
      background: #FFF;
      color: #121212;
      padding: 15px;
      position: relative; }
      @media screen and (max-width: 768px) {
        .site-category .thumbarea li a {
          margin: 8px 0 0 0; }
          .site-category .thumbarea li a figure figcaption {
            width: calc(100% - 150px);
            padding: 0 0 0 15px;
            margin: 0;
            text-align: left; } }
      .site-category .thumbarea li a:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: #f43300 2px solid;
        transform: scale(0.88);
        opacity: 0;
        -webkit-transition: all 200ms 0ms ease-in-out;
        -moz-transition: all 200ms 0ms ease-in-out;
        -ms-transition: all 200ms 0ms ease-in-out;
        -o-transition: all 200ms 0ms ease-in-out;
        transition: all 200ms 0ms ease-in-out; }
        @media screen and (max-width: 768px) {
          .site-category .thumbarea li a:after {
            display: none; } }
      .site-category .thumbarea li a:hover:after {
        transform: scale(1);
        opacity: 1; }
      .site-category .thumbarea li a:hover img {
        opacity: 1; }
    @media screen and (min-width: 769px) {
      .site-category .thumbarea li:nth-child(3n+3) {
        margin-right: 0; }
      .site-category .thumbarea li:nth-child(-n+3) {
        margin-top: 0; } }
    @media screen and (max-width: 768px) {
      .site-category .thumbarea li figure {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: flex-start; } }
    .site-category .thumbarea li img {
      width: 100%; }
      @media screen and (max-width: 768px) {
        .site-category .thumbarea li img {
          width: 150px;
          height: auto; } }
    .site-category .thumbarea li figcaption {
      margin: 15px 0 0;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .site-category .thumbarea li figcaption {
          width: 100%; } }
      .site-category .thumbarea li figcaption strong {
        font-weight: 700;
        font-size: 15px; }
  @media screen and (min-width: 769px) {
    .site-category .thumbarea--four li {
      width: 23.5%; }
      .site-category .thumbarea--four li:nth-child(3n+3) {
        margin-right: 2%; }
      .site-category .thumbarea--four li:nth-child(-n+4) {
        margin-top: 0; }
      .site-category .thumbarea--four li:nth-child(4n+4) {
        margin-right: 0; } }

.site-category .featurebox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-category .featurebox {
      display: block; } }
  .site-category .featurebox__video {
    width: 480px; }
    @media screen and (max-width: 1023px) {
      .site-category .featurebox__video {
        width: 320px; } }
    @media screen and (max-width: 768px) {
      .site-category .featurebox__video {
        width: 100%; } }
    .site-category .featurebox__video iframe {
      vertical-align: bottom;
      width: 100%;
      height: 300px; }
      @media screen and (max-width: 768px) {
        .site-category .featurebox__video iframe {
          height: 200px; } }
  .site-category .featurebox__img {
    width: 480px; }
    @media screen and (max-width: 1023px) {
      .site-category .featurebox__img {
        width: 320px; } }
    @media screen and (max-width: 768px) {
      .site-category .featurebox__img {
        width: 100%; } }
    .site-category .featurebox__img--frame {
      border: #e8e8e8 1px solid;
      padding: 25px; }
      @media screen and (max-width: 768px) {
        .site-category .featurebox__img--frame {
          padding: 8px; } }
    .site-category .featurebox__img img {
      width: 100%; }
  .site-category .featurebox__data {
    width: calc(100% - 480px);
    padding: 0 0 0 40px; }
    @media screen and (max-width: 1023px) {
      .site-category .featurebox__data {
        width: calc(100% - 320px); } }
    @media screen and (max-width: 768px) {
      .site-category .featurebox__data {
        width: auto;
        padding: 20px 0 0; } }
    .site-category .featurebox__data .mb {
      margin-bottom: 32px; }
      @media screen and (max-width: 768px) {
        .site-category .featurebox__data .mb {
          margin-bottom: 16px; } }
    .site-category .featurebox__data .mt {
      margin-top: 32px; }
      @media screen and (max-width: 768px) {
        .site-category .featurebox__data .mt {
          margin-top: 16px; } }
    .site-category .featurebox__data img {
      max-width: 100%; }
    .site-category .featurebox__data strong {
      font-weight: 700;
      border-bottom: #f43300 2px solid;
      color: #f43300; }
    .site-category .featurebox__data__list {
      padding: 15px 0 0; }
      .site-category .featurebox__data__list li {
        list-style: none;
        font-size: 15px;
        margin: 0 0 15px;
        padding: 0 0 15px;
        border-bottom: #e8e8e8 1px solid; }
        .site-category .featurebox__data__list li:before {
          content: "\f058";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          display: inline-block;
          margin: 0 8px 0 0;
          color: #f43300; }
    .site-category .featurebox__data__alert {
      margin: 20px 0 0;
      text-align: right;
      font-size: 13px; }

.site-category .functionbox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-category .functionbox {
      display: block; } }
  .site-category .functionbox__img {
    width: 350px; }
    @media screen and (max-width: 768px) {
      .site-category .functionbox__img {
        width: 100%; } }
    .site-category .functionbox__img--frame {
      border: #e8e8e8 1px solid;
      padding: 25px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center; }
      @media screen and (max-width: 768px) {
        .site-category .functionbox__img--frame {
          padding: 8px; } }
    .site-category .functionbox__img img {
      width: 100%; }
  .site-category .functionbox__data {
    width: calc(100% - 350px);
    padding: 0 0 0 40px; }
    @media screen and (max-width: 768px) {
      .site-category .functionbox__data {
        width: auto;
        margin: 20px 0 0;
        padding: 0; } }
    .site-category .functionbox__data strong {
      font-weight: 700;
      border-bottom: #f43300 2px solid;
      color: #f43300; }
    .site-category .functionbox__data__list li {
      font-size: 15px;
      padding: 15px 0;
      padding-left: 36px;
      border-bottom: #e8e8e8 1px solid;
      position: relative; }
      .site-category .functionbox__data__list li:before {
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #f43300;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: 18px;
        left: 0;
        font-size: 14px; }
      .site-category .functionbox__data__list li:last-child {
        margin: 0; }
      .site-category .functionbox__data__list li:nth-child(1):before {
        content: "1"; }
      .site-category .functionbox__data__list li:nth-child(2):before {
        content: "2"; }
      .site-category .functionbox__data__list li:nth-child(3):before {
        content: "3"; }
      .site-category .functionbox__data__list li:nth-child(4):before {
        content: "4"; }
      .site-category .functionbox__data__list li:nth-child(5):before {
        content: "5"; }
      .site-category .functionbox__data__list li:nth-child(6):before {
        content: "6"; }
      .site-category .functionbox__data__list li:nth-child(7):before {
        content: "7"; }
      .site-category .functionbox__data__list li:nth-child(8):before {
        content: "8"; }
      .site-category .functionbox__data__list li:nth-child(9):before {
        content: "9"; }
      .site-category .functionbox__data__list li:nth-child(10):before {
        content: "10"; }
    .site-category .functionbox__data__minilist {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      .site-category .functionbox__data__minilist li {
        width: calc(50% - 12px);
        background: #f2f2f2;
        padding: 15px;
        border-radius: 3px;
        margin: 24px 0 0; }
        .site-category .functionbox__data__minilist li:nth-child(-n+2) {
          margin-top: 0; }
        .site-category .functionbox__data__minilist li dl dt {
          font-size: 16px;
          font-weight: 700;
          margin: 0 0 8px;
          padding: 0 0 8px;
          border-bottom: #FFF 1px solid; }
          .site-category .functionbox__data__minilist li dl dt:before {
            content: '■';
            color: #f43300;
            display: inline-block;
            margin: 0 8px 0 0; }

.site-category .largetxt {
  font-size: 16px;
  margin: 8px 0 16px; }

.site-category .glaytxt {
  background: #f2f2f2;
  padding: 25px;
  margin: 32px 0; }

.site-category .site-module .table-style tbody tr th {
  background: #f2f2f2; }

.site-category .site-module .table-style tbody tr td .mb {
  margin: 0 0 20px; }

.site-category .site-module .table-style tbody tr td dl {
  margin: 0 0 20px; }
  .site-category .site-module .table-style tbody tr td dl:last-of-type {
    margin: 0; }
  .site-category .site-module .table-style tbody tr td dl dt {
    width: 160px;
    background: #f43300;
    color: #FFF;
    padding: 2px 0;
    margin: 0 0 4px;
    text-align: center; }

.site-contact .contactbox {
  background: #f2f2f2;
  padding: 15px 160px;
  border-radius: 4px;
  margin: 0 0 72px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 1023px) {
    .site-contact .contactbox {
      padding: 15px; } }
  @media screen and (max-width: 768px) {
    .site-contact .contactbox {
      margin: 0 0 32px;
      display: block; } }
  .site-contact .contactbox li {
    width: calc(50% - 12px);
    background: #FFF;
    border-radius: 3px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.06); }
    @media screen and (max-width: 768px) {
      .site-contact .contactbox li {
        width: auto;
        margin: 0 0 15px; }
        .site-contact .contactbox li:last-child {
          margin: 0; } }
    .site-contact .contactbox li dl {
      text-align: center;
      line-height: 1;
      font-weight: 700;
      padding: 20px; }
      .site-contact .contactbox li dl dt {
        font-size: 17px;
        margin: 0 0 12px; }
        .site-contact .contactbox li dl dt i {
          color: #f43300;
          display: inline-block;
          margin: 0 8px 0 0; }
      .site-contact .contactbox li dl dd {
        font-family: 'Open Sans',sans-serif;
        font-size: 40px;
        color: #f43300; }
        @media screen and (max-width: 1023px) {
          .site-contact .contactbox li dl dd {
            font-size: 24px; } }
        .site-contact .contactbox li dl dd .up {
          display: inline-block;
          transform: translateY(-4px); }

.site-contact .site-module .table-style tbody tr:first-child {
  border-top: none; }
  .site-contact .site-module .table-style tbody tr:first-child th, .site-contact .site-module .table-style tbody tr:first-child td {
    padding-top: 24px; }

.site-contact .site-module .table-style tbody tr .sub {
  margin: 8px 0 0; }

.site-contact .harf {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .site-contact .harf__item {
    width: calc(50% - 15px);
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    .site-contact .harf__item__title {
      width: 40px; }
    .site-contact .harf__item__gadget {
      width: calc(100% - 40px); }

.site-contact .send {
  text-align: center;
  margin: 40px 0 0; }
  @media screen and (max-width: 768px) {
    .site-contact .send {
      margin: 24px 0 0; } }
  .site-contact .send input {
    width: 280px;
    font-family: 'Noto Sans JP' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em;
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    background: #f43300;
    border: #f43300 2px solid;
    padding: 15px;
    line-height: 1;
    cursor: pointer; }
    @media screen and (max-width: 768px) {
      .site-contact .send input {
        width: 100%;
        display: block;
        font-size: 16px; } }
    .site-contact .send input:hover {
      background: #FFF;
      color: #f43300; }

.site-privacy .text {
  margin: 0 0 48px; }
  @media screen and (max-width: 768px) {
    .site-privacy .text {
      margin: 0 0 40px; } }

.site-privacy .margin {
  margin: 0 0 24px; }

@media screen and (max-width: 768px) {
  .site-privacy .site-module .table-style tbody tr th br {
    display: none; } }

.site-privacy .site-module .table-style tbody tr td {
  font-size: 13px; }
  @media screen and (max-width: 768px) {
    .site-privacy .site-module .table-style tbody tr td {
      padding: 0 0 0 32px; } }

.site-privacy ol li {
  list-style: inside decimal; }
  .site-privacy ol li > ol {
    padding: 0 0 0 2em; }

.site-news .list li {
  list-style: none;
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: #e8e8e8 1px solid; }
  @media screen and (max-width: 768px) {
    .site-news .list li {
      margin: 0 0 15px;
      padding: 0 0 15px; } }
  .site-news .list li > a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    color: #121212; }
  .site-news .list li:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .site-news .list li .bg {
    width: 250px;
    height: 140px;
    background-size: cover;
    background-position: center; }
    @media screen and (max-width: 768px) {
      .site-news .list li .bg {
        width: 100px;
        height: 75px; } }
  .site-news .list li dl {
    width: calc(100% - 250px);
    padding: 0 0 0 24px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start; }
    @media screen and (max-width: 768px) {
      .site-news .list li dl {
        width: calc(100% - 100px);
        padding: 0 0 0 15px;
        display: block; } }
    .site-news .list li dl dt {
      font-size: 20px;
      font-weight: 700;
      width: calc(100% - 100px);
      padding: 0 0 0 20px; }
      @media screen and (max-width: 768px) {
        .site-news .list li dl dt {
          font-size: 15px;
          width: auto;
          padding: 4px 0 0; } }
    .site-news .list li dl dd.time {
      background: #f43300;
      color: #FFF;
      font-size: 12px;
      width: 100px;
      padding: 2px 0;
      text-align: center;
      transform: translateY(4px); }
      @media screen and (max-width: 768px) {
        .site-news .list li dl dd.time {
          font-size: 11px;
          transform: translateY(0); } }
    .site-news .list li dl dd.description {
      font-size: 13.5px;
      text-align: justify; }
      @media screen and (max-width: 768px) {
        .site-news .list li dl dd.description {
          display: none; } }

@media screen and (max-width: 1023px) {
  .site-news .minlist {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; } }

@media screen and (max-width: 768px) {
  .site-news .minlist {
    display: block; } }

.site-news .minlist li {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: #e8e8e8 1px solid; }
  @media screen and (max-width: 1023px) {
    .site-news .minlist li {
      width: calc(50% - 10px); } }
  @media screen and (max-width: 768px) {
    .site-news .minlist li {
      width: auto;
      margin: 0 0 15px;
      padding: 0 0 15px; } }
  .site-news .minlist li:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .site-news .minlist li a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    color: #121212; }
    .site-news .minlist li a .bg {
      width: 90px;
      height: 60px;
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 1023px) {
        .site-news .minlist li a .bg {
          width: 80px;
          height: 50px; } }
    .site-news .minlist li a dl {
      width: calc(100% - 90px);
      padding: 0 0 0 20px; }
      @media screen and (max-width: 1023px) {
        .site-news .minlist li a dl {
          width: calc(100% - 80px); } }
      .site-news .minlist li a dl dt {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.66;
        margin: 8px 0 0; }
        @media screen and (max-width: 1023px) {
          .site-news .minlist li a dl dt {
            font-size: 13.5px; } }
      .site-news .minlist li a dl dd.time {
        background: #f43300;
        color: #FFF;
        font-size: 11px;
        width: 88px;
        padding: 1px 0;
        text-align: center;
        transform: translateY(4px); }
      .site-news .minlist li a dl dd.description {
        font-size: 13.5px;
        text-align: justify; }

.site-news .details .articletitle {
  border-top: #f43300 6px solid;
  background: #f2f2f2;
  padding: 20px 24px;
  margin: 0 0 20px; }
  @media screen and (max-width: 768px) {
    .site-news .details .articletitle {
      padding: 15px;
      margin: 0 0 24px; } }
  @media screen and (max-width: 768px) {
    .site-news .details .articletitle {
      margin: 0 0 16px; } }
  .site-news .details .articletitle h2 {
    line-height: 1.56;
    font-size: 24px;
    font-weight: 700;
    margin: 8px 0 0; }
    @media screen and (max-width: 768px) {
      .site-news .details .articletitle h2 {
        font-size: 17px; } }
  .site-news .details .articletitle .date {
    font-weight: 700;
    color: #FFF;
    background: #f43300;
    padding: 1px 20px;
    font-size: 12px;
    display: inline-block; }

.site-news .details__edit {
  font-size: 15px;
  line-height: 1.8; }
  @media screen and (max-width: 1023px) {
    .site-news .details__edit {
      font-size: 14px; } }
  @media screen and (max-width: 768px) {
    .site-news .details__edit {
      font-size: 13.5px; } }
  .site-news .details__edit h3 {
    font-size: 24px;
    font-weight: 700;
    border-top: #121212 2px solid;
    border-bottom: #121212 2px solid;
    font-weight: 700;
    padding: 8px 2px;
    margin: 0 0 20px; }
    @media screen and (max-width: 1023px) {
      .site-news .details__edit h3 {
        font-size: 22px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h3 {
        font-size: 20px; } }
  .site-news .details__edit h4 {
    font-size: 22px;
    border-bottom: #e8e8e8 2px solid;
    font-weight: 700;
    padding: 0 0 0 16px;
    margin: 0 0 16px;
    position: relative; }
    @media screen and (max-width: 1023px) {
      .site-news .details__edit h4 {
        font-size: 20px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h4 {
        font-size: 18px; } }
    .site-news .details__edit h4:before {
      content: '';
      width: 4px;
      height: 80%;
      position: absolute;
      top: 10%;
      left: 0;
      background: #f43300;
      border-radius: 2px; }
  .site-news .details__edit h5 {
    font-size: 20px;
    font-weight: 700;
    background: #f2f2f2;
    padding: 4px 15px;
    margin: 0 0 16px; }
    @media screen and (max-width: 1023px) {
      .site-news .details__edit h5 {
        font-size: 18px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h5 {
        font-size: 16px; } }
  .site-news .details__edit h6 {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 0 18px;
    margin: 0 0 8px; }
    @media screen and (max-width: 1023px) {
      .site-news .details__edit h6 {
        font-size: 17px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h6 {
        font-size: 15px; } }
    .site-news .details__edit h6:before {
      content: '';
      width: 12px;
      height: 12px;
      border: #f43300 4px solid;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
  .site-news .details__edit table {
    border: #e8e8e8 1px solid;
    border-collapse: collapse;
    width: 100%;
    margin: 0 0 40px; }
    @media screen and (max-width: 1023px) {
      .site-news .details__edit table {
        margin: 0 0 32px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit table {
        margin: 0 0 24px; } }
    .site-news .details__edit table tbody tr td, .site-news .details__edit table tbody tr th,
    .site-news .details__edit table thead tr td,
    .site-news .details__edit table thead tr th {
      padding: 15px;
      text-align: left;
      vertical-align: middle;
      border: #e8e8e8 1px solid; }
    .site-news .details__edit table tbody tr th,
    .site-news .details__edit table thead tr th {
      background: #f2f2f2; }
      @media screen and (max-width: 768px) {
        .site-news .details__edit table tbody tr th,
        .site-news .details__edit table thead tr th {
          min-width: 100px; } }
  .site-news .details__edit img {
    max-width: 100%; }
  .site-news .details__edit .avatar {
    width: 88px;
    margin: 0 15px; }
    @media screen and (max-width: 768px) {
      .site-news .details__edit .avatar {
        width: 64px;
        margin: 0; } }
    .site-news .details__edit .avatar img {
      width: 100%;
      border-radius: 50%; }
    .site-news .details__edit .avatar figcaption {
      text-align: center;
      margin: 15px 0 0;
      font-size: 14px; }
      @media screen and (max-width: 768px) {
        .site-news .details__edit .avatar figcaption {
          margin: 8px 0 0;
          font-size: 13px; } }
  .site-news .details__edit iframe {
    width: 100%;
    height: 300px;
    vertical-align: bottom;
    margin: 0 0 40px; }
    @media screen and (max-width: 1023px) {
      .site-news .details__edit iframe {
        height: 250px;
        margin: 0 0 32px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit iframe {
        height: 180px;
        margin: 0 0 20px; } }
  .site-news .details__edit p {
    margin: 0 0 40px; }
    @media screen and (max-width: 1023px) {
      .site-news .details__edit p {
        margin: 0 0 32px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit p {
        margin: 0 0 20px; } }

.site-sitemap ul li {
  list-style: none;
  margin: 0 0 40px;
  padding: 0 0 40px;
  border-bottom: #e8e8e8 1px solid; }
  @media screen and (max-width: 768px) {
    .site-sitemap ul li {
      margin: 0 0 32px;
      padding: 0 0 32px; } }
  .site-sitemap ul li:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .site-sitemap ul li a {
    color: #121212; }
  .site-sitemap ul li dl dt {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 16px; }
    @media screen and (max-width: 768px) {
      .site-sitemap ul li dl dt {
        font-size: 16px;
        margin: 0 0 12px; } }
  .site-sitemap ul li dl dd {
    float: left; }
    @media screen and (min-width: 769px) {
      .site-sitemap ul li dl dd {
        width: 23.5%;
        margin: 0 2% 2% 0; }
        .site-sitemap ul li dl dd:nth-of-type(4n+1) {
          clear: both; }
        .site-sitemap ul li dl dd:nth-of-type(4n+4) {
          margin-right: 0; }
        .site-sitemap ul li dl dd:nth-last-of-type(-n+4) {
          margin-bottom: 0; } }
    @media screen and (max-width: 768px) {
      .site-sitemap ul li dl dd {
        width: 48.5%;
        margin: 0 3% 3% 0; }
        .site-sitemap ul li dl dd:nth-of-type(2n+1) {
          clear: both; }
        .site-sitemap ul li dl dd:nth-of-type(2n+2) {
          margin-right: 0; }
        .site-sitemap ul li dl dd:nth-last-of-type(-n+2) {
          margin-bottom: 0; } }
    .site-sitemap ul li dl dd a {
      background: #f2f2f2;
      border: #f2f2f2 2px solid;
      border-radius: 6px;
      display: block;
      padding: 12px 0;
      text-align: center;
      font-weight: 700; }
      @media screen and (max-width: 1023px) {
        .site-sitemap ul li dl dd a {
          font-size: 13px; } }
      .site-sitemap ul li dl dd a:hover {
        background: #FFF;
        border-color: #f43300;
        color: #f43300; }

.site-mouvers .is_title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  line-height: 1.6;
  margin: 0 0 32px; }
  .site-mouvers .is_title .or {
    color: #f05a00; }

.site-mouvers .main {
  position: relative;
  padding: calc(64px + 64px) 0 40px; }
  .site-mouvers .main.video {
    padding-bottom: 100px; }
  .site-mouvers .main__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 64px;
    padding: 0 64px;
    position: relative;
    z-index: 2; }
    .site-mouvers .main__grid.video {
      grid-template-columns: 1fr 640px; }
      .site-mouvers .main__grid.video iframe {
        position: absolute;
        top: -92px;
        right: 0;
        width: 640px;
        height: calc(640px / 16 * 9); }
    .site-mouvers .main__grid__item {
      align-self: center;
      position: relative; }
      .site-mouvers .main__grid__item h1 {
        color: #FFF;
        font-size: 64px;
        font-weight: 700;
        line-height: 1.33;
        text-shadow: 0 0 32px #190a00; }
  .site-mouvers .main__under {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px;
    padding-top: 64px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px; }
    .site-mouvers .main__under__item {
      color: #FFF;
      background: rgba(0, 0, 0, 0.48);
      backdrop-filter: saturate(90%) blur(20px);
      padding: 24px;
      border-radius: 4px; }
      .site-mouvers .main__under__item svg {
        fill: #FFF;
        width: 40px;
        height: 40px; }
      .site-mouvers .main__under__item dt {
        font-size: 16px;
        font-weight: 700;
        margin: 0 0 12px; }
      .site-mouvers .main__under__item dd {
        line-height: 1.6; }
  .site-mouvers .main__pic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; }

.site-mouvers .first {
  background: url(/images/mouvers/dotto_bg.png) no-repeat right -15% top/360px; }
  .site-mouvers .first__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 12px;
    margin: 0 0 56px; }
    .site-mouvers .first__list dl {
      background: #f2f2f2;
      border-radius: 4px;
      padding: 20px; }
      .site-mouvers .first__list dl dt {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 12px; }
      .site-mouvers .first__list dl dd {
        line-height: 1.6;
        text-align: justify; }
  .site-mouvers .first__ex {
    background: #f05a00;
    color: #FFF;
    padding: 40px;
    max-width: 900px;
    margin: 0 auto;
    border-radius: 4px;
    text-align: center;
    font-size: 18px; }
    .site-mouvers .first__ex dd {
      font-weight: 700;
      font-size: 24px;
      margin: 16px 0 0; }

.site-mouvers .second__title {
  font-size: 24px;
  font-weight: 700; }

.site-mouvers .second .products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 48px; }
  .site-mouvers .second .products > li > a {
    height: 100%;
    background: #FFF;
    color: #121212;
    display: block;
    padding: 32px;
    padding-bottom: 80px;
    position: relative; }
    .site-mouvers .second .products > li > a:before {
      content: attr(data-t);
      position: absolute;
      top: 0;
      right: 0;
      background: #00ab96;
      color: #FFF;
      padding: 2px 16px; }
    .site-mouvers .second .products > li > a:hover img {
      opacity: 1; }
    .site-mouvers .second .products > li > a:hover .more {
      background: #f05a00;
      color: #FFF; }
    .site-mouvers .second .products > li > a h3 {
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      margin: 0 0 16px;
      line-height: 1.6; }
      .site-mouvers .second .products > li > a h3:after {
        content: attr(data-g);
        display: block;
        font-size: 15px;
        color: #999; }
    .site-mouvers .second .products > li > a figure {
      width: 90%;
      margin: 16px auto; }
      .site-mouvers .second .products > li > a figure img {
        width: 100%; }
    .site-mouvers .second .products > li > a .desc {
      font-size: 15px;
      margin: 0 0 24px; }
    .site-mouvers .second .products > li > a .more {
      border: #f05a00 1px solid;
      color: #f05a00;
      width: 200px;
      padding: 10px 0;
      border-radius: 999px;
      text-align: center;
      position: absolute;
      left: 50%;
      bottom: 32px;
      transform: translateX(-50%); }

.site-mouvers .third {
  background: url(/images/mouvers/dotto_bg.png) no-repeat right 2% top -5%/360px, url(/images/mouvers/gear_bg.png) no-repeat left 0 top 5%/180px; }
  .site-mouvers .third .grid {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-column-gap: 56px; }
    .site-mouvers .third .grid__item {
      position: relative;
      align-self: center; }
      .site-mouvers .third .grid__item__slides {
        position: absolute;
        left: 5%;
        bottom: 0;
        width: 90%;
        z-index: 10;
        transform: translateY(50%); }
        .site-mouvers .third .grid__item__slides .swiper-wrapper {
          transition-timing-function: ease-in-out; }
          .site-mouvers .third .grid__item__slides .swiper-wrapper .swiper-slide {
            width: 135px; }
            .site-mouvers .third .grid__item__slides .swiper-wrapper .swiper-slide a {
              display: block;
              border-radius: 4px;
              overflow: hidden; }
              .site-mouvers .third .grid__item__slides .swiper-wrapper .swiper-slide a:hover img {
                opacity: 1;
                transform: scale(1.1); }
            .site-mouvers .third .grid__item__slides .swiper-wrapper .swiper-slide img {
              width: 100%;
              transition: transform 400ms ease-in-out; }
      .site-mouvers .third .grid__item__main img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px; }
      .site-mouvers .third .grid__item h3 {
        font-size: 36px;
        font-weight: 700;
        line-height: 1.3;
        margin: 0 0 24px; }
        .site-mouvers .third .grid__item h3 strong {
          color: #f05a00; }
      .site-mouvers .third .grid__item__text {
        font-size: 15px; }

.site-mouvers .fourth .grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  margin: 0 0 80px; }
  .site-mouvers .fourth .grid__item figure img {
    width: 100%; }
  .site-mouvers .fourth .grid__item dl {
    margin: 24px 0 0; }
    .site-mouvers .fourth .grid__item dl dt {
      font-size: 17px;
      font-weight: 700;
      background: #e8e8e8;
      padding: 6px 12px; }
    .site-mouvers .fourth .grid__item dl dd {
      font-size: 14px; }

.site-mouvers .fourth .btnarea a {
  font-size: 20px;
  padding: 16px 64px;
  border-radius: 999px; }

.site-mouvers .localnav {
  background: url(/images/mouvers/dotto_bg.png) no-repeat right -40px top -120px/360px;
  padding: 100px 0 0; }
  .site-mouvers .localnav__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2px;
    transform: translateY(40px); }
    .site-mouvers .localnav__list a {
      font-size: 20px;
      text-align: center;
      background: #f2f2f2;
      color: #121212;
      display: block;
      padding: 16px 0; }
      .site-mouvers .localnav__list a:hover, .site-mouvers .localnav__list a.active {
        background: #f05a00;
        color: #FFF; }

.site-mouvers .lbtnarea {
  padding: 24px 0 0; }
  .site-mouvers .lbtnarea a {
    border-radius: 999px; }

#site-wrapper, #site-footer {
  position: relative; }

.site-clum {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 0 auto; }
  @media screen and (max-width: 1023px) {
    .site-clum {
      width: auto;
      display: block; } }
  .site-clum__sidebar {
    width: 280px; }
    .site-clum__sidebar .btnarea a {
      display: block !important;
      min-width: 1px !important; }
    @media screen and (max-width: 1023px) {
      .site-clum__sidebar {
        width: auto; } }
    @media screen and (max-width: 768px) {
      .site-clum__sidebar {
        margin: 32px 0 0; } }
  .site-clum__container {
    width: calc(100% - 280px);
    padding: 0 48px 0 0; }
    @media screen and (max-width: 1023px) {
      .site-clum__container {
        width: auto;
        padding: 0; } }
    .site-clum__container .pager {
      margin: 24px 0 0;
      text-align: center; }
      .site-clum__container .pager a, .site-clum__container .pager span {
        width: 28px;
        height: 28px;
        line-height: 26px;
        text-align: center;
        display: inline-block;
        margin: 0 3px;
        border: #e8e8e8 1px solid;
        background: #e8e8e8;
        color: #121212;
        font-size: 13px; }
      .site-clum__container .pager span {
        background: #f43300;
        border-color: #f43300;
        color: #FFF; }
      .site-clum__container .pager a:hover {
        color: #f43300;
        background: #FFF;
        border-color: #f43300; }

#app {
  opacity: 0; }
  #app.active {
    opacity: 1; }

.display.side-margin {
  max-width: 1600px;
  padding: 0 4%; }

.site-module.wrap {
  padding: 120px 0;
  overflow: hidden; }
  @media screen and (max-width: 1023px) {
    .site-module.wrap {
      padding: 72px 0; } }
  @media screen and (max-width: 768px) {
    .site-module.wrap {
      padding: 32px 0; } }
  .site-module.wrap.glay {
    background: #f2f2f2; }
  .site-module.wrap.mid {
    padding: 80px 0; }
    @media screen and (max-width: 1023px) {
      .site-module.wrap.mid {
        padding: 56px 0; } }
    @media screen and (max-width: 768px) {
      .site-module.wrap.mid {
        padding: 32px 0; } }
  .site-module.wrap.no-top {
    padding-top: 0; }
  .site-module.wrap.no-bottom {
    padding-bottom: 0; }

.site-module h2.large {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  margin: 0 0 32px;
  letter-spacing: .08em;
  text-align: center;
  background: url("../../images/common/h_bg.png") no-repeat center top;
  background-size: 60px;
  padding: 30px 0 0; }
  @media screen and (max-width: 768px) {
    .site-module h2.large {
      font-size: 15px;
      margin: 0 0 24px;
      background: url("../../images/common/h_bg.png") no-repeat center top;
      background-size: 40px;
      padding: 24px 0 0; } }
  .site-module h2.large:before {
    content: attr(data-eng);
    font-family: 'Open Sans', sans-serif;
    font-size: 42px;
    display: block;
    margin: 0 0 10px; }
    @media screen and (max-width: 768px) {
      .site-module h2.large:before {
        font-size: 28px; } }

.site-module h2.borders,
.site-module h3.borders,
.site-module h4.borders {
  border-bottom: #e8e8e8 1px solid;
  position: relative;
  padding: 12px;
  padding-left: 20px;
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 16px; }
  @media screen and (max-width: 768px) {
    .site-module h2.borders,
    .site-module h3.borders,
    .site-module h4.borders {
      font-size: 18px;
      margin: 0 0 20px;
      padding-left: 18px; } }
  .site-module h2.borders small,
  .site-module h3.borders small,
  .site-module h4.borders small {
    display: inline-block;
    margin: 0 0 0 20px;
    font-size: 13px; }
    @media screen and (max-width: 768px) {
      .site-module h2.borders small,
      .site-module h3.borders small,
      .site-module h4.borders small {
        margin: 0 0 0 15px;
        font-size: 11px; } }
  .site-module h2.borders:after,
  .site-module h3.borders:after,
  .site-module h4.borders:after {
    content: '';
    width: 5px;
    height: 70%;
    background: #f43300;
    border-radius: 4px;
    display: block;
    position: absolute;
    top: 15%;
    left: 0; }

.site-module h3.centers,
.site-module h4.centers {
  position: relative;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  color: #121212;
  letter-spacing: .12em;
  margin: 0 0 28px; }
  @media screen and (max-width: 768px) {
    .site-module h3.centers,
    .site-module h4.centers {
      font-size: 20px;
      margin: 0 0 16px; } }
  .site-module h3.centers:before,
  .site-module h4.centers:before {
    content: '';
    width: 100%;
    height: 3px;
    background: #121212;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -2; }
  .site-module h3.centers:after,
  .site-module h4.centers:after {
    content: '';
    width: 200px;
    height: 100%;
    background: #FFF;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1; }
    @media screen and (max-width: 768px) {
      .site-module h3.centers:after,
      .site-module h4.centers:after {
        width: 160px; } }

.site-module .btnarea {
  margin: 32px 0 0; }
  @media screen and (max-width: 768px) {
    .site-module .btnarea {
      margin: 24px 0 0; } }
  .site-module .btnarea.center {
    text-align: center; }
    .site-module .btnarea.center a {
      margin: 0 8px; }
      @media screen and (max-width: 768px) {
        .site-module .btnarea.center a {
          margin: 0; } }
  .site-module .btnarea a {
    background: #f43300;
    color: #FFF;
    padding: 10px 48px;
    min-width: 300px;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    position: relative;
    z-index: 2;
    display: inline-block; }
    @media screen and (max-width: 768px) {
      .site-module .btnarea a {
        display: block;
        padding: 10px 15px;
        text-align: center;
        font-size: 16px; } }
    .site-module .btnarea a:hover {
      background: #121212;
      color: #FFF;
      -webkit-transition: all 240ms 0ms ease-in-out;
      -moz-transition: all 240ms 0ms ease-in-out;
      -ms-transition: all 240ms 0ms ease-in-out;
      -o-transition: all 240ms 0ms ease-in-out;
      transition: all 240ms 0ms ease-in-out; }
    .site-module .btnarea a.ghost {
      background: none;
      border: #f43300 2px solid;
      color: #f43300;
      position: relative; }
      .site-module .btnarea a.ghost:before {
        content: '';
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #f43300;
        z-index: -1;
        -webkit-transition: all 240ms 0ms ease-in-out;
        -moz-transition: all 240ms 0ms ease-in-out;
        -ms-transition: all 240ms 0ms ease-in-out;
        -o-transition: all 240ms 0ms ease-in-out;
        transition: all 240ms 0ms ease-in-out; }
      .site-module .btnarea a.ghost:hover {
        background: none;
        color: #FFF; }
        .site-module .btnarea a.ghost:hover:before {
          width: 100%; }
    .site-module .btnarea a.arrow:after {
      content: "\f061";
      font-size: 11px;
      font-family: "Font Awesome 5 Free";
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%); }
    .site-module .btnarea a.black {
      background: #121212;
      color: #FFF; }
      .site-module .btnarea a.black:hover {
        background: #FFF;
        color: #121212; }
    .site-module .btnarea a.blue {
      border-color: #023894;
      background: #023894;
      color: #FFF; }
      .site-module .btnarea a.blue:hover {
        background: #FFF;
        color: #023894; }

.site-module .table-style {
  border-collapse: collapse;
  width: 100%; }
  @media screen and (max-width: 768px) {
    .site-module .table-style {
      display: block;
      border: #e8e8e8 1px solid; } }
  .site-module .table-style.white {
    background: #FFF;
    border: #e8e8e8 1px solid; }
    .site-module .table-style.white thead tr th,
    .site-module .table-style.white thead tr td,
    .site-module .table-style.white tbody tr th,
    .site-module .table-style.white tbody tr td {
      padding: 10px 15px;
      font-size: 16px; }
      @media screen and (max-width: 768px) {
        .site-module .table-style.white thead tr th,
        .site-module .table-style.white thead tr td,
        .site-module .table-style.white tbody tr th,
        .site-module .table-style.white tbody tr td {
          padding: 6px 15px;
          font-size: 13px; } }
    .site-module .table-style.white thead tr th,
    .site-module .table-style.white tbody tr th {
      border-right: #e8e8e8 1px solid; }
  @media screen and (max-width: 768px) {
    .site-module .table-style thead,
    .site-module .table-style tbody {
      display: block; } }
  .site-module .table-style thead tr,
  .site-module .table-style tbody tr {
    border-bottom: #e8e8e8 1px solid; }
    @media screen and (max-width: 768px) {
      .site-module .table-style thead tr,
      .site-module .table-style tbody tr {
        display: block; }
        .site-module .table-style thead tr:last-child,
        .site-module .table-style tbody tr:last-child {
          border: none; } }
    .site-module .table-style thead tr:first-child,
    .site-module .table-style tbody tr:first-child {
      border-top: #e8e8e8 1px solid; }
      @media screen and (max-width: 768px) {
        .site-module .table-style thead tr:first-child,
        .site-module .table-style tbody tr:first-child {
          border: none; } }
    .site-module .table-style thead tr th, .site-module .table-style thead tr td,
    .site-module .table-style tbody tr th,
    .site-module .table-style tbody tr td {
      padding: 15px 0;
      font-size: 15px;
      vertical-align: middle;
      text-align: left; }
      @media screen and (max-width: 768px) {
        .site-module .table-style thead tr th, .site-module .table-style thead tr td,
        .site-module .table-style tbody tr th,
        .site-module .table-style tbody tr td {
          display: block;
          font-size: 14px;
          padding: 10px 15px; } }
      .site-module .table-style thead tr th input[type=text],
      .site-module .table-style thead tr th input[type=email],
      .site-module .table-style thead tr th input[type=tel],
      .site-module .table-style thead tr th select,
      .site-module .table-style thead tr th option,
      .site-module .table-style thead tr th textarea, .site-module .table-style thead tr td input[type=text],
      .site-module .table-style thead tr td input[type=email],
      .site-module .table-style thead tr td input[type=tel],
      .site-module .table-style thead tr td select,
      .site-module .table-style thead tr td option,
      .site-module .table-style thead tr td textarea,
      .site-module .table-style tbody tr th input[type=text],
      .site-module .table-style tbody tr th input[type=email],
      .site-module .table-style tbody tr th input[type=tel],
      .site-module .table-style tbody tr th select,
      .site-module .table-style tbody tr th option,
      .site-module .table-style tbody tr th textarea,
      .site-module .table-style tbody tr td input[type=text],
      .site-module .table-style tbody tr td input[type=email],
      .site-module .table-style tbody tr td input[type=tel],
      .site-module .table-style tbody tr td select,
      .site-module .table-style tbody tr td option,
      .site-module .table-style tbody tr td textarea {
        font-family: 'Noto Sans JP' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
        font-weight: 400;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.024em;
        border: #e8e8e8 1px solid;
        padding: 8px 10px;
        width: 100%;
        font-size: 15px;
        border-radius: 4px;
        overflow: hidden; }
        .site-module .table-style thead tr th input[type=text]:focus,
        .site-module .table-style thead tr th input[type=email]:focus,
        .site-module .table-style thead tr th input[type=tel]:focus,
        .site-module .table-style thead tr th select:focus,
        .site-module .table-style thead tr th option:focus,
        .site-module .table-style thead tr th textarea:focus, .site-module .table-style thead tr td input[type=text]:focus,
        .site-module .table-style thead tr td input[type=email]:focus,
        .site-module .table-style thead tr td input[type=tel]:focus,
        .site-module .table-style thead tr td select:focus,
        .site-module .table-style thead tr td option:focus,
        .site-module .table-style thead tr td textarea:focus,
        .site-module .table-style tbody tr th input[type=text]:focus,
        .site-module .table-style tbody tr th input[type=email]:focus,
        .site-module .table-style tbody tr th input[type=tel]:focus,
        .site-module .table-style tbody tr th select:focus,
        .site-module .table-style tbody tr th option:focus,
        .site-module .table-style tbody tr th textarea:focus,
        .site-module .table-style tbody tr td input[type=text]:focus,
        .site-module .table-style tbody tr td input[type=email]:focus,
        .site-module .table-style tbody tr td input[type=tel]:focus,
        .site-module .table-style tbody tr td select:focus,
        .site-module .table-style tbody tr td option:focus,
        .site-module .table-style tbody tr td textarea:focus {
          box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.16); }
      .site-module .table-style thead tr th label, .site-module .table-style thead tr td label,
      .site-module .table-style tbody tr th label,
      .site-module .table-style tbody tr td label {
        display: inline-block;
        margin: 0 20px 0 0; }
        .site-module .table-style thead tr th label input, .site-module .table-style thead tr td label input,
        .site-module .table-style tbody tr th label input,
        .site-module .table-style tbody tr td label input {
          margin: 0 8px 0 0;
          display: inline-block;
          vertical-align: middle;
          transform: translateY(-1px);
          cursor: pointer; }
        .site-module .table-style thead tr th label:last-of-type, .site-module .table-style thead tr td label:last-of-type,
        .site-module .table-style tbody tr th label:last-of-type,
        .site-module .table-style tbody tr td label:last-of-type {
          margin: 0; }
      .site-module .table-style thead tr th select, .site-module .table-style thead tr td select,
      .site-module .table-style tbody tr th select,
      .site-module .table-style tbody tr td select {
        width: auto; }
      .site-module .table-style thead tr th textarea, .site-module .table-style thead tr td textarea,
      .site-module .table-style tbody tr th textarea,
      .site-module .table-style tbody tr td textarea {
        height: 200px; }
    .site-module .table-style thead tr th,
    .site-module .table-style tbody tr th {
      width: 20%;
      font-size: 16px; }
      @media screen and (max-width: 1023px) {
        .site-module .table-style thead tr th,
        .site-module .table-style tbody tr th {
          width: 200px; } }
      .site-module .table-style thead tr th.hiss,
      .site-module .table-style tbody tr th.hiss {
        width: 25%;
        padding-right: 40px; }
        @media screen and (max-width: 1023px) {
          .site-module .table-style thead tr th.hiss,
          .site-module .table-style tbody tr th.hiss {
            width: 300px; } }
        @media screen and (max-width: 768px) {
          .site-module .table-style thead tr th.hiss,
          .site-module .table-style tbody tr th.hiss {
            width: auto;
            padding: 15px; } }
        .site-module .table-style thead tr th.hiss:after,
        .site-module .table-style tbody tr th.hiss:after {
          content: '必須';
          float: right;
          background: #f43300;
          color: #FFF;
          padding: 2px 12px;
          border-radius: 4px;
          font-weight: 700;
          font-size: 12px; }
      @media screen and (max-width: 768px) {
        .site-module .table-style thead tr th,
        .site-module .table-style tbody tr th {
          width: auto;
          font-size: 15px;
          background: #f2f2f2; } }

.site-404 dl.ttl {
  text-align: center; }
  .site-404 dl.ttl dt {
    font-weight: 700;
    font-size: 20px;
    margin: 0 0 8px; }

.scroll-anime {
  opacity: 0;
  transform: translateY(40px);
  -webkit-transition: all 600ms 0ms ease-in-out;
  -moz-transition: all 600ms 0ms ease-in-out;
  -ms-transition: all 600ms 0ms ease-in-out;
  -o-transition: all 600ms 0ms ease-in-out;
  transition: all 600ms 0ms ease-in-out; }
  .scroll-anime._active {
    opacity: 1;
    transform: translateY(0); }

.scroll-img {
  position: relative; }
  .scroll-img:before, .scroll-img:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .scroll-img:before {
    background: #f43300;
    -webkit-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img:after {
    background: #FFF;
    -webkit-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img.__active:before {
    width: 0; }
  .scroll-img.__active:after {
    width: 0; }

.scale-enter-active, .scale-leave-active {
  transition: 600ms; }

.scale-enter, .scale-leave-to {
  opacity: 0;
  transform: scale(1.1); }

#intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; }
  #intro.active:before, #intro.active:after {
    height: 0; }
  #intro.active #bar {
    animation: eve 400ms forwards; }

@keyframes eve {
  0% {
    left: 0; }
  100% {
    left: 100%; } }
  #intro.active #num {
    opacity: 0;
    transform: scale(2); }
  #intro:before, #intro:after {
    content: '';
    width: 100%;
    height: 51%;
    position: absolute;
    background: #121212;
    z-index: 1;
    -webkit-transition: all 400ms 0ms ease-in-out;
    -moz-transition: all 400ms 0ms ease-in-out;
    -ms-transition: all 400ms 0ms ease-in-out;
    -o-transition: all 400ms 0ms ease-in-out;
    transition: all 400ms 0ms ease-in-out; }
  #intro:before {
    top: 0; }
  #intro:after {
    bottom: 0; }
  #intro #bar {
    height: 2px;
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 3;
    transform: translateY(-1px);
    background: #f43300; }
  #intro #num {
    font-family: Arial, Helvetica, "sans-serif";
    font-size: calc(120px + 20 * (100vw - 450px)/ 900);
    line-height: 1;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    pointer-events: none;
    color: #f43300;
    font-weight: 700;
    z-index: 2;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    transition: opacity 400ms, transform 400ms; }

.fade-enter-active, .fade-leave-active {
  transition: 400ms; }

.fade-enter, .fade-leave-to {
  opacity: 0; }
