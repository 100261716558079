.site-news{
    .list{
        li{
            list-style: none;
            margin: 0 0 20px;
            padding: 0 0 20px;
            border-bottom: $border 1px solid;
            @media screen and (max-width:$responsive){
                margin: 0 0 15px;
                padding: 0 0 15px;
            }
            >a{
                @include flex();
                align-items: flex-start;
                color: $normal;
            }
            &:last-child{
                margin: 0;
                padding: 0;
                border: none;
            }
            .bg{
                width: 250px;
                height: 140px;
                background-size: cover;
                background-position: center;
                @media screen and (max-width:$responsive){
                    width: 100px;
                    height: 75px;
                }
            }
            dl{
                width: calc(100% - 250px);
                padding: 0 0 0 24px;
                @include flex();
                align-items: flex-start;
                @media screen and (max-width:$responsive){
                    width: calc(100% - 100px);
                    padding: 0 0 0 15px;
                    display: block;
                }
                dt{
                    font-size: 20px;
                    font-weight: 700;
                    width: calc(100% - 100px);
                    padding: 0 0 0 20px;
                    @media screen and (max-width:$responsive){
                        font-size: 15px; 
                        width: auto;
                        padding: 4px 0 0;
                    }
                }
                dd{
                    &.time{
                        background: $red;
                        color: #FFF;
                        font-size: 12px;
                        width: 100px;
                        padding: 2px 0;
                        text-align: center;
                        transform: translateY(4px);
                        @media screen and (max-width:$responsive){
                            font-size: 11px;
                            transform: translateY(0);
                        }
                    }
                    &.description{
                        font-size: 13.5px;
                        text-align: justify;
                        @media screen and (max-width:$responsive){
                            display: none;
                        }
                    }
                }
            }
        }
    }
    
    .minlist{
        @media screen and (max-width:$tab){
            @include flex();   
        }
        @media screen and (max-width:$responsive){
            display: block;   
        }
        li{
            margin: 0 0 20px;
            padding: 0 0 20px;
            border-bottom: $border 1px solid;
            @media screen and (max-width:$tab){
                width: calc(50% - 10px);   
            }
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 0 0 15px;
                padding: 0 0 15px;
            }
            &:last-child{
                margin: 0;
                padding: 0;
                border: none;
            }
            a{
                @include flex();
                align-items: flex-start;
                color: $normal;
                .bg{
                    width: 90px;
                    height: 60px;
                    background-size: cover;
                    background-position: center;
                    @media screen and (max-width:$tab){
                        width: 80px;
                        height: 50px;
                    }
                }
                dl{
                    width: calc(100% - 90px);
                    padding: 0 0 0 20px;
                    @media screen and (max-width:$tab){
                        width: calc(100% - 80px);
                    }
                    dt{
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 1.66;
                        margin: 8px 0 0;
                        @media screen and (max-width:$tab){
                            font-size: 13.5px;   
                        }
                    }
                    dd{
                        &.time{
                            background: $red;
                            color: #FFF;
                            font-size: 11px;
                            width: 88px;
                            padding: 1px 0;
                            text-align: center;
                            transform: translateY(4px);
                        }
                        &.description{
                            font-size: 13.5px;
                            text-align: justify;
                        }
                    }
                }
            }
        }
    }
    
    .details{
        .articletitle{
            border-top: $red 6px solid;
            background: $glay;
            padding: 20px 24px;
            margin: 0 0 20px;
            @media screen and (max-width:$responsive){
                padding: 15px;
                margin: 0 0 24px;
            }
            @media screen and (max-width:$responsive){
                margin: 0 0 16px;   
            }
            h2{
                line-height: 1.56;
                font-size: 24px;
                font-weight: 700;
                margin: 8px 0 0;
                @media screen and (max-width:$responsive){
                    font-size: 17px;   
                }
            }
            .date{
                font-weight: 700;
                color: #FFF;
                background: $red;
                padding: 1px 20px;
                font-size: 12px;
                display: inline-block;
            }
        }
        &__edit{
            font-size: 15px;
            line-height: 1.8;
            @media screen and (max-width:$tab){
                font-size: 14px;   
            }
            @media screen and (max-width:$responsive){
                font-size: 13.5px;   
            }
            h3{
                font-size: 24px;
                font-weight: 700;
                border-top: $normal 2px solid;
                border-bottom: $normal 2px solid;
                font-weight: 700;
                padding: 8px 2px;
                margin: 0 0 20px;
                @media screen and (max-width:$tab){
                    font-size: 22px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 20px;   
                }
            }
            h4{
                font-size: 22px;
                border-bottom: $border 2px solid;
                font-weight: 700;
                padding: 0 0 0 16px;
                margin: 0 0 16px;
                position: relative;
                @media screen and (max-width:$tab){
                    font-size: 20px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 18px;   
                }
                &:before{
                    content: '';
                    width: 4px;
                    height: 80%;
                    position: absolute;
                    top: 10%;
                    left: 0;
                    background: $red;
                    border-radius: 2px;
                }
            }
            h5{
                font-size: 20px;
                font-weight: 700;
                background: $glay;
                padding: 4px 15px;
                margin: 0 0 16px;
                @media screen and (max-width:$tab){
                    font-size: 18px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 16px;   
                }
            }
            h6{
                position: relative;
                font-size: 18px;
                font-weight: 700;
                padding: 0 0 0 18px;
                margin: 0 0 8px;
                @media screen and (max-width:$tab){
                    font-size: 17px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 15px;   
                }
                &:before{
                    content: '';
                    width: 12px;
                    height: 12px;
                    border: $red 4px solid;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
            table{
                border: $border 1px solid;
                border-collapse: collapse;
                width: 100%;
                margin: 0 0 40px;
                @media screen and (max-width:$tab){
                    margin: 0 0 32px;   
                }
                @media screen and (max-width:$responsive){
                    margin: 0 0 24px;   
                }
                tbody,
                thead{
                    tr{
                        td,th{
                            padding: 15px;
                            text-align: left;
                            vertical-align: middle;
                            border: $border 1px solid;
                        }
                        th{
                            background: $glay;
                            @media screen and (max-width:$responsive){
                                min-width: 100px;   
                            }
                        }
                    }
                }
            }
            img{
                max-width: 100%;
            }
            .avatar{
                width: 88px;
                margin: 0 15px;
                @media screen and (max-width:$responsive){
                    width: 64px;
                    margin: 0;
                }
                img{
                    width: 100%;
                    border-radius: 50%;
                }
                figcaption{
                    text-align: center;
                    margin: 15px 0 0;
                    font-size: 14px;
                    @media screen and (max-width:$responsive){
                        margin: 8px 0 0;
                        font-size: 13px;   
                    }
                }
            }
            iframe{
                width: 100%;
                height: 300px;
                vertical-align: bottom;
                margin: 0 0 40px;
                @media screen and (max-width:$tab){
                    height: 250px;
                    margin: 0 0 32px;   
                }
                @media screen and (max-width:$responsive){
                    height: 180px;
                    margin: 0 0 20px;
                }
            }
            p{
                margin: 0 0 40px;
                @media screen and (max-width:$tab){
                    margin: 0 0 32px;   
                }
                @media screen and (max-width:$responsive){
                    margin: 0 0 20px;   
                }
            }
        }
    }
}