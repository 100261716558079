.site-mouvers{
  $m-orange: #f05a00;

  .is_title{
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    line-height: 1.6;
    margin: 0 0 32px;
    .or{
      color: $m-orange;
    }
  }

  .main{
    position: relative;
    padding: calc(64px + 64px) 0 40px;
    &.video{
      padding-bottom: 100px;
    }
    &__grid{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 64px;
      padding: 0 64px;
      position: relative;
      z-index: 2;
      &.video{
        grid-template-columns:  1fr 640px;
        iframe{
          position: absolute;
          top: -92px;
          right: 0;
          width: 640px;
          height: calc(640px / 16 * 9);
        }
      }
      &__item{
        align-self: center;
        position: relative;
        h1{
          color: #FFF;
          font-size: 64px;
          font-weight: 700;
          line-height: 1.33;
          text-shadow: 0 0 32px rgba(#190a00,1);
        }
      }
    }
    &__under{
      max-width: 1400px;
      margin: 0 auto;
      padding: 0 24px;
      padding-top: 64px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 16px;
      &__item{
        color: #FFF;
        background: rgba(#000,.48);
        backdrop-filter: saturate(90%) blur(20px);
        padding: 24px;
        border-radius: 4px;
        svg{
          fill: #FFF;
          width: 40px;
          height: 40px;
        }
        dt{
          font-size: 16px;
          font-weight: 700;
          margin: 0 0 12px;
        }
        dd{
          line-height: 1.6;
        }
      }
    }
    &__pic{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }

  .first{
    background: url(/images/mouvers/dotto_bg.png) no-repeat right -15% top / 360px;
    &__list{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 12px;
      margin: 0 0 56px;
      dl{
        background: $glay;
        border-radius: 4px;
        padding: 20px;
        dt{
          font-size: 18px;
          font-weight: 700;
          margin: 0 0 12px;
        }
        dd{
          line-height: 1.6;
          text-align: justify;
        }
      }
    }
    &__ex{
      background: $m-orange;
      color: #FFF;
      padding: 40px;
      max-width: 900px;
      margin: 0 auto;
      border-radius: 4px;
      text-align: center;
      font-size: 18px;
      dd{
        font-weight: 700;
        font-size: 24px;
        margin: 16px 0 0;
      }
    }
  }
  .second{
    &__title{
      font-size: 24px;
      font-weight: 700;
    }
    .products{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 48px;
      > li{
        >a{
          height: 100%;
          background: #FFF;
          color: $normal;
          display: block;
          padding: 32px;
          padding-bottom: 80px;
          position: relative;
          &:before{
            content: attr(data-t);
            position: absolute;
            top: 0;
            right: 0;
            background: #00ab96;
            color: #FFF;
            padding: 2px 16px;
          }
          &:hover{
            img{
              opacity: 1;
            }
            .more{
              background: $m-orange;
              color: #FFF;
            }
          }
          h3{
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            margin: 0 0 16px;
            line-height: 1.6;
            &:after{
              content: attr(data-g);
              display: block;
              font-size: 15px;
              color: #999;
            }
          }
          figure{
            width: 90%;
            margin: 16px auto;
            img{
              width: 100%;
            }
          }
          .desc{
            font-size: 15px;
            margin: 0 0 24px;
          }
          .more{
            border: $m-orange 1px solid;
            color: $m-orange;
            width: 200px;
            padding: 10px 0;
            border-radius: 999px;
            text-align: center;
            position: absolute;
            left: 50%;
            bottom: 32px;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  .third{
    background: url(/images/mouvers/dotto_bg.png) no-repeat right 2% top -5% / 360px,
                url(/images/mouvers/gear_bg.png) no-repeat left 0 top 5% / 180px,;
    .grid{
      display: grid;
      grid-template-columns: 1.2fr 1fr;
      grid-column-gap: 56px;
      &__item{
        position: relative;
        align-self: center;
        &__slides{
          position: absolute;
          left: 5%;
          bottom: 0;
          width: 90%;
          z-index: 10;
          transform: translateY(50%);
          .swiper-wrapper{
            transition-timing-function: ease-in-out;
            .swiper-slide{
              width: 135px;
              a{
                display: block;
                border-radius: 4px;
                overflow: hidden;
                &:hover{
                  img{
                    opacity: 1;
                    transform: scale(1.1);
                  }
                }
              }
              img{
                width: 100%;
                transition: transform 400ms ease-in-out;
              }
            }
          }
        }
        &__main{
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }
        }
        h3{
          font-size: 36px;
          font-weight: 700;
          line-height: 1.3;
          margin: 0 0 24px;
          strong{
            color: $m-orange;
          }
        }
        &__text{
          font-size: 15px;
        }
      }
    }
  }
  .fourth{
    .grid{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 32px;
      margin: 0 0 80px;
      &__item{
        figure{
          img{
            width: 100%;
          }
        }
        dl{
          margin: 24px 0 0;
          dt{
            font-size: 17px;
            font-weight: 700;
            background: darken($glay, 4%);
            padding: 6px 12px;
          }
          dd{
            font-size: 14px;
          }
        }
      }
    }
    .btnarea{
      a{
        font-size: 20px;
        padding: 16px 64px;
        border-radius: 999px;
      }
    }
  }

  .localnav{
    background: url(/images/mouvers/dotto_bg.png) no-repeat right -40px top -120px / 360px;
    padding: 100px 0 0;
    &__list{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2px;
      transform: translateY(40px);
      a{
        font-size: 20px;
        text-align: center;
        background: $glay;
        color: $normal;
        display: block;
        padding: 16px 0;
        &:hover,
        &.active{
          background: $m-orange;
          color: #FFF;
        }
      }
    }
  }
  .lbtnarea{
    padding: 24px 0 0;
    a{
      border-radius: 999px;
    }
  }
}