.site-category{
    .site-module{
        &.wrap{
            &.mids{
                padding-top: 56px;
                @media screen and (max-width:$responsive){
                    padding-top: 32px;   
                }
            }
        }
    }
    
    .main{
        margin: 0 0 56px;
        padding: 0 16% 32px;
        border-bottom: $border 1px solid;
        @media screen and (max-width:$responsive){
            margin: 0 0 20px;
            padding: 0 15px;
            border: none;
        }
        img{
            width: 100%;
        }
    }
    .firstbox{
        @include flex();
		&.mb{
			margin-bottom: 40px;
			@media screen and (max-width:$responsive){
				margin-bottom: 24px;	
			}
		}
        @media screen and (max-width:$responsive){
            display: block;   
        }
        &__fig{
            width: 300px;
            @media screen and (max-width:$responsive){
                width: 260px;
                margin: 0 auto;
            }
            img{
                width: 100%;
            }
        }
        &__data{
            width: calc(100% - 300px);
            padding: 0 0 0 48px;
            @media screen and (max-width:$responsive){
                width: auto;
                padding: 20px 0 0;
                margin: 20px 0 0;
                border-top: $border 1px solid;
            }
            &__dl{
                position: relative;
                dt{
                    font-size: 28px;
                    font-weight: 700;
                    @media screen and (max-width:$tab){
                        font-size: 22px;     
                    }
                    @media screen and (max-width:$responsive){
                        text-align: center;
                        font-size: 19px;
                    }
                    &:after{
                        content: attr(data-eng);
                        color: $red;
                        font-size: 10px;
                        display: inline-block;
                        margin: 0 0 0 20px;
                        @media screen and (max-width:$responsive){
                            display: block;
                            margin: 4px 0 0;
                        }
                    }
                }
                dd{
                    margin: 8px 0 0;
                    padding: 12px 0 0;
                    border-top: $border 1px solid;
                    @media screen and (max-width:$responsive){
                        padding: 20px 0 0;
                        margin: 0;
                        border: none;
                    }
                    &.custumorder{
                        border: $normal 2px solid;
                        padding: 6px 32px;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 700;
                        margin: 0;
                        position: absolute;
                        top: 0;
                        right: 0;
                        @media screen and (max-width:$tab){
                            position: relative;
                            margin: 10px 0;
                        }
                    }
                    &.str{
                        font-weight: 700;
                        border: none;
                        font-size: 17px;
                        margin: 0;
                        padding: 0;
                    }
                    &.iso{
                        margin: 0;
                        padding: 24px 0 0;
                        border: none;
                        @media screen and (max-width:$responsive){
                            padding: 12px 0 0;   
                        }
                        figure{
                            @include flex();
                            align-items: center;
                            justify-content: flex-start;
                            img{
                                width: 200px;
                                @media screen and (max-width:$tab){
                                    width: 140px;    
                                }
                                @media screen and (max-width:$responsive){
                                    width: 120px;
                                }
                            }
                            figcaption{
                                margin: 0 0 0 32px;
                                @media screen and (max-width:$responsive){
                                    width: calc(100% - 120px);
                                    padding: 0 0 0 20px;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
                &--mini{
                    margin: 32px 0 0;
                    background: $glay;
                    padding: 15px 24px;
                    border-radius: 4px;
                    @media screen and (max-width:$responsive){
                        margin: 16px 0 0;
                        padding: 15px;
                    }
                    dt{
                        font-size: 18px;
                        @media screen and (max-width:$responsive){
                            font-size: 16px;
                            text-align: left;
                        }
                        &:before{
                            content: '■';
                            color: $red;
                            font-size: 12px;
                            display: inline-block;
                            margin: 0 6px 0 0;
                            transform: translateY(-2px);
                        }
                    }
                    dd{
                        @media screen and (max-width:$responsive){
                           padding: 4px 0 0; 
                        }
                    }
                }
            }
        }
    }
    .thumbarea{
        background: $glay;
        padding: 25px;
        margin: 56px 0 0;
        @include flex();
        justify-content: flex-start;
        @media screen and (max-width:$responsive){
            margin: 32px 0 0;
            padding: 15px;
            display: block;
        }
        li{
            width: 32%;
            margin: 2% 2% 0 0;
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 0 0 15px;
                &:last-child{
                    margin: 0;
                    > figure{
                        margin: 0;
                    }
                }
                &:first-child{
                    a{
                        margin: 0;
                    }
                }
                > figure{
                    margin: 0 0 8px;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
            }
            a{
                display: block;
                background: #FFF;
                color: $normal;
                padding: 15px;
                position: relative;
                @media screen and (max-width:$responsive){
                    margin: 8px 0 0 0;
					figure{
						figcaption{
							width: calc(100% - 150px);
							padding: 0 0 0 15px;
							margin: 0;
							text-align: left;
						}
					}
                }
                &:after{
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: $red 2px solid;
                    transform: scale(.88);
                    opacity: 0;
                    @include transition(200ms);
                    @media screen and (max-width:$responsive){
                        display: none;
                    }
                }
                &:hover{
                    &:after{
                        transform: scale(1);
                        opacity: 1;  
                    }
                    img{
                        opacity: 1;
                    }
                }
            }
            @media screen and (min-width:$responsive + 1px){
                &:nth-child(3n+3){
                    margin-right: 0;
                }
                &:nth-child(-n+3){
                    margin-top: 0;
                }
            }
            @media screen and (max-width:$responsive){
                figure{
                    @include flex();
                    align-items: flex-start;
                }   
            }
            img{
                width: 100%;
                @media screen and (max-width:$responsive){
                    width: 150px;
                    height: auto;
                }
            }
            figcaption{
                margin: 15px 0 0;
                text-align: center;
				@media screen and (max-width:$responsive){
					width: 100%;	
				}
				strong{
					font-weight: 700;
					font-size: 15px;
				}
            }
        }
        @media screen and (min-width:$responsive + 1px){
            &--four{
                li{
                    width: 23.5%;
                    &:nth-child(3n+3){
                        margin-right: 2%;
                    }
                    &:nth-child(-n+4){
                        margin-top: 0;
                    }
                    &:nth-child(4n+4){
                        margin-right: 0;
                    }
                }
            }
        }
    }
    
    .featurebox{
        @include flex();
        @media screen and (max-width:$responsive){
            display: block;   
        }
        &__video{
            width: 480px;
            @media screen and (max-width:$tab){
                width: 320px;     
            }
            @media screen and (max-width:$responsive){
                width: 100%;   
            }
            iframe{
                vertical-align: bottom;
                width: 100%;
                height: 300px;
                @media screen and (max-width:$responsive){
                    height: 200px;   
                }
            }
        }
        &__img{
            width: 480px;
            @media screen and (max-width:$tab){
                width: 320px;     
            }
            @media screen and (max-width:$responsive){
                width: 100%;    
            }
            &--frame{
                border: $border 1px solid;
                padding: 25px;
                @media screen and (max-width:$responsive){
                    padding: 8px;   
                }
            }
            img{
                width: 100%;
            }
        }
        &__data{
            width: calc(100% - 480px);
            padding: 0 0 0 40px;
            @media screen and (max-width:$tab){
                width: calc(100% - 320px);     
            }
            @media screen and (max-width:$responsive){
                width: auto;
                padding: 20px 0 0;
            }
            .mb{
                margin-bottom: 32px;
                @media screen and (max-width:$responsive){
                    margin-bottom: 16px;   
                }
            }
            .mt{
                margin-top: 32px;
                @media screen and (max-width:$responsive){
                    margin-top: 16px;   
                }
            }
            img{
                max-width: 100%;
            }
            strong{
                font-weight: 700;
                border-bottom: $red 2px solid;
                color: $red;
            }
            &__list{
                padding: 15px 0 0;
                li{
                    list-style: none;
                    font-size: 15px;
                    margin: 0 0 15px;
                    padding: 0 0 15px;
                    border-bottom: $border 1px solid;
                    &:before{
                        content: "\f058";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        display: inline-block;
                        margin: 0 8px 0 0;
                        color: $red;
                    }
                }
            }
            &__alert{
                margin: 20px 0 0;
                text-align: right;
                font-size: 13px;
            }
        }
    }
    
    .functionbox{
        @include flex();
        @media screen and (max-width:$responsive){
            display: block;
        }
        &__img{
            width: 350px;
            @media screen and (max-width:$responsive){
                width: 100%;   
            }
            &--frame{
                border: $border 1px solid;
                padding: 25px;
                @include flex();
                align-items: center;
                @media screen and (max-width:$responsive){
                    padding: 8px;   
                }
            }
            img{
                width: 100%;
            }
        }
        &__data{
            width: calc(100% - 350px);
            padding: 0 0 0 40px;
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 20px 0 0;
                padding: 0;
            }
            strong{
                font-weight: 700;
                border-bottom: $red 2px solid;
                color: $red;
            }
            &__list{
                li{
                    font-size: 15px;
                    padding: 15px 0;
                    padding-left: 36px;
                    border-bottom: $border 1px solid;
                    position: relative;
                    &:before{
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        background: $red;
                        color: #FFF;
                        text-align: center;
                        position: absolute;
                        top: 18px;
                        left: 0;
                        font-size: 14px;
                    }
                    &:last-child{
                        margin: 0;
                    }
                    @for $i from 1 through 10{
                        &:nth-child(#{$i}){
                            &:before{
                                content: '#{$i}';
                            }
                        }
                    }
                }
            }
            &__minilist{
                @include flex();
                li{
                    width: calc(50% - 12px);
                    background: $glay;
                    padding: 15px;
                    border-radius: 3px;
                    margin: 24px 0 0;
                    &:nth-child(-n+2){
                        margin-top: 0;
                    }
                    dl{
                        dt{
                            font-size: 16px;
                            font-weight: 700;
                            margin: 0 0 8px;
                            padding: 0 0 8px;
                            border-bottom: #FFF 1px solid;
                            &:before{
                                content: '■';
                                color: $red;
                                display: inline-block;
                                margin: 0 8px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .largetxt{
        font-size: 16px;
        margin: 8px 0 16px;
    }
    .glaytxt{
        background: $glay;
        padding: 25px;
        margin: 32px 0;
    }
    .site-module{
        .table-style{
            tbody{
                tr{
                    th{
                        background: $glay;
                    }
                    td{
                        .mb{
                            margin: 0 0 20px;
                        }
                        dl{
                            margin: 0 0 20px;
                            &:last-of-type{
                                margin: 0;
                            }
                            dt{
                                width: 160px;
                                background: $red;
                                color: #FFF;
                                padding: 2px 0;
                                margin: 0 0 4px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}