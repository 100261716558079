#site-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $pc-head;
    z-index: 2000;
    padding: 0 40px;
    @include flex();
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    color: #FFF;
    @include transition(400ms);
    @media screen and (max-width:$tab){
        height: $sp-head;
        padding: 0 20px;
        position: absolute;
    }
    .trigger,
    .close{
        display: none;
        @media screen and (max-width:$tab){
            width: 40px;
            height: 40px;
            border: #FFF 1px solid;
            position: fixed;
            display: block;
            top: 15px;
            right: 20px;
            border-radius: 6px;
            position: fixed;
            cursor: pointer;
            @include transition(200ms);
            &:before,
            &:after{
                content: '';
                width: 18px;
                height: 1px;
                background: #FFF;
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translateX(-50%);
                @include transition(200ms);
            }
            &:after{
                top: auto;
                bottom: 40%;
            }
        }
    }
    @media screen and (min-width:$tab + 1px){
        &.active,
        &:hover{
            background: #FFF;
            color: $normal;
            .logo{
                &:after{
                    opacity: 1;
                }
                img{
                    opacity: 0;
                }
            }
            .gnavi{
                &__inner{
                    > ul{
                        li{
                            > a{
                                color: $normal;
                            }
                            dl{
                                dt{
                                    a{
                                        color: $normal;
                                    }
                                }
                            }
                        }
                    } 
                }
            }
            .subnavi{
                .lang{
                    a{
                        color: $normal;
                    }
                }
            }
        }
    }
    @media screen and (max-width:$tab){
        &.active{
            .trigger{
                border-color: $normal;
                background: $normal;
                &:before,
                &:after{
                    background: #FFF;
                }
            } 
        }
    }
    .logo{
        margin: 2px 0 0;
        width: 200px;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("../../../images/common/logo_2x.png") no-repeat;
            background-size: cover;
            opacity: 0;
            pointer-events: none;
            @include transition(400ms);
        }
        img{
            width: 100%;
            opacity: 1;
            @include transition(400ms);
        }
    }
    .gnavi{
        margin: 0 0 0 80px;
        @media screen and (max-width:1120px){
            margin: 0 0 0 48px;
        }
        @media screen and (max-width:$tab){
            display: block;
            opacity: 0;
            &.active{
                opacity: 1;
            }
        }
        &__inner{
            @media screen and (min-width:$tab + 1px){
                display: block !important;
            }
            @media screen and (max-width:$tab){
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #FFF;
                z-index: 9000;
                padding: 70px 0 0;
                overflow: auto;
            }
            .close{
                @media screen and (max-width:$tab){
                    position: absolute;
                    border-color: $glay;
                    background: $glay;
                    &:before,
                    &:after{
                        background: $normal;
                        bottom: auto;
                        top: 50%;
                        left: 10px;
                        transform: rotate(45deg);
                    }
                    &:after{
                        transform: rotate(-45deg);
                    }
                }
            }
            > ul{
                @include flex();
                align-items: flex-start;
                @media screen and (max-width:$tab){
                    display: block;
                    color: $normal;
                }
                > li{
                    list-style: none;
                    font-weight: 500;
                    margin: 0 40px 0 0;
                    @media screen and (max-width:1120px){
                        margin: 0 24px 0 0;
                    }
                    @media screen and (max-width:$tab){
                        margin: 0;
                        color: $normal;
                        border-bottom: $border 1px solid;
                        font-size: 16px;
                        &:first-child{
                            border-top: $border 1px solid;
                        }
                    }
                    i{
                        display: inline-block;
                        margin: 0 0 0 8px;
                        font-size: .8rem;
                        transform: translateY(-1px);
                        @media screen and (max-width:$tab){
                            position: absolute;
                            top: 50%;
                            right: 20px;
                            transform: translateY(-50%);
                            margin: 0;
                            color: $red;
                        }
                    }
                    > a{
                        color: #FFF;
                        @include transition(400ms);
                        position: relative;
                        @media screen and (max-width:$tab){
                            color: $normal;
                            padding: 15px 20px;
                            display: block;
                            position: relative;
                        }
                        &:after{
                            content: '';
                            width: 6px;
                            height: 6px;
                            background: $red;
                            position: absolute;
                            border-radius: 50%;
                            left: 50%;
                            opacity: 0;
                            top: 0;
                            transform: translateX(-50%);
                            @include transition(200ms);
                        }
                        @media screen and (min-width:$tab + 1px){
                            &:hover{
                                &:after{
                                    opacity: 1;
                                    top: -8px;
                                }
                            }
                        }
                    }
                    &:last-child{
                        margin: 0;
                    }
                    dl{
                        @media screen and (min-width:$tab + 1px){
                            &:hover{
                                dt{
                                    i{
                                        color: $red;
                                        transform: rotate(-180deg);
                                    }
                                }
                                dd{
                                    display: block !important;
                                    animation: fade 400ms forwards;
                                    @keyframes fade{
                                        0%{
                                            opacity: 0;
                                            transform: translateY(-10px);
                                        }
                                        100%{
                                            opacity: 1;
                                            transform: translateY(0);
                                        }
                                    }
                                }
                            }
                        }
                        dt{
                            cursor: pointer;
                            position: relative;
                            font-weight: 500;
                            @media screen and (max-width:$tab){
                                padding: 15px 20px;
                                &:before{
                                    display: none;
                                }
                                &.active{
                                    i{
                                        transform: translateY(-50%) rotate(180deg);
                                    }
                                }
                            }
                            a{
                                color: #FFF;
                                @include transition(400ms);
                                @media screen and (max-width:$tab){
                                    color: $normal;   
                                } 
                            }
                            i{
                                display: inline-block;
                                @include transition(120ms);
                            }
                            &:before{
                                content: '';
                                width: 100%;
                                height: $pc-head + 4px;
                                position: absolute;
                                top: -32px;
                                left: 0;
                                z-index: -1;
                            }
                        }
                        dd{
                            width: 100%;
                            position: absolute;
                            top: $pc-head;
                            left: 0;
                            background: $glay;
                            opacity: 0;
                            display: none;
                            box-shadow: 0 16px 16px rgba(#000,.05);
                            @media screen and (max-width:$tab){
                                position: relative;
                                top: 0;
                                left: 0;
                                display: block;
                                box-shadow: none;
                                opacity: 1;
                            }
                            ul{
                                @include flex();
                                justify-content: flex-start;
                                padding: 20px 0;
                                @media screen and (max-width:$tab){
                                    display: block;
                                    padding: 0;
                                }
                                li{
                                    width: 16.6%;
                                    padding: 20px 0;
                                    border-right: rgba(#000,.08) 1px solid;
                                    &:nth-child(6n+6){
                                        border-right: none;
                                    }
                                    @media screen and (max-width:$tab){
                                        width: auto;
                                        padding: 0;
                                        border-bottom: rgba(#000,.08) 1px solid;
                                        &:last-child{
                                            border: none;
                                        }
                                    }
                                    figure{
                                        text-align: center;
                                        @media screen and (max-width:$tab){
                                            @include flex();
                                            align-items: center;
                                            justify-content: flex-start;
                                        }
                                        img{
                                            width: 80px;
                                            @media screen and (max-width:$tab){
                                                width: 32px;
                                                margin: 0 15px 0 0;
                                            }
                                        }
										figcaption{
											.mini{
												font-size: 12px;
												display: block;
												margin: 0 15px;
												@media screen and (max-width:$tab){
													display: none;
												}
											}
										}
                                    }
                                    a{
                                        font-size: 13.5px;
                                        display: block;
                                        @media screen and (max-width:$tab){
                                            color: $normal;
                                            font-size: 16px;
                                            padding: 15px 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .spmeta{
            display: none;
            @media screen and (max-width:$tab){
                display: block;
                margin: 16px 20px 0;
                &__contact{
                    display: block;
                    background: $red;
                    color: #FFF;
                    padding: 10px 0;
                    font-size: 16px;
                    border-radius: 5px;
                    text-align: center;
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
                    }
                }
                &__lang{
                    margin: 15px 0 0;
                    text-align: center;
                    li{
                        list-style: none;
                        display: inline-block;
                        margin: 0 8px;
                        a{
                            color: $normal;
                        }
                        &.active{
                            a{
                                color: $red;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }
    .subnavi{
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 0 0 40px;
        @include transition(400ms);
        @media screen and (max-width:$tab){
            display: none;
        }
        ul{
            @include flex();
            align-items: center;
            a{
                color: #FFF;
            }
            li{
                &.lang{
                    margin: 0 40px 0 0;
                    a{
                        display: inline-block;
                        position: relative;
                        @include transition(400ms);
                        &:hover{
                            color: $red;
                            &:first-of-type{
                                &:after{
                                    color: $normal;
                                }
                            }
                        }
                        &.active{
                            &:before{
                                content: '';
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                position: absolute;
                                top: -8px;
                                left: 8px;
                                background: $red;
                            }
                        }
                        &:first-of-type{
                            &:after{
                                content: '/';
                                margin: 0 12px;
                                display: inline-block; 
                            }
                        }
                    }
                }
                .contact{
                    position: relative;
                    padding: 14px 24px;
                    display: block;
                    height: $pc-head;
                    @include flex();
                    align-items: center;
                    justify-content: center;
                    @include transition(400ms);
                    &:before,
                    &:after{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: $normal;
                        z-index: 1;
                    }
                    &:after{
                        width: 0;
                        background: $red;
                        @include transition(200ms);
                    }
                    &:hover{
                        text-decoration: none;
                        opacity: 1;
                        &:after{
                            width: 100%;
                        }
                    }
                    i,span{
                        position: relative;
                        z-index: 2;
                    }
                    i{
                        width: 100%;
                        margin: 0;
                        font-size: 20px;
                        display: block;
                        text-align: center;
                    }
                    span{
                        display: block;
                        font-size: 13px;
                        text-align: center;
                        letter-spacing: .12em;
                    }
                }
                i{
                    display: inline-block;
                    margin: 0 0 0 8px;
                    font-size: .8rem;
                    transform: translateY(-1px);
                    @include transition(400ms);
                }
            }
        }
    }
    
    
    /*
    .menu-trigger{
        cursor: pointer;
        &__item{
            $side: 30px;
            width: $side;
            height: $side;
            margin: 0 auto;
            position: relative;
            &__border,
            &:before,
            &:after{
                content: '';
                width: 26px;
                height: 3px;
                background: $blue;
                position: absolute;
                border-radius: 999px;
                top: 7px;
                left: 50%;
                transform: translateX(-50%);
            }
            &:after{
                top: auto;
                bottom: 7px;
            }
            &__border{
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }
        &__text{
            @include osw();
            white-space: nowrap;
            display: block;
            font-size: 12px;
            line-height: 1;
            text-align: center;
            margin: 2px 0 0;
            letter-spacing: .08em;
        }
    }
    */
    
    
}