.site-catalog{
    .localnav{
        background: $glay;
        padding: 20px 40px;
        border-radius: 4px;
        @media screen and (max-width:$responsive){
            padding: 0;   
        }
        ul{
            @include flex();
            justify-content: flex-start;
            align-items: flex-start;
            @media screen and (max-width:$responsive){
                display: block;   
            }
            li{
                list-style: none;
                @media screen and (max-width:$responsive){
                    border-bottom: $border 1px solid;
                    &:last-child{
                        border: none;
                    }
                }
                &:after{
                    content: '|';
                    opacity: .16;
                    display: inline-block;
                    margin: 0 20px;
                    @media screen and (max-width:$responsive){
                        display: none;
                    }
                }
                a{
                    color: $normal;
                    @media screen and (max-width:$responsive){
                        display: block;
                        padding: 10px 15px;
                    }
                    &:before{
                        content: "\f0a9";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        display: inline-block;
                        margin: 0 6px 0 0;
                        color: $red;
                    }
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    
    .catalist{
        padding: 72px 0 0;
        @media screen and (max-width:$tab){
            padding: 56px 0 0;   
        }
        @media screen and (max-width:$responsive){
            padding: 32px 0 0;   
        }
        &__item{
            margin: 0 0 0 -32px;
            @include flex();
            align-items: flex-start;
            justify-content: flex-start;
            @media screen and (max-width:$tab){
                margin: 0 0 0 -20px;   
            }
            @media screen and (max-width:$responsive){
                margin: 0;
                display: block;
            }
            > li{
                width: 25%;
                padding: 0 0 0 32px;
                margin: 32px 0 0;
                @media screen and (min-width:$tab + 1px){
                    &:nth-child(-n+4){
                        margin-top: 0;
                    }
                }
                @media screen and (max-width:$tab){
                    width: 33.3%;
                    padding: 0 0 0 20px;
                    &:nth-child(-n+3){
                        margin-top: 0;
                    }
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 0;
                    margin: 0 0 16px;
                    &:last-child{
                        margin: 0;
                    }
                }
                a{
                    border: $border 1px solid;
                    display: block;
                    padding: 20px;
                    color: $normal;
                    background: #FFF;
                    position: relative;
                    @media screen and (max-width:$responsive){
                        padding: 15px;   
                    }
                    &:after{
                        content: '';
                        width: calc(100% + 2px);
                        height: calc(100% + 2px);
                        border: $red 2px solid;
                        opacity: 0;
                        transform: scale(.88);
                        position: absolute;
                        top: -1px;
                        left: -1px;
                        z-index: 2;
                        pointer-events: none;
                        @include transition(200ms);
                        @media screen and (max-width:$responsive){
                            display: none;
                        }
                    }
                    &:hover{
                        &:after{
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                    figure{
                        text-align: center;
                        @media screen and (max-width:$responsive){
                            @include flex();
                            justify-content: flex-start;
                            align-items: center;
                        }
                        img{
                            width: 100px;
                            @include transition(200ms);
                            @media screen and (max-width:$responsive){
                                width: 80px;
                                margin: 0 15px 0 0;
                            }
                        }
                        figcaption{
                            margin: 16px 0 0;
                            font-size: 15px;
                            @media screen and (max-width:$responsive){
                                margin: 0;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        
        &__video{
            margin: 0 0 0 -32px;
            @include flex();
            align-items: flex-start;
            justify-content: flex-start;
            @media screen and (max-width:$tab){
                margin: 0 0 0 -20px;   
            }
            @media screen and (max-width:$responsive){
                margin: 0;
                display: block;
            }
            > li{
                width: 33.3%;
                padding: 0 0 0 32px;
                margin: 32px 0 0;
                @media screen and (max-width:$tab){
                    width: 50%;
                    margin: 20px 0 0;
                    padding: 0 0 0 20px;
                }
                @media screen and (min-width:$tab+1px){
                    &:nth-child(-n+3){
                        margin-top: 0;
                    }
                }
                @media screen and (max-width:$tab){
                    &:nth-child(-n+2){
                        margin-top: 0;
                    }
                }
                @media screen and (max-width:$responsive){
                    margin: 0 0 15px;
                    width: auto;
                    padding: 0;
                    &:last-child{
                        margin: 0;
                    }
                }
                iframe{
                    width: 100%;
                    height: 250px;
                    vertical-align: bottom;
                    @media screen and (max-width:$tab){
                        height: 200px;   
                    }
                }
            }
            &__caption{
                text-align: center;
                padding: 15px;
                background: $glay;
            }
        }
    }
    
    .productlist{
        padding: 72px 0 0;
        @media screen and (max-width:$responsive){
            padding: 32px 0 0;   
        }
        &--no{
            padding: 0;
        }
        &__item{
            @include flex();
            justify-content: flex-start;
            @media screen and (max-width:$responsive){
                display: block;
            }
            li{
                width: 23.5%;
                margin: 2% 2% 0 0;
                border: $border 1px solid;
                position: relative;
                box-shadow: 0 0 16px rgba(#000,.04);
                background: #FFF;
                @media screen and (max-width:$tab){
                    width: 32%;   
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 8px;
                    &:after{
                        clear: both;
                        display: block;
                        content: '';
                    }
                    &:last-child{
                        margin: 0;
                    }
                }
                &:after{
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 0 12px 12px;
                    border-color: transparent transparent $red transparent;
                    position: absolute;
                    right: -1px;
                    bottom: -1px;
                    @media screen and (max-width:$responsive){
                        display: none;
                    }
                }
                @media screen and (min-width:$tab + 1px){
                    &:nth-child(-n+4){
                        margin-top: 0;
                    }
                    &:nth-child(4n+4){
                        margin-right: 0;
                    }
                }
                @media screen and (max-width:$tab){
                    &:nth-child(-n+3){
                        margin-top: 0;
                    }
                    &:nth-child(3n+3){
                        margin-right: 0;
                    }
                }
                a{
                    display: block;
                    color: $normal;
                    @media screen and (max-width:$responsive){
                        padding: 15px;   
                    }
                }
                figure{
                    width: 56%;
                    min-width: 160px;
                    margin: 25px auto;
                    @media screen and (max-width:$responsive){
                        margin: 0;
                        min-width: 1px;
                        width: 100px;
                        margin: 0 15px 0 0;
                        float: left;
                    }
                    img{
                        width: 100%;
                    }
                }
                dl{
                    padding: 25px;
                    padding-top: 0;
                    @media screen and (max-width:$responsive){
                        padding: 0;   
                    }
                    dt{
                        font-weight: 700;
                        font-size: 16px;
                        text-align: center;
                        @media screen and (max-width:$responsive){
                            text-align: left;   
                        }
                        &:before{
                            content: attr(data-eng);
                            display: block;
                            color: $red;
                            font-size: 10px;
                        }
                    }
                    dd{
                        text-align: justify;
                        margin: 12px 0 0;
                        padding: 12px 0 0;
                        font-size: 13px;
                        border-top: $border 1px solid;
                        @media screen and (max-width:$responsive){
                            clear: both;
                            margin: 0;
                            border: none;
                        }
                    }
                }
            }
        }
    }
    
    .downloadlist{
        padding: 72px 0 0;
        @media screen and (max-width:$responsive){
            padding: 32px 0 0;   
        }
        &--no{
            padding: 0;
        }
        &__item{
            @include flex();
            justify-content: flex-start;
            @media screen and (max-width:$responsive){
                display: block;   
            }
            li{
                width: 23.5%;
                margin: 2% 2% 0 0;
                border: $border 1px solid;
                position: relative;
                box-shadow: 0 0 16px rgba(#000,.04);
                background: #FFF;
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 8px;
                    &:last-child{
                        margin: 0;
                    }
                }
                &:nth-child(-n+4){
                    margin-top: 0;
                }
                &:nth-child(4n+4){
                    margin-right: 0;
                }
                a{
                    display: block;
                    color: $normal;
                    padding: 15px;
                    @media screen and (max-width:$responsive){
                        padding: 10px 15px;    
                    }
                    i{
                        color: darken($border, 16%);
                        display: inline-block;
                        margin: 0 10px 0 0;
                    }
                }
            }
        }
    }
}