.site-privacy{
	.text{
        margin: 0 0 48px;
		@media screen and (max-width:$responsive){
			margin: 0 0 40px;
		}
	}
	.margin{
		margin: 0 0 24px;
	}
	.site-module{
        .table-style{
            tbody{
                tr{
					@media screen and (max-width:$responsive){
						th{
							br{
								display: none;
							}
						}	
					}
                    td{
                        font-size: 13px;
                        @media screen and (max-width:$responsive){
                            padding: 0 0 0 32px;   
                        }
                    }
                }
            }
        }
	}
	ol{
		li{
			list-style: inside decimal;
			> ol{
				padding: 0 0 0 2em;
			}
		}
	}
}